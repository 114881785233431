import React from 'react'
import classnames from 'classnames'
import { Action } from '@foodsby/nutrient'

const LineItem = ({ name, price, tooltip, className }) => (
  <div className={classnames('line-item', className)}>
    <div className='line-item__name'>
      {name}
      {tooltip}
    </div>
    <div className='line-item__price'>{price.format()}</div>
  </div>
)

const LineItemTotal = props => (
  <LineItem className='line-item__total' {...props} />
)

const LineItemCoupon = ({ code, discount, onRemove }) => (
  <div className='line-item line-item__coupon'>
    <div className='line-item__name'>
      {code}
      {onRemove && (
        <Action className='line-item__action' onClick={onRemove}>
          REMOVE
        </Action>
      )}
    </div>
    <div className='line-item__price'>-{discount.format()}</div>
  </div>
)

export { LineItemTotal, LineItemCoupon }

export default LineItem
