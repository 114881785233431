import React from 'react'
import { Modal } from '@foodsby/nutrient'

const WarningModal = ({
  onCancel,
  showModal,
  showCancel = false,
  title,
  message,
  buttons,
  icon,
  maxWidth
}) => (
  <Modal
    className='modal--center warning-modal'
    onCancel={onCancel}
    showModal={showModal}
    showCancel={showCancel}
    maxWidth={maxWidth}
  >
    <div className='warning-modal__header'>
      {icon && <span className='warning-modal__icon'>{icon}</span>}

      {title && <h2 className='warning-modal__title'>{title}</h2>}
    </div>

    {message && <div className='warning-modal__section'>{message}</div>}

    {buttons && (
      <div className='warning-modal__button-group'>
        {buttons.map((button, key) => (
          <span key={key} className='warning-modal__button'>
            {button}
          </span>
        ))}
      </div>
    )}
  </Modal>
)

export default WarningModal
