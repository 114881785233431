import React from 'react'
import noop from 'lodash/noop'

import { ReactComponent as CloseIcon } from 'assets/icon--close.svg'
import { fromCents } from 'utils/money'
import { getCustomizations } from 'utils/order'

const MealItemList = ({ meal, onRemove = noop, onEdit = noop, readOnly }) =>
  meal.map((mealItem, index) => (
    <MealItem
      mealItem={mealItem}
      key={index}
      onRemove={() => onRemove(index)}
      onEdit={() => onEdit(index)}
      readOnly={readOnly}
    />
  ))

const MealItemName = ({ mealItem, onRemove, onEdit, readOnly }) => {
  if (readOnly) {
    return <div className='meal-item__name'>{mealItem.name}</div>
  } else {
    const onClickRemove = e => {
      e.preventDefault()
      onRemove()
    }

    const onClickEdit = e => {
      e.preventDefault()
      onEdit()
    }

    return (
      <div className='meal-item__name meal-item__name--editable'>
        <CloseIcon className='meal-item__close-icon' onClick={onClickRemove} />
        {/* eslint-disable-next-line */}
        <a href='#' onClick={onClickEdit}>
          {mealItem.name}
        </a>{' '}
      </div>
    )
  }
}

const MealItem = ({ mealItem, onRemove, onEdit, readOnly }) => {
  const customizations = getCustomizations(mealItem)

  return (
    <div className='meal-item'>
      <div className='meal-item__name-and-price'>
        <MealItemName
          mealItem={mealItem}
          onRemove={onRemove}
          onEdit={onEdit}
          readOnly={readOnly}
        />
        <div className='meal-item__price'>
          {fromCents(mealItem.totalPriceInCents).format()}
        </div>
      </div>
      <div>
        {customizations.map((c, idx) => (
          <div key={idx} className='meal-item__customization'>
            {c.name}
          </div>
        ))}
        {mealItem.specialInstructions && (
          <div className='meal-item__customization'>
            Special Instructions: <em>{mealItem.specialInstructions}</em>
          </div>
        )}
      </div>
    </div>
  )
}

export default MealItemList
