import React, { useState } from 'react'
import { Field } from 'formik'
import noop from 'lodash/noop'

import {
  AlertErrorMessage,
  AlertWarningMessage
} from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import MoneyPicker from 'components/newOrderWorkflow/shared/moneyPicker/MoneyPicker'
import Divider from 'components/newOrderWorkflow/shared/divider/Divider'
import { validateBudget } from 'utils/validators'
import { ignoreOnEnter } from 'utils/inputs'
import { sumMealItems } from 'utils/order'
import { fromDollars } from 'utils/money'
import DfoRadioOption from 'components/newOrderWorkflow/shared/dfoRadioOption/DfoRadioOption'

const NoMealBudget = ({ checked, onChange }) => {
  const label = (
    <label className='dfo-label' htmlFor='noBudget'>
      Attendees have no budget on their meal.
    </label>
  )

  return (
    <div className='meal-budget'>
      <div className='meal-budget__budget'>
        <DfoRadioOption
          id='noBudget'
          name='noBudget'
          value='noBudget'
          checked={checked}
          label={label}
          subtext={
            checked && (
              <AlertWarningMessage>
                If selected, you won’t be able to update your budget after
                you’ve checked out.
              </AlertWarningMessage>
            )
          }
          onChange={onChange}
        />
      </div>
    </div>
  )
}

const MealBudgetSetter = ({
  meals,
  highestMealCost,
  setBudget = noop,
  errors,
  touched,
  checked,
  onChange
}) => {
  const totalPrice = sumMealItems(meals)
  const recommendedBudget = fromDollars(Math.ceil(totalPrice.value))

  const mealBudgetInput = (
    <div className='meal-budget__budget'>
      <label className='dfo-label' htmlFor='budgetSet'>
        Attendees can spend a max of
      </label>
      <Field
        id='budgetPerAttendeeInCents'
        name='budgetPerAttendeeInCents'
        validate={
          checked &&
          (value =>
            validateBudget(
              value,
              totalPrice.intValue,
              highestMealCost,
              touched.budgetPerAttendeeInCents ? setBudget : noop
            ))
        }
      >
        {({ field, form }) => (
          <MoneyPicker
            name={field.name}
            amountInCents={field.value}
            onChange={val => {
              form.setFieldValue(field.name, val)
              form.setFieldTouched(field.name, true)
              onChange(null, 'budgetSet')
            }}
            onEnter={ignoreOnEnter}
            error={form.errors[field.name]}
          />
        )}
      </Field>
      <label className='dfo-label tablet-up' htmlFor='budgetSet'>
        on their meal.
      </label>
    </div>
  )

  const description = (
    <>
      <div className='dfo-small-text'>
        We recommend a budget of at least {recommendedBudget.format()} based on
        the price of the Backup Meal selected.
      </div>
    </>
  )

  return (
    <div className='meal-budget'>
      <DfoRadioOption
        id='budgetSet'
        name='budgetSet'
        value='budgetSet'
        checked={checked}
        label={mealBudgetInput}
        subtext={description}
        onChange={onChange}
      />

      {checked && errors.budgetPerAttendeeInCents && (
        <AlertErrorMessage>{errors.budgetPerAttendeeInCents}</AlertErrorMessage>
      )}
    </div>
  )
}

const MealBudget = ({
  defaultMeal,
  highestMealCost,
  errors,
  touched,
  setBudget,
  setNoBudget = noop,
  noBudget,
  dirty,
  setFieldValue,
  immutableBudget,
  divide
}) => {
  const [budgetSelected, setBudgetSelected] = useState(!noBudget)

  const handleSelection = (event, val) => {
    const value = val || event.currentTarget.value
    if (value === 'noBudget') {
      setNoBudget(true)
      setFieldValue('noBudget', true)
      setFieldValue('budgetSet', false)
      setBudgetSelected(false)
    } else {
      setNoBudget(false)
      setFieldValue('budgetSet', true)
      setFieldValue('noBudget', false)
      setBudgetSelected(true)
    }
  }

  if (immutableBudget) {
    return (
      <label className='dfo-label' htmlFor='budgetPerAttendeeInCents'>
        Attendees have no budget on their meal.
      </label>
    )
  }

  return (
    <>
      <MealBudgetSetter
        meals={defaultMeal}
        highestMealCost={highestMealCost}
        errors={errors}
        touched={touched}
        setBudget={setBudget}
        checked={budgetSelected}
        onChange={handleSelection}
        dirty={dirty}
      />
      {divide && <Divider className='meal-budget__divider'>OR</Divider>}
      <NoMealBudget checked={!budgetSelected} onChange={handleSelection} />
    </>
  )
}

export default MealBudget
