import React from 'react'

import DfoRadioOption from 'components/newOrderWorkflow/shared/dfoRadioOption/DfoRadioOption'
import Answer from './Answer'
import { getPriceText } from './utils'
import track, { INPUT_CHANGE, SELECT_MENU_ITEM_OPTION } from 'services/tracking'

const AnswerRadioOption = ({ item, field, form }) => {
  const checked = Number(field.value) === Number(item.id)

  const onChange = e => {
    const val = e.currentTarget.value ? Number(e.currentTarget.value) : null
    form.setFieldValue(field.name, val)

    track({
      category: INPUT_CHANGE,
      action: SELECT_MENU_ITEM_OPTION
    })
  }

  return (
    <Answer>
      <DfoRadioOption
        id={`item_${item.id}`}
        name={field.name}
        value={item.id}
        checked={checked}
        label={item.text}
        subtext={getPriceText(item.price)}
        onChange={onChange}
        onBlur={field.onBlur}
      />
    </Answer>
  )
}

export default AnswerRadioOption
