import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { selectCurrentUser } from 'redux/selectors'
import { LOGIN_PATH } from 'routes'

const PrivateRoute = ({ component: Component, currentUser, ...rest }) => {
  return currentUser.userId ? (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />
      }}
    />
  ) : (
    <Redirect to={{ pathname: LOGIN_PATH, state: rest.location }} />
  )
}

export default connect(state => ({ currentUser: selectCurrentUser(state) }))(
  PrivateRoute
)
