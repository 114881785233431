import React, { useState } from 'react'
import { Action, Button, Modal } from '@foodsby/nutrient'

import { ReactComponent as InfoItalicIcon } from 'assets/icon--info-italic.svg'

const BackupMealWarningModal = ({ showModal, onAcknowledge }) => {
  const [learnMore, setLearnMore] = useState(false)

  return (
    <Modal
      className='modal--center backup-meal-warning-modal'
      maxWidth='550px'
      showModal={showModal}
      onCancel={onAcknowledge}
      showCancel
    >
      <div className='backup-meal-warning-modal__icon'>
        <InfoItalicIcon />
      </div>

      <h3>We noticed you’re adding a lot of items to your order.</h3>

      {!learnMore && (
        <>
          <h3 className='backup-meal-warning-modal__instructions'>
            In this step, you're building a backup meal for anyone who doesn’t
            order on time.
          </h3>

          <h3 className='backup-meal-warning-modal__instructions'>
            Anything you add here will be delivered to any attendee who doesn’t
            select their preferred meal.
          </h3>
        </>
      )}

      {learnMore && (
        <>
          <div className='modal-section'>
            In this step, you're building a backup meal for anyone who doesn’t
            order on time.
          </div>

          <div className='modal-section'>
            Anything you add here will be delivered to any attendee who doesn’t
            select their preferred meal.
          </div>

          <div className='modal-section backup-meal-warning-modal__question'>
            How does the backup meal work?
          </div>

          <div className='modal-section'>
            When you finish creating this order, you’ll get a link to send to
            everyone attending your event. Each attendee can click the link to
            choose their own meal from the selected restaurant.
          </div>

          <div className='modal-section'>
            But if an attendee forgets to select their own meal, they’ll get the
            backup meal you’ve selected instead. That way, they still get food
            at your event, even if they don’t make their own selection.
          </div>

          <div className='modal-section backup-meal-warning-modal__question'>
            What’s a good backup meal?
          </div>

          <div className='modal-section'>
            A salad is a great option that fits many dietary preferences. It
            ensures that the backup meal works for anyone who gets it.
          </div>
        </>
      )}

      <div className='modal-button-group'>
        <Button className='secondary' onClick={onAcknowledge}>
          GOT IT
        </Button>
        {!learnMore && (
          <Action
            className='secondary'
            onClick={() => setLearnMore(!learnMore)}
          >
            LEARN MORE
          </Action>
        )}
      </div>
    </Modal>
  )
}

export default BackupMealWarningModal
