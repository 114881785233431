import React from 'react'
import classnames from 'classnames'

import DfoCollapsible from 'components/newOrderWorkflow/shared/dfoCollapsible/DfoCollapsible'
import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'

/*
 * TODO: questions with child questions don't collapse properly.
 * They should be nested in the parent's collapsible content, ideally.
 */
export const QuestionCollapsible = ({
  children,
  question,
  open,
  required,
  valid,
  errorMessage
}) => (
  <DfoCollapsible
    id={question.id}
    initialOpen={open}
    trigger={
      question.text && (
        <div className='menu-question__header'>
          <h2
            className={classnames('menu-question__title', {
              'menu-question__title--required': required,
              'menu-question__title--invalid': !valid
            })}
          >
            {question.text}
            {question.depth > 2 ? ' (Based on Selection above)' : ''}
            {required && (
              <span
                className={classnames('menu-question__required', {
                  'menu-question__required--invalid': !valid
                })}
              >
                &nbsp; ({errorMessage && `${errorMessage}, `}Required)&nbsp;
                {question.minimumSelection > 1 && valid && (
                  <span>({question.minimumSelection})</span>
                )}
                &nbsp;
                {!valid && <AlertErrorIcon />}
              </span>
            )}
          </h2>
        </div>
      )
    }
  >
    {question.description && <p>{question.description}</p>}
    {children}
  </DfoCollapsible>
)

export const MenuQuestion = ({
  children,
  question,
  errors,
  menuItemForEdit,
  isEditingOrder
}) => (
  <section
    className={classnames('menu-question', 'segment', {
      'segment-child': question.depth >= 2
    })}
  >
    <QuestionCollapsible
      question={question}
      required={question.minimumSelection > 0}
      valid={!errors}
      errorMessage={errors === 'Required' ? '' : errors}
      open={true} /* !(menuItemForEdit && isEditingOrder) */
    >
      {children}
    </QuestionCollapsible>
  </section>
)

export default MenuQuestion
