import React from 'react'

import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import WarningModal from 'components/newOrderWorkflow/shared/warningModal/WarningModal'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'

export const ConfirmNavigationModal = ({ onSubmit, onCancel }) => {
  const title = 'Are you sure you want to leave this page?'
  const message = 'Your changes will not be saved.'
  const buttons = [
    <RoundButton onClick={onSubmit} className='borderless'>
      Leave Page
    </RoundButton>,
    <RoundButton onClick={onCancel}>Back To Editing</RoundButton>
  ]

  return (
    <WarningModal
      onCancel={onCancel}
      title={title}
      message={message}
      buttons={buttons}
      icon={<AlertErrorIcon />}
      maxWidth='500px'
    />
  )
}

export const UnsavedDetailsModal = ({ onSubmit, onCancel }) => {
  const title = "You didn't save your changes"
  const message =
    'Your information will be lost if you leave this page without saving.'
  const buttons = [
    <RoundButton onClick={onSubmit} className='borderless'>
      Don't Save
    </RoundButton>,
    <RoundButton onClick={onCancel}>Continue Editing</RoundButton>
  ]

  return (
    <WarningModal
      onCancel={onCancel}
      title={title}
      message={message}
      buttons={buttons}
      icon={<AlertErrorIcon />}
      maxWidth='500px'
    />
  )
}
