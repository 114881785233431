import React from 'react'
import { RadioOption } from '@foodsby/nutrient-v3'

const DfoRadioOption = ({ id, label, subtext, ...rest }) => (
  <div className='dfo-radio-option'>
    <RadioOption label={label} id={id} hint={subtext} {...rest} />
  </div>
)

export default DfoRadioOption
