import React, { Component } from 'react'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import {
  JwtClient,
  jwt,
  refreshToken as refreshTokenApi
} from '@foodsby/webapp-jwt'

import { selectAccessToken } from 'redux/selectors'
import { setAccessToken } from 'redux/modules/auth'
import api from 'utils/api'

export const AuthContext = React.createContext()

const GET_CURRENT_USER_API_PATH = '/api/v1/uaa/users/current'

const COOKIE_NAME = process.env.REACT_APP_AUTH_COOKIE_NAME
const COOKIE_DOMAIN = process.env.REACT_APP_AUTH_COOKIE_DOMAIN
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const BASE_URL = process.env.REACT_APP_BASE_URL

class AuthProvider extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true,
      user: undefined
    }

    jwt.initialize({
      cookieName: COOKIE_NAME,
      cookieDomain: COOKIE_DOMAIN,
      secureCookie: process.env.NODE_ENV !== 'development',
      onStopImpersonation: () => {
        alert('Your session has ended.')
        window.close()
        window.location.reload()
      }
    }) // create a CrossStorage hub
  }

  componentDidUpdate (prevProps) {
    if (this.props.accessToken !== prevProps.accessToken) {
      this.getData(this.props.accessToken)
    }
  }

  async componentDidMount () {
    try {
      // Refresh JWT if needed
      if (jwt.isWithoutAccessToken()) {
        await refreshTokenApi(
          {
            clientId: CLIENT_ID,
            clientSecret: CLIENT_SECRET
          },
          {
            baseURL: BASE_URL
          }
        )
      }

      let accessToken = jwt.getAccessToken({
        cookieName: COOKIE_DOMAIN
      }) // is user logged in?

      // if not try to get the jwt from other domain
      if (!accessToken) {
        const jwtHub = process.env.REACT_APP_JWT_HUB
        const client = new JwtClient(jwtHub)
        await client.connect()

        accessToken = await client.getAccessToken()
        const refreshToken = await client.getRefreshToken()

        if (accessToken && refreshToken) {
          jwt.storeTokens({
            access_token: accessToken,
            refresh_token: refreshToken
          })
          this.props.setAccessToken(accessToken)
        } else {
          this.setState({
            loading: false
          })
        }
      } else {
        this.props.setAccessToken(accessToken)
      }
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false,
        error
      })
      if (error.code !== 401) {
        throw error
      }
    }
  }

  getData = async accessToken => {
    const decoded = jwtDecode(accessToken)
    const {
      user_id: userId,
      location_id: locationId,
      user_name: username,
      authorities
    } = decoded
    try {
      const currentUser = await api.get(GET_CURRENT_USER_API_PATH)

      this.setState({
        user: {
          userId,
          username,
          locationId,
          authorities,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          emailAddress: username
        },
        loading: false,
        error: undefined
      })
    } catch (error) {
      this.setState({
        loading: false,
        error: error
      })
      throw error
    }
  }

  render () {
    return (
      <AuthContext.Provider
        value={{
          ...this.state
        }}
      >
        {!this.state.loading && this.props.children}
      </AuthContext.Provider>
    )
  }
}

const mapStateToProps = state => ({
  accessToken: selectAccessToken(state)
})

const mapDispatchToProps = {
  setAccessToken
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthProvider)
