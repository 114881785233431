import React, { Component } from 'react'
import { FastField, Form } from 'formik'
import classnames from 'classnames'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import MenuQuestions from './MenuQuestions'
import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import { hasOrIsEqual, calculatePrice } from './utils'
import { fromDollars } from 'utils/money'

const PersonalizeMenuHeader = ({ menuItem }) => (
  <header className='personalize-menu__header'>
    <h1 className='dfo-h1'>{menuItem.text}</h1>
    <div className='personalize-menu__description'>{menuItem.description}</div>
  </header>
)

const PersonalizeMenuFooter = ({
  questions,
  errors,
  estimatedBudget,
  isUpdating,
  onCancel,
  isAttendee,
}) => {
  const submitLabel = isUpdating ? `Update ${!isAttendee ? 'Backup ' : ''}Meal` : '+ Add to Meal'

  const errorMessages = map(errors, (error, formId) => {
    const question = questions.find(q => q.formId === formId)
    if (!question) {
      console.log(errors)

      debugger
    }
    return `${question.text} ${error.footer}`
  })

  return (
    <footer
      className={classnames('personalize-menu__footer', {
        'personalize-menu__footer--column': isUpdating,
      })}
    >
      <div className='personalize-menu__footer-top'>
        <div className='personalize-menu__footer-left'>
          {estimatedBudget && (
            <div className='personalize-menu__estimated-budget'>
              <div className='personalize-menu__budget'>
                Estimated Budget: <b>{estimatedBudget.format()}</b>
              </div>
              <div className='personalize-menu__budget-explanation'>
                Based on selected meal item.
              </div>
            </div>
          )}
        </div>

        <div className='personalize-menu__footer-right'>
          <RoundButton type='button' className='secondary-alt' label='Cancel' onClick={onCancel} />
          <RoundButton className='primary-alt' label={submitLabel} />
        </div>
      </div>

      {errors && (
        <div className='personalize-menu__footer-bottom'>
          <ul className='personalize-menu__error-messages'>
            {map(errorMessages, (errMsg, index) => (
              <li key={index}>
                <AlertErrorIcon />
                {errMsg}
              </li>
            ))}
          </ul>
        </div>
      )}
    </footer>
  )
}

class PersonalizeMenuForm extends Component {
  componentWillReceiveProps ({ values }) {
    if (!isEqual(this.props.values, values)) {
      this.props.questions.forEach(question => {
        const parentNotSelected =
          values[question.formId] &&
          question.parentQuestion &&
          !hasOrIsEqual(values[`q_${question.parentQuestion}`], question.parentAnswer)
        if (parentNotSelected) {
          this.props.setFieldValue(question.formId, undefined)
        }
      })
    }
  }

  render () {
    const {
      errors,
      values,
      onCancel,
      isUpdatingMealItem,
      questions,
      menuItem,
      isBackupMeal,
      attendee,
    } = this.props

    const isValid = isEmpty(errors)

    let estimatedBudget = null
    if (isBackupMeal) {
      const totalPrice = calculatePrice(menuItem, values, questions)
      estimatedBudget =
        totalPrice.value > 0 ? fromDollars(Math.ceil(totalPrice.value)) : fromDollars(0)
    }

    return (
      <Form className='personalize-menu'>
        <PersonalizeMenuHeader menuItem={menuItem} />

        <div className='personalize-menu__questions'>
          <MenuQuestions questions={questions} values={values} errors={errors} />
        </div>

        <section className='segment'>
          <h2 className='segment-title'>Special Instructions</h2>
          <FastField id='specialInstructions' name='specialInstructions' component='textarea' />
        </section>

        <PersonalizeMenuFooter
          questions={questions}
          errors={errors}
          estimatedBudget={estimatedBudget}
          isValid={isValid}
          isUpdating={isUpdatingMealItem}
          onCancel={onCancel}
          isAttendee={!!attendee}
        />
      </Form>
    )
  }
}

export default PersonalizeMenuForm
