import React from 'react'
import classnames from 'classnames'

import StoreLogo from 'components/storeLogo/StoreLogo'
import { formatTimeRange } from 'utils/datetime'
import { fromCentsDollarFormat } from 'utils/money'

const StoreDescription = ({ store, includeDetails }) => (
  <div
    className={classnames('store-description', {
      'store-description__include-details': includeDetails
    })}
  >
    <div className='store-description__title'>{store.name}</div>

    {includeDetails && (
      <div className='store-description__delivery-fees'>
        <div className='store-description__fees'>
          <div className='store-description__value'>
            {fromCentsDollarFormat(store.deliveryFeeInCents).format()}{' '}
            <span className='store-card__label'>delivery fee</span>
          </div>
          <span>&nbsp;&bull;&nbsp;</span>
          <div className='store-description__value'>
            {fromCentsDollarFormat(store.minimumPriceInCents).format()}{' '}
            <span className='store-card__label'>minimum</span>
          </div>
        </div>

        <div className='store-description__fees'>
          <div className='store-description__value'>
            <span className='store-description__label'>Good for up to</span>{' '}
            {store.maxHeadCount} people
          </div>
          <span>&nbsp;&bull;&nbsp;</span>
          <div className='store-description__value'>
            <span className='store-description__label'>Delivery hours</span>{' '}
            {formatTimeRange(store.startDeliveryTime, store.endDeliveryTime)}
          </div>
        </div>
      </div>
    )}
  </div>
)

const GroupOrderStoreInfo = ({ store, includeDetails = true }) => (
  <div className='group-order-store-info'>
    <StoreLogo store={store} />

    <StoreDescription store={store} includeDetails={includeDetails} />
  </div>
)

export default GroupOrderStoreInfo
