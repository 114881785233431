import React from 'react'
import classnames from 'classnames'

const SidebarIconField = ({ icon, iconClassName, className, children }) => {
  const Icon = icon
  return (
    <div className={classnames('sidebar-icon-field', className)}>
      <div className='sidebar-icon-field__icon'>
        <Icon className={classnames(iconClassName)} />
      </div>
      {children}
    </div>
  )
}

export default SidebarIconField
