import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { connect as connectFormik, Field } from 'formik'

import {
  format,
  isBefore,
  getHours,
  getMinutes,
  addMinutes,
  addHours,
  startOfDay,
  isSameDay
} from 'date-fns'
import times from 'lodash/times'

import { selectConfig, selectNewGroupOrder } from 'redux/selectors'
import { updateGroupOrder } from 'redux/modules/newGroupOrder'

import { fromDateTime, getFirstValidDatetime, isTimeSet } from 'utils/datetime'

import track, { INPUT_CHANGE, UPDATE_DELIVERY_TIME } from 'services/tracking'

import RoundTextSelect from 'components/newOrderWorkflow/roundTextSelect/RoundTextSelect'

const getAvailableTimes = () => {
  const startTime = addHours(startOfDay(new Date(0, 0, 0)), 10)
  return times(16, n => addMinutes(startTime, n * 15))
}

function TimeSearch ({
  updateGroupOrder,
  currentDropoff,
  deliveryHourOffset,
  validate,
  setValue,
  setFieldTouched
}) {
  const availableTimes = getAvailableTimes()

  useEffect(() => {
    const newVal = isTimeSet(currentDropoff)
      ? format(currentDropoff, 'h:mm a')
      : ''
    if (newVal !== '') {
      setValue('time', newVal)
    }
  }, [currentDropoff])

  const firstValidDateTime = getFirstValidDatetime(deliveryHourOffset)

  const [filteredAvailableTimes, setFilteredAvailableTimes] = useState([])

  const baseAvailableTimes = availableTimes.filter(at => {
    if (isSameDay(firstValidDateTime, currentDropoff)) {
      const atHour = getHours(at)
      const atMinute = getMinutes(at)
      const firstAvailHour = getHours(firstValidDateTime)
      const firstAvailMinute = getMinutes(firstValidDateTime)
      if (
        atHour < firstAvailHour ||
        (atHour === firstAvailHour && atMinute < firstAvailMinute)
      ) {
        return false
      }
    }
    return true
  })

  useEffect(() => {
    setFilteredAvailableTimes(baseAvailableTimes)
  }, [currentDropoff])

  const handleSelection = option => {
    const newTime = new Date(option.value)

    const existingDropoff = new Date(currentDropoff)
    const dateTime = fromDateTime(existingDropoff, newTime)
    if (isBefore(dateTime, firstValidDateTime)) {
      console.error('This time is not allowed (within cutoff window)')
    } else {
      const dropoff = fromDateTime(existingDropoff, newTime)
      updateGroupOrder({ dropoff })

      track({
        category: INPUT_CHANGE,
        action: UPDATE_DELIVERY_TIME
      })
    }
  }

  const currentVal = () => {
    if (getHours(currentDropoff) === 0 || !currentDropoff) {
      return null
    }
    const hours = getHours(currentDropoff)
    const minutes = getMinutes(currentDropoff)
    return {
      value: new Date(0, 0, 0, hours, minutes),
      label: format(currentDropoff, 'h:mm a')
    }
  }

  const handleBlur = () => {
    setFieldTouched('time', true)
  }

  return (
    <Field
      id='time'
      name='time'
      className='delivery-options__time'
      defaultValue={currentVal()}
      label='at'
      component={RoundTextSelect}
      onChange={option => handleSelection(option)}
      validate={validate}
      isDisabled={!currentDropoff}
      handleBlur={handleBlur}
      options={filteredAvailableTimes.map(t => ({
        label: format(t, 'h:mm a'),
        value: t
      }))}
      placeholder='12:00 PM'
      tabIndex='0'
    />
  )
}

const mapStateToProps = state => ({
  deliveryHourOffset: selectConfig(state).orderDropoffOffsetInHours,
  currentDropoff: selectNewGroupOrder(state).dropoff
})

const mapDispatchToProps = {
  updateGroupOrder
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectFormik(TimeSearch))
