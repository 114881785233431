import React, { Component } from 'react'
import { Form, Formik } from 'formik'
import { withRouter } from 'react-router'
import { Action, Button } from '@foodsby/nutrient'

import TextInput from 'components/form/TextInput'
import Field from 'components/form/formField/FormField'
import PhoneInput from 'components/form/PhoneInput'

import {
  validateName,
  validateEmail,
  validateRequired,
  validateMinLength,
  validateNoSpaces,
  composeValidators
} from 'utils/validators'
import { TERMS_AND_CONDITIONS_URL } from 'routes'

const ERROR_STATUS_EMAIL_EXISTS = 'Email already registered.'

const RegisterForm = ({
  onToggleForm,
  onToggleShowPassword,
  showPassword,
  isSubmitting,
  status
}) => (
  <Form>
    <Field
      label='First Name'
      name='firstName'
      component={TextInput}
      validate={composeValidators([validateRequired, validateName])}
      showUntouchedError
    />
    <Field
      label='Last Name'
      name='lastName'
      component={TextInput}
      validate={composeValidators([validateRequired, validateName])}
      showUntouchedError
    />
    <Field
      label='Phone Number'
      id='phone'
      name='phone'
      className='_lr-hide'
      component={PhoneInput}
      validate={composeValidators([validateRequired, validateMinLength(10)])}
      showUntouchedError
    />
    <Field
      label='Email'
      id='email'
      name='email'
      type='email'
      maxLength={254}
      component={TextInput}
      validate={composeValidators([validateRequired, validateEmail])}
      showUntouchedError
    />
    <div className='register__password-field'>
      <div className='register__show-password'>
        <Action
          style={{ fontSize: '14px' }}
          onClick={onToggleShowPassword}
          type='button'
          tabIndex='-1'
        >
          Show Password
        </Action>
      </div>
      <Field
        className='_lr-hide'
        label='Password'
        id='password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        component={TextInput}
        validate={composeValidators([
          validateRequired,
          validateMinLength(8),
          validateNoSpaces
        ])}
        showUntouchedError
      />
    </div>

    <div className='register__agree-to-tos-field'>
      By signing up, you agree to our <br className='mobile' />
      <a
        style={{ display: 'inline' }}
        target='_blank'
        rel='noopener noreferrer'
        href={TERMS_AND_CONDITIONS_URL}
        tabIndex='-1'
      >
        Terms and Conditions Agreement
      </a>
    </div>

    {status && <div className='register__error'>{status}</div>}

    {status === ERROR_STATUS_EMAIL_EXISTS && (
      <Action
        className='register__error-log-in-link mobile'
        style={{ margin: '20px 0 0 0', fontSize: '13px' }}
        onClick={onToggleForm}
        type='button'
      >
        Log in instead?
      </Action>
    )}

    <div className='register__button-group'>
      <Button
        className='primary'
        style={{ margin: 0 }}
        type='submit'
        disabled={isSubmitting}
      >
        Sign up
      </Button>

      <Action
        className='mobile'
        style={{ margin: '20px 0 0 0' }}
        onClick={onToggleForm}
        type='button'
      >
        I already have an account
      </Action>
    </div>
  </Form>
)

class Register extends Component {
  state = {
    showPassword: false
  }

  onToggleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword })

  onSubmit = async (values, actions) => {
    try {
      actions.setStatus(null)
      actions.setSubmitting(true)

      await this.props.onSubmit(values)
    } catch (error) {
      console.error('Register failed', error)
      actions.setSubmitting(false)

      if (error) {
        if (error.code === 400) {
          actions.setStatus('Unable to register')
        } else if (error.code === 409) {
          actions.setStatus(ERROR_STATUS_EMAIL_EXISTS)
          // bit of a hack to set error without a message (highlights input red only)
          actions.setFieldError('email', ' ')
        }
      } else {
        actions.setStatus(
          'Something went wrong while registering. Please contact support'
        )
      }
    }
  }

  render () {
    const { opened, onToggleForm } = this.props

    return (
      <div className='register'>
        {opened && (
          <>
            <div className='register__header'>Sign up</div>

            <Formik
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                password: ''
              }}
            >
              {props => (
                <RegisterForm
                  showPassword={this.state.showPassword}
                  onToggleShowPassword={this.onToggleShowPassword}
                  onToggleForm={onToggleForm}
                  {...props}
                />
              )}
            </Formik>
          </>
        )}

        {!opened && (
          <div className='register__content tablet-up'>
            <div className='register__header'>Sign up</div>
            <div className='register__instructions'>
              New to Foodsby? Welcome!
              <br />
              Let’s get you set up with an account.
            </div>
            <div className='register__button-group'>
              <Button
                className='primary inverted'
                style={{ margin: 0 }}
                type='button'
                onClick={onToggleForm}
              >
                Sign up
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(Register)
