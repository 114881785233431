import React from 'react'
import { Field, connect as connectFormik } from 'formik'
import classNames from 'classnames'
import get from 'lodash/get'

const FormField = ({ label, formik, showUntouchedError, ...props }) => {
  const { name } = props

  const error = get(formik.errors, name)
  const touch = get(formik.touched, name)

  const showError = error && (showUntouchedError || touch)

  return (
    <div
      className={classNames('form-field', { 'form-field--error': !!showError })}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <Field {...props} />
      {showError && <div className='form-field__error-message'>{error}</div>}
    </div>
  )
}

export default connectFormik(FormField)
