import React from 'react'

import StoreLogo from 'components/storeLogo/StoreLogo'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import DateTimeFormatted from 'components/newOrderWorkflow/shared/dateTimeFormatted/DateTimeFormatted'
import MealBudgetFormatted from 'components/newOrderWorkflow/shared/mealBudgetFormatted/MealBudgetFormatted'

const OrderDeliveryInfo = ({
  store,
  dropoff,
  attendeesCount,
  budgetPerAttendeeInCents,
  noBudget,
  onClickAction
}) => (
  <div className='order-delivery-info'>
    <StoreLogo store={store} />

    <div className='order-delivery-info__content'>
      <div className='order-delivery-info__title dfo-title'>Group Order</div>

      <div className='order-delivery-info__dropoff-and-attendees'>
        <DateTimeFormatted date={dropoff} />

        <span className='order-delivery-info__attendees'>
          &nbsp;for&nbsp;
          <span className='order-delivery-info__attendees-count'>
            {attendeesCount}&nbsp;
          </span>
          people
        </span>
      </div>

      <div className='order-delivery-info__store-and-budget'>
        <span className='order-delivery-info__store-name'>{store.name}</span>
        <span>&nbsp;|&nbsp;</span>
        <MealBudgetFormatted
          budgetPerAttendeeInCents={budgetPerAttendeeInCents}
          noBudget={noBudget}
        />
      </div>

      <DfoAction
        className='order-delivery-info__action'
        onClick={onClickAction}
      >
        Edit
      </DfoAction>
    </div>
  </div>
)

export default OrderDeliveryInfo
