import React from 'react'
import LoadingDots from 'components/loadingDots/LoadingDots'

const MobileLoadingSpinner = () => {
  return (
    <div className='mobile-loading-spinner mobile'>
      <div className='mobile-loading-spinner__dots-container'>
        <LoadingDots />
      </div>
    </div>
  )
}

export default MobileLoadingSpinner
