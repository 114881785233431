import lowerCase from 'lodash/lowerCase'

import StartNewOrderAlert from './components/StartNewOrderAlert'
import PaymentConfirmationAlert from './components/PaymentConfirmationAlert'

import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'

export const LOCATION_CHANGE_ALERT = 'LOCATION_CHANGE_ALERT'
export const RESTAURANT_CHANGE_ALERT = 'RESTAURANT_CHANGE_ALERT'
export const BUDGET_CHANGE_ALERT = 'BUDGET_CHANGE_ALERT'
export const BACKUP_MEAL_CHANGE_ALERT = 'BACKUP_MEAL_CHANGE_ALERT'

export const PAYMENT_CONFIRMATION_ALERT = 'PAYMENT_UPDATE_NEEDED_ALERT'
export const START_NEW_ORDER_ALERT = 'START_NEW_ORDER_ALERT'

export const INVALID_DATE_ALERT = 'INVALID_DATE_ALERT'
export const DAY_DISABLED_ALERT = 'DAY_DISABLED_ALERT'
export const INCOMPLETE_STEP_ALERT = 'INCOMPLETE_STEP_ALERT'
export const INCOMPLETE_RESTAURANT_STEP_ALERT =
  'INCOMPLETE_RESTAURANT_STEP_ALERT'

export const BAD_DELIVERY_DETAILS_ALERT = 'BAD_DELIVERY_DETAILS_ALERT'

export const buildIncompleteStepAlert = stepName =>
  `Please complete ${lowerCase(stepName)} before proceeding.`

export const mapAlert = messageName => {
  switch (messageName) {
    case LOCATION_CHANGE_ALERT:
      return {
        message:
          'By changing your location, your in-progress order will be lost.',
        shortMessage:
          'By changing your location your existing order will be lost.'
      }
    case RESTAURANT_CHANGE_ALERT:
      return {
        message:
          'If you choose a different restaurant, this order will be lost.',
        shortMessage:
          'By changing your restaurant your existing order will be lost.',
        Icon: AlertErrorIcon
      }
    case BUDGET_CHANGE_ALERT:
      return {
        message: 'Please review your budget.',
        shortMessage: 'Please review your budget.'
      }
    case BACKUP_MEAL_CHANGE_ALERT:
      return {
        message:
          'Updating the backup meal will affect your budget. When you’re done editing the backup meal, you’ll review your budget again.',
        shortMessage: 'Updating the backup meal may affect your budget.',
        Icon: AlertErrorIcon
      }
    case PAYMENT_CONFIRMATION_ALERT:
      return {
        Component: PaymentConfirmationAlert
      }
    case START_NEW_ORDER_ALERT:
      return {
        Component: StartNewOrderAlert
      }
    case INVALID_DATE_ALERT:
      return {
        message:
          'You have entered an invalid date. Please enter a date in MM/DD/YY format, or use the date selector.',
        shortMessage:
          'You have entered an invalid date. Please enter a date in MM/DD/YY format, or use the date selector.'
      }
    case DAY_DISABLED_ALERT:
      return {
        message:
          'You have entered a date that Foodsby Meetings is closed. Please enter a different date.'
      }
    case BAD_DELIVERY_DETAILS_ALERT:
      return {
        message: '', // this will be set in the override
        Icon: AlertErrorIcon
      }
    case INCOMPLETE_STEP_ALERT:
      return {
        message: '', // this will be set in the override
        Icon: AlertErrorIcon
      }
    case INCOMPLETE_RESTAURANT_STEP_ALERT:
      return {
        message: 'Please choose a restaurant.',
        shortMessage: 'Please choose a restaurant.',
        Icon: AlertErrorIcon
      }
    default:
      return {}
  }
}
