import React from 'react'

import { ReactComponent as ChevronIcon } from 'assets/dfo/icon--chevron.svg'
import RoundButton from './roundButton/RoundButton'

const DfoSubmitButton = ({ label, onClick, disabled, submitting }) => (
  <RoundButton
    label={
      <>
        {label}
        <ChevronIcon
          style={{ marginLeft: '8px', transform: 'translateY(2px)' }}
        />
      </>
    }
    onClick={onClick}
    submitting={submitting}
    disabled={disabled}
  />
)

export default DfoSubmitButton
