import React, { Component } from 'react'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import { Input } from '@foodsby/nutrient'

/*
 * To get rid of an annoying console warning the customInput needs to be class component :/
 * (see: https://github.com/s-yadav/react-number-format/issues/334#issuecomment-542412771)
 */
class WrappedInput extends Component {
  render () {
    return <Input {...this.props} />
  }
}

const RoundPhoneInput = ({ field, form, ...rest }) => {
  const error = !!form.errors[field.name]
  const touched = !!form.touched[field.name]

  return (
    <div
      className={classnames('round-phone-input', {
        'round-phone-input--error': error && touched
      })}
    >
      <NumberFormat
        format='(###) ###-####'
        customInput={WrappedInput}
        value={form.values[field.name]}
        onValueChange={({ value }) =>
          form.setFieldValue(field.name, value.replace(/\D/g, ''))
        }
        onBlur={evt => field.onBlur(evt)}
        {...rest}
      />
    </div>
  )
}

export default RoundPhoneInput
