import isEmpty from 'lodash/isEmpty'

export const formatAddress = location => {
  if (isEmpty(location)) {
    return ''
  }
  const { street, city, state, zip } = location
  return `${street}, ${city}, ${state}, ${zip}`
}

export const formatTenDigitPhoneNumber = phoneStr =>
  phoneStr
    ? phoneStr.replace(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g, '($1) $2-$3')
    : phoneStr
