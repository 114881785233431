import { Component } from 'react'
import { formatRoute } from 'react-router-named-routes'

import {
  ATTENDEE_TOKEN_LOGIN_PATH,
  ATTENDEE_TOKEN_MEAL_PATH,
  ATTENDEE_TOKEN_STATUS_PATH
} from 'routes'
import { joinGroupOrder } from 'api'
import { ORDER_PAST_CUTOFF, ALL_ATTENDEE_SLOTS_TAKEN } from 'errors'
import { isCancelled } from 'utils/order'

class TokenAttendee extends Component {
  goToStatusPage = token => {
    this.props.history.replace(
      formatRoute(ATTENDEE_TOKEN_STATUS_PATH, { token })
    )
  }

  async componentDidMount () {
    const { tokenResponse } = this.props
    const token = this.props.match.params.token
    const groupOrderId = tokenResponse.id

    try {
      if (isCancelled(tokenResponse)) {
        this.goToStatusPage(token)
        return
      }

      await joinGroupOrder(groupOrderId)

      this.props.history.replace(
        formatRoute(ATTENDEE_TOKEN_MEAL_PATH, { token })
      )
    } catch (error) {
      console.error(error)
      if (error && error.code >= 400 && error.code < 500) {
        if (error.code === 401) {
          this.props.history.replace(
            formatRoute(ATTENDEE_TOKEN_LOGIN_PATH, { token })
          )
        } else if (
          error.message === ORDER_PAST_CUTOFF ||
          error.message === ALL_ATTENDEE_SLOTS_TAKEN
        ) {
          this.goToStatusPage(token)
        } else {
          throw error
        }
      } else {
        throw error
      }
    }
  }

  render () {
    return null
  }
}

export default TokenAttendee
