import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import AbandonOrderPrompt from 'components/newOrderWorkflow/shared/AbandonOrderPrompt'
import { MEAL_BUDGET_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'
import {
  INCOMPLETE_STEP_ALERT,
  buildIncompleteStepAlert
} from 'components/newOrderWorkflow/navAlerts/constants'
import MealBudgetMain from './mealBudgetMain/MealBudgetMain'
import MealBudgetSidebar from './mealBudgetSidebar/MealBudgetSidebar'
import {
  newGroupOrder,
  selectCurrentUser,
  selectNewGroupOrder
} from 'redux/selectors'
import { setNavAlert } from 'redux/modules/alerts'
import { setDeliveryOptionsFormOpen } from 'redux/modules/newGroupOrder'
import { getStores } from 'redux/modules/store'
import { routeToHomeIfNoData } from 'higherOrderComponents'
import { NEW_DELIVERY_DETAILS_PATH } from 'routes'

const MealBudgetForm = props => (
  <Form style={{ height: '100%' }}>
    <OrderWorkflowLayout
      headerProps={{
        stepName: MEAL_BUDGET_ORDER_STEP,
        onClickFutureStep: (step, isComplete) => {
          if (!props.isValid) {
            props.setNavAlert({
              name: INCOMPLETE_STEP_ALERT,
              messageOverride: buildIncompleteStepAlert(MEAL_BUDGET_ORDER_STEP)
            })
          } else if (isComplete) {
            props.history.push(step.path)
          }
        },
        onClickNextStep: step => {
          if (!props.isValid) {
            props.setNavAlert({
              name: INCOMPLETE_STEP_ALERT,
              messageOverride: buildIncompleteStepAlert(MEAL_BUDGET_ORDER_STEP)
            })
          } else {
            props.submitForm()
          }
        },
        showNextStepLabel: props.isValid
      }}
      mainComponent={<MealBudgetMain {...props} />}
      sidebarComponent={<MealBudgetSidebar {...props} />}
    />
  </Form>
)

let MealBudgetPage = ({
  currentUser,
  locationId,
  budgetPerAttendeeInCents,
  getStores,
  setDeliveryOptionsFormOpen,
  setNavAlert,
  history
}) => {
  useEffect(() => {
    const fetchStores = async () => await getStores(locationId)
    fetchStores()
  }, [locationId])

  const onSubmit = () => history.push(NEW_DELIVERY_DETAILS_PATH)

  return (
    <DfoLayout currentUser={currentUser}>
      <Formik
        initialValues={{ budgetPerAttendeeInCents }}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {props => (
          <MealBudgetForm
            setDeliveryOptionsFormOpen={setDeliveryOptionsFormOpen}
            setNavAlert={setNavAlert}
            history={history}
            {...props}
          />
        )}
      </Formik>

      <AbandonOrderPrompt />
    </DfoLayout>
  )
}

MealBudgetPage = withRouter(MealBudgetPage)

MealBudgetPage = routeToHomeIfNoData(MealBudgetPage)

const mapStateToProps = state => ({
  newGroupOrder: selectNewGroupOrder(state),
  currentUser: selectCurrentUser(state),
  locationId: newGroupOrder.selectLocationId(state),
  budgetPerAttendeeInCents: newGroupOrder.selectBudgetPerAttendeeInCents(state)
})

const mapDispatchToProps = {
  getStores,
  setNavAlert,
  setDeliveryOptionsFormOpen
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MealBudgetPage)
