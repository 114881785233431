import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import MediaQuery from 'react-responsive'
import find from 'lodash/find'

import AppLayoutWithSidebar from 'components/appLayoutWithSidebar/AppLayoutWithSidebar'
import AttendeeMealStatusMain from './attendeeMealStatusMain/AttendeeMealStatusMain'
import AttendeeMealStatusSidebar from './attendeeMealStatusSidebar/AttendeeMealStatusSidebar'
import { getByToken } from 'api'
import { getLocation } from 'redux/modules/location'
import { selectLocation, selectCurrentUser } from 'redux/selectors'
import { toDisplayName } from 'utils/user'

class AttendeeMealStatusPage extends Component {
  state = {
    groupOrder: null
  }

  async componentDidMount () {
    const { token } = this.props.match.params

    const tokenResponse = await getByToken(token)

    this.props.getLocation(tokenResponse.monolithLocationId)

    this.setState({ groupOrder: tokenResponse })
  }

  getAttendee = groupOrder =>
    find(groupOrder.attendees, a => a.userId === this.props.currentUser.userId)

  render () {
    const { currentUser, currentLocation } = this.props
    const { groupOrder } = this.state

    if (!groupOrder || !currentLocation) {
      return null
    }

    const attendee = this.getAttendee(groupOrder)
    const attendeeMeal = attendee ? attendee.meals : null

    const meetingDetailsHeaderText = `${toDisplayName(
      groupOrder.owner
    )}'s Order`

    return (
      <AppLayoutWithSidebar
        mobileReady
        headerProps={{ fixedHeader: true }}
        mainComponent={
          <AttendeeMealStatusMain
            currentUser={currentUser}
            currentLocation={currentLocation}
            groupOrder={groupOrder}
            attendee={attendee}
          />
        }
        sidebarComponent={
          <MediaQuery query='(min-width: 769px)'>
            <AttendeeMealStatusSidebar
              currentUser={currentUser}
              currentLocation={currentLocation}
              groupOrder={groupOrder}
              attendee={attendee}
              meal={attendeeMeal}
              meetingDetailsHeaderText={meetingDetailsHeaderText}
            />
          </MediaQuery>
        }
      />
    )
  }
}

const mapStateToProps = state => ({
  currentLocation: selectLocation(state),
  currentUser: selectCurrentUser(state)
})

const mapDispatchToProps = {
  getLocation
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AttendeeMealStatusPage)
)
