import React from 'react'

import { ReactComponent as OpenIcon } from 'assets/dfo/icon--summary.svg'
import { ReactComponent as CloseIcon } from 'assets/dfo/icon--close.svg'

const FloatingButton = ({ onClick, open }) => (
  <button type='button' className='floating-button' onClick={onClick}>
    {open ? <CloseIcon /> : <OpenIcon />}
  </button>
)

export default FloatingButton
