import React from 'react'
import { Button, Spinner } from '@foodsby/nutrient'
import classnames from 'classnames'

const RoundButton = ({
  label,
  children,
  className,
  type = 'submit',
  submitting,
  disabled,
  ...rest
}) => (
  <Button
    type={type}
    className={classnames('round-button', className, {
      'round-button--submitting': submitting
    })}
    disabled={disabled || submitting}
    {...rest}
  >
    {submitting && <Spinner className='round-button__spinner' />}
    {label || children}
  </Button>
)

export default RoundButton
