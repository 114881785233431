import React from 'react'

import AppHeader from 'components/appHeader/AppHeader'
import AppFooter from 'components/appFooter/AppFooter'

const AppLayout = ({ currentUser, headerProps, children }) => (
  <>
    <AppHeader {...headerProps} />
    {children}
    <AppFooter />
  </>
)
export default AppLayout
