import { fromDollars, dollarsToCents } from 'utils/money'

export const getPriceText = price =>
  price === 0 ? '' : `+ ${fromDollars(price).format()}`

export const hasOrIsEqual = (value, other) =>
  value && (value === other || (value.includes && value.includes(other)))

export const isAnswerSelected = (values, parents) => {
  let parentsWithoutLast = [...parents]
  const parentAnswer = parentsWithoutLast.pop()
  const value = values[`q_${parentsWithoutLast.join('_')}`]
  return hasOrIsEqual(value, parentAnswer)
}

export const getPriceOf = (questions, answerId) => {
  for (let q of questions) {
    const answer = q.answers.find(a => a.id === answerId)
    if (answer) {
      return fromDollars(answer.price)
    }
  }
  return fromDollars(0)
}

export const calculatePrice = (menuItem, values, questions) => {
  const menuItemPrice = fromDollars(menuItem.price)
  return Object.keys(values).reduce((acc, key) => {
    const ids = values[key]
    if (!ids || ids.length < 1) {
      return acc
    } else if (Array.isArray(ids)) {
      const arrayReduce = ids.reduce((a, answerId) => {
        const answerPrice = getPriceOf(questions, answerId)
        return a.add(answerPrice)
      }, fromDollars(0))
      return acc.add(arrayReduce)
    } else {
      return acc.add(getPriceOf(questions, ids))
    }
  }, menuItemPrice)
}

export const normalizeFormValues = (menuItem, values, questions) => {
  const totalPrice = calculatePrice(menuItem, values, questions)
  const transformedQuestions = questions.map(q => {
    const valueContainsAnswer = a => hasOrIsEqual(values[q.formId], a.id)
    const transformAnswer = a => ({
      answerId: a.id,
      name: a.text,
      priceInCents: dollarsToCents(a.price)
    })
    const transformedAnswers = q.answers
      .filter(valueContainsAnswer)
      .map(transformAnswer)
    return {
      questionId: q.id,
      depth: q.depth,
      name: q.text,
      parentQuestion: q.parentQuestion,
      parentAnswer: q.parentAnswer,
      answers: transformedAnswers
    }
  })
  return {
    menuItemId: menuItem.id,
    name: menuItem.text,
    priceInCents: dollarsToCents(menuItem.price),
    totalPriceInCents: totalPrice.intValue,
    modifiers: transformedQuestions,
    specialInstructions: values.specialInstructions
  }
}
