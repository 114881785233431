import React from 'react'
import noop from 'lodash/noop'
import trim from 'lodash/trim'

import DfoFormField from 'components/newOrderWorkflow/dfoFormField/DfoFormField'
import RoundTextInput from 'components/newOrderWorkflow/roundTextInput/RoundTextInput'
import { RoundTextareaField } from 'components/newOrderWorkflow/roundTextarea/RoundTextarea'
import RoundPhoneInput from 'components/newOrderWorkflow/roundPhoneInput/RoundPhoneInput'
import {
  composeValidators,
  validateRequired,
  validateName,
  validateEmail,
  validateMinLength,
  validateMaxLength
} from 'utils/validators'

const DeliveryDetails = ({
  setDropoffInstructions = noop,
  setContactInfo = noop,
  contactPhoneNumberLabel = 'Phone Number',
  contactPhoneExtensionLabel = 'Extension',
  suiteNumberLabel = 'Suite Number',
  dropoffInstructionsLabel = 'Delivery Instructions'
}) => {
  const handleContactInfo = name => val => {
    setContactInfo({ [name]: trim(val) })
    return undefined
  }

  return (
    <div className='delivery-details'>
      <div className='delivery-details__form-fields'>
        <DfoFormField
          id='contactName'
          name='contactName'
          className='delivery-details__contact-name'
          label='Contact'
          placeholder='Jane Dough'
          validate={composeValidators(
            [validateRequired, validateName],
            handleContactInfo('contactName')
          )}
          component={RoundTextInput}
        />

        <DfoFormField
          id='contactPhoneNumber'
          name='contactPhoneNumber'
          className='delivery-details__contact-phone-number'
          label={contactPhoneNumberLabel}
          placeholder='(123) 456-7890'
          validate={composeValidators(
            [validateRequired, validateMinLength(10)],
            handleContactInfo('contactPhoneNumber')
          )}
          component={RoundPhoneInput}
        />

        <DfoFormField
          id='contactPhoneExtension'
          name='contactPhoneExtension'
          className='delivery-details__contact-phone-extension'
          label={contactPhoneExtensionLabel}
          placeholder='123'
          subtext='Optional'
          validate={handleContactInfo('contactPhoneExtension')}
          component={RoundTextInput}
        />

        <DfoFormField
          id='contactEmail'
          name='contactEmail'
          className='delivery-details__contact-email'
          label='Email'
          placeholder='jane.dough@example.com'
          validate={composeValidators(
            [validateRequired, validateEmail],
            handleContactInfo('contactEmail')
          )}
          component={RoundTextInput}
        />

        <DfoFormField
          id='suiteNumber'
          name='suiteNumber'
          className='delivery-details__suite-number'
          label={suiteNumberLabel}
          placeholder='900a'
          subtext='Optional'
          validate={handleContactInfo('suiteNumber')}
          component={RoundTextInput}
        />

        <DfoFormField
          id='dropoffInstructions'
          name='dropoffInstructions'
          className='delivery-details__dropoff-instructions'
          label={dropoffInstructionsLabel}
          placeholder='Include information to help the driver find and access your location.'
          validate={composeValidators(
            [validateRequired, validateMaxLength(300)],
            setDropoffInstructions
          )}
          component={RoundTextareaField}
          maxLength={300}
          rows={4}
        />
      </div>
    </div>
  )
}

export default DeliveryDetails
