import React from 'react'
import classnames from 'classnames'

import DfoCollapsible from 'components/newOrderWorkflow/shared/dfoCollapsible/DfoCollapsible'

const SectionHeader = ({ title, subtitle }) => (
  <header className='order-section__header'>
    <h2>
      <span className='order-section__title'>{title} </span>
      {subtitle && <span className='order-section__subtitle'>{subtitle}</span>}
    </h2>
  </header>
)

const Section = ({ children, className, header }) => (
  <section className={classnames('order-section', className)}>
    {header}
    <div className='order-section__content'>{children}</div>
  </section>
)

const OrderSection = ({
  id,
  collapsible,
  initalOpen,
  children,
  headerProps,
  ...rest
}) => {
  const header = <SectionHeader {...headerProps} />

  if (collapsible) {
    return (
      <DfoCollapsible
        id={id}
        className='order-section--collabsible'
        initialOpen={initalOpen}
        trigger={header}
      >
        <Section {...rest}>{children}</Section>
      </DfoCollapsible>
    )
  }

  return (
    <Section className='order-section--static' header={header} {...rest}>
      {children}
    </Section>
  )
}

export default OrderSection
