import React from 'react'
import classnames from 'classnames'

const SidebarLineItem = ({ label, value, className }) => (
  <div className={classnames('sidebar-line-item', className)}>
    <div className='sidebar-line-item__label'>{label}</div>
    <div className='sidebar-line-item__value'>{value}</div>
  </div>
)

export default SidebarLineItem
