import React, { useState } from 'react'
import classnames from 'classnames'

import { Select } from '@foodsby/nutrient'

import { connect as connectFormik } from 'formik'

const selectStyle = {
  container: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    borderRadius: 20,
    height: 32
  }),
  control: (styles, state) => ({
    ...styles,
    borderRadius: 20,
    borderBottomLeftRadius: state.isFocused && state.menuIsOpen ? 0 : 20,
    borderBottomRightRadius: state.isFocused && state.menuIsOpen ? 0 : 20,
    backgroundColor: state.isFocused ? 'white' : '#3d4a6b',
    maxHeight: 32,
    minHeight: 32,
    color: '#a8aebd',
    border: state.isFocused ? '0.5px solid white' : '0.5px solid #3d4a6b',
    boxShadow: 'none'
  }),
  input: styles => ({ ...styles }),
  valueContainer: styles => ({ ...styles, height: 32 }),
  placeholder: styles => ({
    ...styles,
    fontFamily: 'roboto',
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#a8aebd'
  }),
  menu: styles => ({
    ...styles,
    marginTop: 0,
    borderRadius: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: 'transparent',
    paddingBottom: 8,
    paddingTop: 8
  }),
  singleValue: styles => ({
    ...styles,
    fontFamily: 'roboto',
    fontSize: 13,
    fontWeight: 500
  }),
  dropdownIndicator: styles => ({ ...styles, height: 32, paddingTop: 5 }),
  indicatorSeparator: styles => ({
    ...styles,
    height: 0,
    backgroundColor: 'transparent'
  }),
  option: (styles, state) => ({
    ...styles,
    fontSize: 13,
    fontWeight: 500,
    color: state.isSelected || state.isFocused ? 'white' : 'black',
    backgroundColor:
      state.isSelected || state.isFocused ? '#59969b' : 'transparent',
    paddingTop: 3,
    paddingBottom: 3
  })
}

const theme = existingTheme => ({
  ...existingTheme,
  colors: {
    ...existingTheme.colors,
    primary: '#59969b'
  }
})

const RoundTextSelect = ({
  field,
  form,
  options,
  className,
  showOutline,
  handleBlur,
  label,
  ...rest
}) => {
  const error = !!form.errors[field.name]
  const touched = !!form.touched[field.name]

  const [isActive, setIsActive] = useState(false)

  const internalHandleBlur = () => {
    setIsActive(false)
    handleBlur()
  }
  return (
    <div
      className={classnames(`round-text-select ${className}`, {
        'round-text-select--error': error && touched,
        'round-text-select--active': isActive,
        'round-text-select--show-outline': showOutline
      })}
    >
      {label && <label>{label}</label>}
      <Select
        maxMenuHeight={320}
        aria
        options={options}
        styles={selectStyle}
        onFocus={() => setIsActive(true)}
        onBlur={internalHandleBlur}
        classNamePrefix='dfo-select'
        theme={theme}
        {...rest}
      />
    </div>
  )
}

export default connectFormik(RoundTextSelect)
