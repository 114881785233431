import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import { Input } from '@foodsby/nutrient'

const RoundTextInput = ({
  field,
  form,
  showDropdown,
  DropdownChildren,
  iconPlacement,
  ActiveIcon,
  InactiveIcon,
  closeRef,
  closeCallback,
  onFocus,
  buttonLeftText,
  buttonRightText,
  onButtonLeftClick,
  onButtonRightClick,
  handleKeyDown,
  ...rest
}) => {
  const error = !!form.errors[field.name]
  const touched = !!form.touched[field.name]

  const inputRef = useRef()

  const [inputActive, setInputActive] = useState(false)

  const renderIcons = () => {
    return (
      <span className='input-icon-container'>
        {inputActive && <ActiveIcon />}
        {!inputActive && <InactiveIcon onClick={() => setInputActive(true)} />}
      </span>
    )
  }

  const handleInputBlur = () => {
    //input is made inactive via clicking the background div when a dropdown is present.
    if (!showDropdown) {
      setInputActive(false)
    }
  }

  const processKeyDown = e => {
    if (e.keyCode === 9 && inputActive) {
      setInputActive(false)
    }
    if (handleKeyDown) {
      handleKeyDown(e)
    }
  }

  return (
    <div
      className={classnames('round-text-input', {
        'round-text-input--error':
          error && touched && (showDropdown ? !inputActive : true),
        'round-text-input__with-dropdown': showDropdown && inputActive,
        'round-text-input--active': inputActive,
        'round-text-input__icon-left': iconPlacement === 'left',
        'round-text-input__icon-right': iconPlacement === 'right',
        'round-text-input__with-btn-left': !!buttonLeftText,
        'round-text-input__with-btn-right': !!buttonRightText
      })}
      onFocus={() => setInputActive(true)}
      onBlur={handleInputBlur}
      onKeyDown={processKeyDown}
      ref={inputRef}
    >
      {!!buttonLeftText && (
        <button
          tabIndex='-1'
          className='input-left-btn'
          onClick={onButtonLeftClick}
          type='button'
        >
          {buttonLeftText}
        </button>
      )}
      {iconPlacement === 'left' && renderIcons()}
      <Input type='text' {...field} {...rest} onKeyDown={processKeyDown} />
      {iconPlacement === 'right' && !rest.disabled && renderIcons()}
      {!!buttonRightText && (
        <button
          tabIndex='-1'
          className='input-right-btn'
          onClick={onButtonRightClick}
          type='button'
        >
          {buttonRightText}
        </button>
      )}
      {showDropdown && inputActive && (
        <>
          <div
            ref={closeRef}
            className='dfo-form-field__dropdown-background'
            onClick={() => {
              setInputActive(false)
              if (!!closeCallback) {
                closeCallback()
              }
            }}
          />
          <div className='dfo-form-field__dropdown'>
            <DropdownChildren />
          </div>
        </>
      )}
    </div>
  )
}

export default RoundTextInput
