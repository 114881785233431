import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import AppLayout from 'components/appLayout/AppLayout'
import GroupOrderListMain from './groupOrderListMain/GroupOrderListMain'
import {
  getAllGroupOrders,
  getAttendeeGroupOrders
} from 'redux/modules/groupOrder'
import { selectCurrentUser } from 'redux/selectors'

class GroupOrderListPage extends Component {
  componentDidMount () {
    this.props.getAllGroupOrders()
    this.props.getAttendeeGroupOrders()
  }

  render () {
    const { currentUser } = this.props

    return (
      <AppLayout currentUser={currentUser} headerProps={{ fixedHeader: true }}>
        <GroupOrderListMain />
      </AppLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
})

const mapDispatchToProps = {
  getAllGroupOrders,
  getAttendeeGroupOrders
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroupOrderListPage)
)
