import React, { Component } from 'react'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'

import { ReactComponent as LeftChevronIcon } from 'assets/dfo/icon--chevron-left-navy-blue.svg'
import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import DfoPageLayout from 'components/newOrderWorkflow/dfoPageLayout/DfoPageLayout'
import MenuPage from 'components/newOrderWorkflow/menuPage/MenuPage'
import GroupOrderStoreInfo from 'components/groupOrderStoreInfo/GroupOrderStoreInfo'
import EditBackupMealSidebar from './EditBackupMealSidebar'
import { loadGroupOrder } from 'api'
import { getLocation } from 'redux/modules/location'
import { updateDefaultMeal } from 'redux/modules/groupOrder'
import { selectCurrentUser, selectMeal, selectLocation } from 'redux/selectors'
import { EDIT_GROUP_ORDER_PATH } from 'routes'

const EditBackupMealMenuHeader = ({ store, groupOrderId }) => (
  <div className='edit-backup-meal-menu-header'>
    <Link
      className='dfo-back-link'
      to={formatRoute(EDIT_GROUP_ORDER_PATH, {
        groupOrderId: groupOrderId
      })}
    >
      <LeftChevronIcon />
      Order #{groupOrderId}
    </Link>
    <GroupOrderStoreInfo store={store} includeDetails={false} />
  </div>
)

class EditBackupMealPage extends Component {
  state = {
    groupOrder: null,
    submitting: false,
    submitAttempted: false
  }

  async componentDidMount () {
    const groupOrder = await loadGroupOrder(
      this.props.match.params.groupOrderId
    )
    this.props.getLocation(groupOrder.monolithLocationId)
    this.setState({ groupOrder })
  }

  saveMeal = async meal => {
    this.setState({ submitAttempted: true })

    if (this.isValidMeal(meal)) {
      const body = meal.map(mealItem =>
        pick(mealItem, ['menuItemId', 'modifiers', 'id', 'specialInstructions'])
      )
      this.setState({ submitting: true })
      await this.props.updateDefaultMeal(this.state.groupOrder.id, body)
      this.goBack()
    }
  }

  goBack = () =>
    this.props.history.push(
      formatRoute(EDIT_GROUP_ORDER_PATH, {
        groupOrderId: this.state.groupOrder.id
      })
    )

  isValidMeal = meal => !isEmpty(meal)

  render () {
    const { currentUser, location, meal } = this.props
    const { groupOrder, submitting, submitAttempted } = this.state

    if (!groupOrder) {
      return null
    }

    const isValid = this.isValidMeal(meal)

    return (
      <DfoLayout currentUser={currentUser} headerProps={{ fixedHeader: true }}>
        <MenuPage
          isBackupMeal
          initialMeal={groupOrder.defaultMeals}
          groupOrder={groupOrder}
          currentStore={groupOrder.store}
          submitting={submitting}
          submitAttempted={submitAttempted}
          saveMeal={this.saveMeal}
          isValid={isValid}
          layoutComponent={props => <DfoPageLayout {...props} />}
          menuHeaderComponent={props => (
            <EditBackupMealMenuHeader groupOrderId={groupOrder.id} {...props} />
          )}
          sidebarComponent={props => (
            <EditBackupMealSidebar
              currentLocation={location}
              currentStore={groupOrder.store}
              {...props}
            />
          )}
        />
      </DfoLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  meal: selectMeal(state),
  location: selectLocation(state)
})

const mapDispatchToProps = {
  getLocation,
  updateDefaultMeal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBackupMealPage)
