import React from 'react'
import { connect } from 'react-redux'

import MealBudget from 'components/newOrderWorkflow/mealBudget/MealBudget'

import { newGroupOrder } from 'redux/selectors'
import { setBudget, setNoBudget } from 'redux/modules/newGroupOrder'

const MealBudgetMain = ({
  errors,
  touched,
  dirty,
  setFieldValue,
  defaultMeal,
  noBudget,
  setBudget,
  setNoBudget
}) => (
  <div className='meal-budget-main'>
    <div className='meal-budget-main__content'>
      <h1 className='dfo-h1'>How much can attendees spend?</h1>
      <MealBudget
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        dirty={dirty}
        defaultMeal={defaultMeal}
        noBudget={noBudget}
        setBudget={setBudget}
        setNoBudget={setNoBudget}
        divide
      />
    </div>
  </div>
)

const mapStateToProps = state => ({
  defaultMeal: newGroupOrder.selectDefaultMeal(state),
  noBudget: newGroupOrder.selectNoBudget(state)
})

const mapDispatchToProps = {
  setBudget,
  setNoBudget
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MealBudgetMain)
