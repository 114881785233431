import React from 'react'

import StoreLogo from 'components/storeLogo/StoreLogo'
import SidebarIconField from 'components/sidebarIconField/SidebarIconField'
import { formatDateTime, formatDateTimeNoWeekday } from 'utils/datetime'
import { formatAddress } from 'utils/formatters'
import { ReactComponent as CalendarIcon } from 'assets/icon--calendar.svg'
import { ReactComponent as PinIcon } from 'assets/icon--pin.svg'
import { ReactComponent as LockIcon } from 'assets/icon--lock.svg'

const AttendeeOrderSummary = ({
  store,
  location,
  ownerName,
  cutoff,
  dropoff
}) => (
  <div className='attendee-order-summary'>
    <div className='attendee-order-summary__content'>
      <StoreLogo store={store} />

      <div className='attendee-order-summary__body'>
        <div className='attendee-order-summary__header'>
          Join {ownerName}'s Order
        </div>

        <div className=''>
          <SidebarIconField icon={PinIcon}>
            <span>{formatAddress(location)}</span>
          </SidebarIconField>

          <SidebarIconField icon={CalendarIcon}>
            <span>{formatDateTime(dropoff)}</span>
          </SidebarIconField>

          <SidebarIconField icon={LockIcon} iconClassName='lock'>
            <span>Order locks on {formatDateTimeNoWeekday(cutoff)}</span>
          </SidebarIconField>
        </div>
      </div>
    </div>

    {store && (
      <div className='attendee-order-summary__choose-meal'>
        Choose your meal
        <br />
        from {store.name}
      </div>
    )}
  </div>
)

export default AttendeeOrderSummary
