import React from 'react'
import classnames from 'classnames'

import { formatDateTimeAsParts, isTimeSet } from 'utils/datetime'

const DateTimeFormatted = ({ date, boldFont = true }) => {
  const dateParts = formatDateTimeAsParts(date)

  const time = isTimeSet(date) ? dateParts.time : null

  return (
    <span
      className={classnames('date-time-formatted', {
        'date-time-formatted--bold': boldFont
      })}
    >
      <span>
        <span
          className={classnames('date-time-formatted__date', {
            'date-time-formatted--placeholder': !dateParts.date
          })}
        >
          {dateParts.date ? dateParts.date : 'mm/dd/yy'}
        </span>
        <span className='date-time-formatted__ordinal'>
          {dateParts.dayOrdinal}
        </span>
      </span>

      <span>
        <span className='date-time-formatted__at'>&nbsp;at&nbsp;</span>
        <span
          className={classnames('date-time-formatted__time', {
            'date-time-formatted--placeholder': !time
          })}
        >
          {time ? time : '12:00'}
        </span>
        <span
          className={classnames('date-time-formatted__meridiem', {
            'date-time-formatted--placeholder': !time
          })}
        >
          &nbsp;{time ? dateParts.meridiem : 'PM'}
        </span>
      </span>
    </span>
  )
}

export default DateTimeFormatted
