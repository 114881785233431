import React from 'react'
import size from 'lodash/size'
import map from 'lodash/map'

import {
  AlertErrorMessage,
  AlertSuccessMessage
} from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import { AttendeeRow, AddAttendeeRow } from './attendeeRow/AttendeeRow'

const AttendeeList = ({
  attendees,
  defaultMeals,
  onAddAttendee,
  onRemoveAttendee,
  onSelectAttendee,
  editable,
  submitting,
  complete,
  min,
  max
}) => {
  const attendeeCount = size(attendees)
  const maxAttendees = attendeeCount >= max
  const canRemove = editable && attendeeCount > min

  return (
    <div className='attendee-list'>
      {map(attendees, (attendee, index) => (
        <AttendeeRow
          key={index}
          index={index}
          defaultMeals={defaultMeals}
          attendee={attendee}
          editable={editable}
          canRemove={canRemove}
          onRemoveAttendee={onRemoveAttendee}
          onEditAttendee={onSelectAttendee}
          recentlyAdded={complete && attendeeCount - 1 === index}
        />
      ))}

      {editable && !maxAttendees && (
        <>
          {complete && (
            <AlertSuccessMessage>
              Added!
              <div className='attendee-list__alert-subtext'>
                Adding an attendee will update your estimated meal total.
              </div>
            </AlertSuccessMessage>
          )}

          <AddAttendeeRow
            attendeeCount={attendeeCount + 1}
            defaultMeals={defaultMeals}
            onAddAttendee={onAddAttendee}
            submitting={submitting}
          />
        </>
      )}

      {editable && maxAttendees && (
        <AlertErrorMessage>
          You have reached the maximum number of attendees for this restaurant
        </AlertErrorMessage>
      )}
    </div>
  )
}

export default AttendeeList
