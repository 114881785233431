import { ReactComponent as LocationIcon } from 'assets/dfo/icon--location.svg'
import { ReactComponent as LocationIconActive } from 'assets/dfo/icon--location--active.svg'
import { ReactComponent as LocationIconDisabled } from 'assets/dfo/icon--location--disabled.svg'
import { ReactComponent as RestaurantIcon } from 'assets/dfo/icon--restaurant.svg'
import { ReactComponent as RestaurantIconActive } from 'assets/dfo/icon--restaurant--active.svg'
import { ReactComponent as RestaurantIconDisabled } from 'assets/dfo/icon--restaurant--disabled.svg'
import { ReactComponent as BackupMealIcon } from 'assets/dfo/icon--backup-meal.svg'
import { ReactComponent as BackupMealIconActive } from 'assets/dfo/icon--backup-meal--active.svg'
import { ReactComponent as BackupMealIconDisabled } from 'assets/dfo/icon--backup-meal--disabled.svg'
import { ReactComponent as MealBudgetIcon } from 'assets/dfo/icon--meal-budget.svg'
import { ReactComponent as MealBudgetIconActive } from 'assets/dfo/icon--meal-budget--active.svg'
import { ReactComponent as MealBudgetIconDisabled } from 'assets/dfo/icon--meal-budget--disabled.svg'
import { ReactComponent as DeliveryDetailsIcon } from 'assets/dfo/icon--delivery-details.svg'
import { ReactComponent as DeliveryDetailsIconActive } from 'assets/dfo/icon--delivery-details--active.svg'
import { ReactComponent as DeliveryDetailsIconDisabled } from 'assets/dfo/icon--delivery-details--disabled.svg'
import { ReactComponent as OrderSummaryIcon } from 'assets/dfo/icon--order-summary.svg'
import { ReactComponent as OrderSummaryIconActive } from 'assets/dfo/icon--order-summary--active.svg'
import { ReactComponent as OrderSummaryIconDisabled } from 'assets/dfo/icon--order-summary--disabled.svg'
import { ReactComponent as CheckoutIcon } from 'assets/dfo/icon--checkout.svg'
import { ReactComponent as CheckoutIconActive } from 'assets/dfo/icon--checkout--active.svg'
import { ReactComponent as CheckoutIconDisabled } from 'assets/dfo/icon--checkout--disabled.svg'
import { ReactComponent as ShareOrderIconActive } from 'assets/dfo/icon--share-order--active.svg'
import { ReactComponent as ShareOrderIconDisabled } from 'assets/dfo/icon--share-order--disabled.svg'
import {
  STORES_PATH,
  NEW_BACKUP_MEAL_PATH,
  NEW_MEAL_BUDGET_PATH,
  NEW_DELIVERY_DETAILS_PATH,
  NEW_ORDER_SUMMARY_PATH,
  NEW_CHECKOUT_PATH,
  SHARE_ORDER_PATH
} from 'routes'

import {
  LOCATION_CHANGE_ALERT,
  RESTAURANT_CHANGE_ALERT,
  BUDGET_CHANGE_ALERT,
  BACKUP_MEAL_CHANGE_ALERT
} from 'components/newOrderWorkflow/navAlerts/constants'

export const LOCATION_ORDER_STEP = 'location'
export const RESTAURANT_ORDER_STEP = 'restaurant'
export const BACKUP_MEAL_ORDER_STEP = 'backup-meal'
export const MEAL_BUDGET_ORDER_STEP = 'meal-budget'
export const DELIVERY_DETAILS_ORDER_STEP = 'delivery-details'
export const ORDER_SUMMARY_ORDER_STEP = 'order-summary'
export const CHECKOUT_ORDER_STEP = 'checkout'
export const SHARE_ORDER_STEP = 'share-order'

export const ORDER_STEPS = [
  {
    name: LOCATION_ORDER_STEP,
    label: 'Location',
    path: '/',
    icon: LocationIcon,
    iconActive: LocationIconActive,
    iconDisabled: LocationIconDisabled,
    navAlertName: LOCATION_CHANGE_ALERT
  },
  {
    name: RESTAURANT_ORDER_STEP,
    label: 'Restaurant',
    path: STORES_PATH,
    icon: RestaurantIcon,
    iconActive: RestaurantIconActive,
    iconDisabled: RestaurantIconDisabled,
    navAlertName: RESTAURANT_CHANGE_ALERT
  },
  {
    name: BACKUP_MEAL_ORDER_STEP,
    label: 'Backup Meal',
    path: NEW_BACKUP_MEAL_PATH,
    icon: BackupMealIcon,
    iconActive: BackupMealIconActive,
    iconDisabled: BackupMealIconDisabled,
    navAlertName: BACKUP_MEAL_CHANGE_ALERT
  },
  {
    name: MEAL_BUDGET_ORDER_STEP,
    label: 'Meal Budget',
    navLabel: 'SET MEAL BUDGET',
    path: NEW_MEAL_BUDGET_PATH,
    icon: MealBudgetIcon,
    iconActive: MealBudgetIconActive,
    iconDisabled: MealBudgetIconDisabled,
    navAlertName: BUDGET_CHANGE_ALERT
  },
  {
    name: DELIVERY_DETAILS_ORDER_STEP,
    label: 'Delivery Details',
    navLabel: 'ADD DELIVERY DETAILS',
    path: NEW_DELIVERY_DETAILS_PATH,
    icon: DeliveryDetailsIcon,
    iconActive: DeliveryDetailsIconActive,
    iconDisabled: DeliveryDetailsIconDisabled
  },
  {
    name: ORDER_SUMMARY_ORDER_STEP,
    label: 'Order Summary',
    navLabel: 'REVIEW ORDER',
    path: NEW_ORDER_SUMMARY_PATH,
    icon: OrderSummaryIcon,
    iconActive: OrderSummaryIconActive,
    iconDisabled: OrderSummaryIconDisabled
  },
  {
    name: CHECKOUT_ORDER_STEP,
    label: 'Checkout',
    navLabel: 'CONT. TO PAYMENT',
    path: NEW_CHECKOUT_PATH,
    icon: CheckoutIcon,
    iconActive: CheckoutIconActive,
    iconDisabled: CheckoutIconDisabled
  },
  {
    name: SHARE_ORDER_STEP,
    label: 'Share Order',
    navLabel: 'PLACE ORDER',
    path: SHARE_ORDER_PATH,
    iconActive: ShareOrderIconActive,
    iconDisabled: ShareOrderIconDisabled
  }
]
