import React from 'react'
import isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'

import { EditMealSection } from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import LineItem, { LineItemTotal } from 'components/lineItem/LineItem'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import { ReactComponent as SilverwareIcon } from 'assets/dfo/icon--silverware.svg'
import { sumMealItems } from 'utils/order'
import { fromCents } from 'utils/money'

const MealOrderSummary = ({
  storeName,
  meals,
  budget,
  hasBudget,
  remainingBalance,
  overbudget,
  onEditMealItem,
  onRemoveMealItem
}) => {
  return (
    <div className='meal-order-summary'>
      <div className='meal-order-summary__header'>
        <SilverwareIcon />
        <div className='meal-order-summary__title'>Your Meal Order</div>
      </div>

      {isEmpty(meals) ? (
        <div className='order-overview-section attendee-meal-instructions-section'>
          <div className='dfo-title'>{storeName}</div>
          <div className='attendee-meal-instructions-section__content'>
            Your menu selections will appear here.
          </div>
        </div>
      ) : (
        <EditMealSection
          storeName={storeName}
          meals={meals}
          onEditMealItem={onEditMealItem}
          onRemoveMealItem={onRemoveMealItem}
          noBudget={!hasBudget}
          showPrice
        />
      )}

      <div className='order-overview-section'>
        <LineItemTotal name='Your Total' price={sumMealItems(meals)} />

        {hasBudget && (
          <LineItem
            className={classnames('line-item__remaining-meal-budget', {
              'line-item__remaining-meal-budget--overbudget': overbudget
            })}
            name='Remaining meal budget:'
            price={fromCents(remainingBalance)}
          />
        )}

        {hasBudget && overbudget && (
          <AlertErrorMessage>
            <b>You’re over budget.</b> Please update your order to be{' '}
            <b>{fromCents(budget).format()}</b> or less.
          </AlertErrorMessage>
        )}
      </div>
    </div>
  )
}

export default MealOrderSummary
