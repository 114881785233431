import React from 'react'

import { ReactComponent as ColumnViewIconActive } from 'assets/dfo/icon--column-view--active.svg'
import { ReactComponent as RowViewIconActive } from 'assets/dfo/icon--row-view--active.svg'
import { ReactComponent as ColumnViewIconDisabled } from 'assets/dfo/icon--column-view--disabled.svg'
import { ReactComponent as RowViewIconDisabled } from 'assets/dfo/icon--row-view--disabled.svg'

import track, {
  CLICK_EVENT,
  CLICK_COLUMN_VIEW_RESTAURANT,
  CLICK_ROW_VIEW_RESTAURANT
} from 'services/tracking'

const ViewSelector = ({ toggleView, viewAsColumn }) => {
  const handleClick = (toggleToColumn, eventName) => {
    track({
      category: CLICK_EVENT,
      action: eventName
    })
    toggleView(toggleToColumn)
  }

  return (
    <div className='view-selector'>
      View by:
      <button
        id='view-selector-button'
        className='view-selector__button'
        onClick={() => handleClick(false, CLICK_ROW_VIEW_RESTAURANT)}
      >
        <div className='view-selector__icon'>
          {viewAsColumn ? <RowViewIconDisabled /> : <RowViewIconActive />}
        </div>
      </button>
      <div className='view-selector__divider' />
      <button
        id='view-selector-button'
        className='view-selector__button'
        onClick={() => handleClick(true, CLICK_COLUMN_VIEW_RESTAURANT)}
      >
        <div className='view-selector__icon'>
          {viewAsColumn ? <ColumnViewIconActive /> : <ColumnViewIconDisabled />}
        </div>
      </button>
    </div>
  )
}

export default ViewSelector
