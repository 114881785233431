import React from 'react'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Timer as TimerIcon } from '@material-ui/icons'
import { jwt, stopImpersonating } from '@foodsby/webapp-jwt'
import Timer from 'react-compound-timer'

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: 4
  },
  root: {
    background: '#EF3A24', // Foodsby-logo red
    color: '#FFFFFF'
  },
  title: {
    flexGrow: 1
  },
  offset: theme.mixins.toolbar
}))

export default function ImpersonationHeader () {
  const classes = useStyles()

  const accessToken = jwt.getDecodedAccessToken()
  const expiry = accessToken.exp
  const now = (Date.now() / 1000) | 0
  const diff = expiry - now

  return (
    <div className={classes.container}>
      <AppBar className={classes.root} position='fixed'>
        <Toolbar>
          <Typography className={classes.title} variant='body1'>
            <i>Impasta!</i>{' '}
            <span aria-label='spaghetti' role='img'>
              🍝
            </span>
            {` You are currently logged in as ${
              accessToken.user_name
            } (User ID: ${accessToken.user_id})`}
          </Typography>
          <TimerIcon />
          <Timer
            checkpoints={[{ time: 0, callback: stopImpersonating }]}
            direction='backward'
            formatValue={value => (value < 10 ? `0${value}` : value)}
            initialTime={diff * 1000}
          >
            {() => (
              <>
                <Timer.Minutes />:<Timer.Seconds />
              </>
            )}
          </Timer>
          <Button color='inherit' onClick={stopImpersonating}>
            Exit
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.offset} />
    </div>
  )
}
