import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import AbandonOrderPrompt from 'components/newOrderWorkflow/shared/AbandonOrderPrompt'
import OrderSummaryMain from './orderSummaryMain/OrderSummaryMain'
import OrderSummarySidebar from './orderSummarySidebar/OrderSummarySidebar'
import {
  selectNewGroupOrder,
  newGroupOrder,
  selectCurrentUser
} from 'redux/selectors'
import {
  recalculateTaxes,
  setDeliveryOptionsFormOpen
} from 'redux/modules/newGroupOrder'
import { getStores } from 'redux/modules/store'
import { routeToHomeIfNoData } from 'higherOrderComponents'
import { ORDER_SUMMARY_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'
import { NEW_CHECKOUT_PATH } from 'routes'

let OrderSummaryPage = props => {
  useEffect(() => {
    const fetchData = async () => {
      await props.getStores(props.locationId)
      await props.recalculateTaxes()
    }
    fetchData()
  }, [props.locationId])

  useEffect(() => {
    props.recalculateTaxes()
  }, [props.newGroupOrder.attendeesCount])

  const onSubmit = () => props.history.push(NEW_CHECKOUT_PATH)

  return (
    <DfoLayout currentUser={props.currentUser}>
      <OrderWorkflowLayout
        headerProps={{
          stepName: ORDER_SUMMARY_ORDER_STEP,
          onClickFutureStep: (step, isComplete) => {
            if (isComplete) {
              props.history.push(step.path)
            }
          },
          onClickNextStep: step => props.history.push(step.path),
          showNextStepLabel: true
        }}
        mainComponent={<OrderSummaryMain {...props} onSubmit={onSubmit} />}
        sidebarComponent={
          <OrderSummarySidebar {...props} onSubmit={onSubmit} />
        }
        inlineMobileSidebar
      />

      <AbandonOrderPrompt />
    </DfoLayout>
  )
}

OrderSummaryPage = withRouter(OrderSummaryPage)

OrderSummaryPage = routeToHomeIfNoData(OrderSummaryPage)

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  newGroupOrder: selectNewGroupOrder(state),
  locationId: newGroupOrder.selectLocationId(state)
})

const mapDispatchToProps = {
  getStores,
  recalculateTaxes,
  setDeliveryOptionsFormOpen
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryPage)
