import React, { useState } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { Button } from '@foodsby/nutrient'

import { ReactComponent as DeliveryIcon } from 'assets/splash-delivery.svg'
import { ReactComponent as SoupIcon } from 'assets/splash-soup.svg'
import { ReactComponent as VeganIcon } from 'assets/splash-vegan.svg'
import { ReactComponent as CalendarIcon } from 'assets/splash-calendar.svg'
import SplashLayout from 'components/splashLayout/SplashLayout'
import LocationSearchWithButton from 'components/locationSearch/LocationSearchWithButton'
import { setLocationFromAddress } from 'redux/modules/location'
import { selectCurrentUser } from 'redux/selectors'
import { STORES_PATH, STORES_FOR_LOCATION_PATH, AW_SHUCKS_PATH } from 'routes'

const SplashPage = ({ currentUser, history, setLocationFromAddress }) => {
  const [dontShow, setDontShow] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const skipSplash = window.localStorage.getItem('goSkipSplash')

  if (currentUser && currentUser.userId && skipSplash === 'true') {
    history.replace(STORES_PATH)
  }

  const setSkipSplash = () =>
    window.localStorage.setItem('goSkipSplash', dontShow)

  const onClickView = () => {
    setSkipSplash()
    dontShow ? history.replace(STORES_PATH) : history.push(STORES_PATH)
  }

  const onSelectLocation = async (address, placeId) => {
    setSubmitting(true)
    const locationId = await setLocationFromAddress(address, placeId)
    const route = locationId
      ? formatRoute(STORES_FOR_LOCATION_PATH, { locationId })
      : AW_SHUCKS_PATH
    history.push(route)
  }

  const DontShow = () => (
    <div className='splash-page__dont-show'>
      <div>
        <label>
          <input
            type='checkbox'
            checked={dontShow}
            onChange={() => setDontShow(!dontShow)}
          />
          Don't show this page again
        </label>
      </div>
    </div>
  )

  const ViewRestaurants = () => (
    <div>
      <div className='splash-page__view-restaurants'>
        <Button onClick={() => onClickView()}>
          View Restaurants
          <span className='splash-page__view-restaurants-chevron'>›</span>
        </Button>
      </div>
      <DontShow />
    </div>
  )

  return (
    <SplashLayout
      currentUser={currentUser}
      className='splash-page'
      headerProps={{ fixedHeader: true }}
    >
      <div className='splash-page__get-started'>
        <h1 className='splash-page__foodsby-meetings-header'>
          Lunch for the whole team,
          <br />
          right when you need it.
        </h1>
        <div className='splash-page__schedule-in-advance'>
          Schedule in advance, let attendees choose their own meals, and get
          everything delivered right to your office.
        </div>
        {currentUser && currentUser.userId ? (
          <ViewRestaurants />
        ) : (
          <div className='splash-page__search'>
            <LocationSearchWithButton
              onSelect={onSelectLocation}
              submitting={submitting}
            />
          </div>
        )}
      </div>
      <div className='splash-page__loving-foodsby-meetings'>
        <div className='splash-page__loving-container'>
          <div className='splash-page__loving-header'>
            Why meeting organizers are loving Foodsby Meetings
          </div>
          <div className='splash-page__loving-descriptions'>
            <div className='splash-page__loving-description splash-page__soup-description'>
              <div className='splash-page__loving-icon'>
                <SoupIcon />
              </div>
              <div className='splash-page__loving-description-header'>
                More Restaurants
              </div>
              <div className='splash-page__loving-description-text'>
                Order from any restaurant available in your area
              </div>
            </div>
            <div className='splash-page__loving-description splash-page__calendar-description'>
              <div className='splash-page__loving-icon'>
                <CalendarIcon />
              </div>
              <div className='splash-page__loving-description-header'>
                Scheduled Deliveries
              </div>
              <div className='splash-page__loving-description-text'>
                Set a specific date and time in advance
              </div>
            </div>
            <div className='splash-page__loving-description splash-page__vegan-description'>
              <div className='splash-page__loving-icon'>
                <VeganIcon />
              </div>
              <div className='splash-page__loving-description-header'>
                Attendee's Choice
              </div>
              <div className='splash-page__loving-description-text'>
                Meeting attendees can choose their own orders
              </div>
            </div>
            <div className='splash-page__loving-description splash-page__delivery-description'>
              <div className='splash-page__loving-icon'>
                <DeliveryIcon />
              </div>
              <div className='splash-page__loving-description-header'>
                Efficient Ordering
              </div>
              <div className='splash-page__loving-description-text'>
                Easier for meeting organizers
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='splash-page__how-it-works'>
        <h1>How it works</h1>
        <div className='splash-page__how-it-works-instructions'>
          <ol>
            <li>Pick the date and time for your delivery.</li>
            <li>Choose your restaurant and set your budget for the team.</li>
            <li>Share and let attendees pick what they want to eat.</li>
          </ol>
        </div>
      </div>
      <div className='splash-page__view-restaurants-footer'>
        {currentUser && currentUser.userId ? (
          <ViewRestaurants />
        ) : (
          <div className='splash-page__search'>
            <LocationSearchWithButton
              onSelect={onSelectLocation}
              submitting={submitting}
              autoFocus={false}
            />
          </div>
        )}
      </div>
    </SplashLayout>
  )
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
})

const mapDispatchToProps = {
  setLocationFromAddress
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplashPage)
