import React from 'react'

import MenuQuestion from './menuQuestion/MenuQuestion'
import AnswerField from './AnswerField'
import { isAnswerSelected } from './utils'

const Question = ({ question, values, errors }) => {
  if (question.depth > 1 && !isAnswerSelected(values, question.parents)) {
    return null
  }
  const errorsMessages =
    errors[question.formId] && errors[question.formId].inline

  return (
    <MenuQuestion question={question} errors={errorsMessages}>
      <ul className='segment-items'>
        {question.answers.map(answer => (
          <AnswerField key={answer.id} answer={answer} question={question} />
        ))}
      </ul>
    </MenuQuestion>
  )
}

const MenuQuestions = ({ questions, values, errors }) => (
  <>
    {questions.map(question => (
      <Question
        key={question.formId}
        question={question}
        values={values}
        errors={errors}
      />
    ))}
  </>
)

export default MenuQuestions
