import React from 'react'
import { connect } from 'react-redux'
import NavigationPrompt from 'react-router-navigation-prompt'

import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import WarningModal from 'components/newOrderWorkflow/shared/warningModal/WarningModal'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'

import { newGroupOrder, selectNewGroupOrder } from 'redux/selectors'
import { clearGroupOrder } from 'redux/modules/newGroupOrder'
import {
  STORES_PATH,
  NEW_BACKUP_MEAL_PATH,
  NEW_ORDER_SUMMARY_PATH,
  NEW_DELIVERY_DETAILS_PATH,
  NEW_MEAL_BUDGET_PATH,
  NEW_CHECKOUT_PATH,
  SHARE_ORDER_PATH,
  PAGE_NOT_FOUND,
  ERROR_PATH
} from 'routes'

const noPromptRoutes = [
  STORES_PATH,
  NEW_BACKUP_MEAL_PATH,
  NEW_ORDER_SUMMARY_PATH,
  NEW_DELIVERY_DETAILS_PATH,
  NEW_MEAL_BUDGET_PATH,
  NEW_CHECKOUT_PATH,
  SHARE_ORDER_PATH,
  PAGE_NOT_FOUND,
  ERROR_PATH
]

const AbandonOrderPrompt = ({ clearGroupOrder, orderStoreId }) => {
  const shouldConfirmNavigation = (currentLocation, nextLocation) => {
    const isRoutingInternally =
      nextLocation &&
      !noPromptRoutes.some(path => nextLocation.pathname.startsWith(path))
    const isRoutingExternally = nextLocation === undefined
    // If a store has been selected, the order is considered "in progress"
    const orderInProgress = !!orderStoreId
    return orderInProgress && (isRoutingInternally || isRoutingExternally)
  }

  return (
    <NavigationPrompt when={shouldConfirmNavigation}>
      {({ onConfirm, onCancel }) => (
        <ConfirmNavigationModal
          onCancel={onCancel}
          onSubmit={props => {
            clearGroupOrder()
            onConfirm(props)
          }}
        />
      )}
    </NavigationPrompt>
  )
}

const ConfirmNavigationModal = ({ onCancel, onSubmit }) => {
  const title = "Your order isn't finished!"
  const message =
    'If you leave this page, your order will be lost. Do you want to continue with your order?'
  const buttons = [
    <RoundButton onClick={onSubmit} className='borderless'>
      Leave Page
    </RoundButton>,
    <RoundButton onClick={onCancel}>Continue Order</RoundButton>
  ]

  return (
    <WarningModal
      onCancel={onCancel}
      showCancel
      title={title}
      message={message}
      buttons={buttons}
      icon={<AlertErrorIcon />}
      maxWidth='500px'
    />
  )
}

const mapStateToProps = state => ({
  newGroupOrder: selectNewGroupOrder(state),
  orderStoreId: newGroupOrder.selectStoreId(state)
})

const mapDispatchToProps = {
  clearGroupOrder
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AbandonOrderPrompt)
