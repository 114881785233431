import React from 'react'

import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import { ReactComponent as AlertWarningIcon } from 'assets/dfo/icon--alert-warning.svg'
import { ReactComponent as AlertSuccessIcon } from 'assets/dfo/icon--green-check.svg'

const AlertErrorMessage = ({ children }) => (
  <div className='alert-message alert-message--error'>
    <AlertErrorIcon />
    <div className='alert-message__content'>{children}</div>
  </div>
)

const AlertWarningMessage = ({ children }) => (
  <div className='alert-message alert-message--warning'>
    <AlertWarningIcon />
    <div className='alert-message__content'>{children}</div>
  </div>
)

const AlertSuccessMessage = ({ children }) => (
  <div className='alert-message alert-message--success'>
    <AlertSuccessIcon />
    <div className='alert-message__content'>{children}</div>
  </div>
)

export { AlertErrorMessage, AlertWarningMessage, AlertSuccessMessage }
