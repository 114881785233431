import React from 'react'
import filter from 'lodash/filter'
import sample from 'lodash/sample'

import { ReactComponent as CornIcon } from 'assets/icon--corn.svg'
import { ReactComponent as NutsIcon } from 'assets/icon--nuts.svg'
import { ReactComponent as FudgeIcon } from 'assets/icon--fudge.svg'
import { ReactComponent as ShiitakeIcon } from 'assets/icon--shiitake.svg'

export const messageContents = [
  {
    component: CornIcon,
    text: 'Aw Shucks.'
  },
  {
    component: NutsIcon,
    text: 'Nuts.'
  },
  {
    component: FudgeIcon,
    text: 'Oh Fudge.'
  },
  {
    component: ShiitakeIcon,
    text: 'Shiitake!'
  }
]

export const randomMessage = (currentMessage, messages = messageContents) =>
  sample(filter(messages, m => m !== currentMessage))

const AwShucks = ({ message }) => {
  const ShucksIcon = message.component

  return (
    <div className='aw-shucks'>
      <div className='aw-shucks__icon'>
        <ShucksIcon />
      </div>

      <div className='aw-shucks__header'>{message.text}</div>

      <div className='aw-shucks__no-restaurants-available'>
        There are currently no restaurants available in your area.
      </div>

      <div className='aw-shucks__our-network-is-growing'>
        Our network is growing fast, so check again in the future!
      </div>
    </div>
  )
}

export default AwShucks
