import React from 'react'
import classnames from 'classnames'

export default function MessageBar ({ type, center = false, children }) {
  return (
    <div
      className={classnames('message-bar', {
        'message-bar--warning': type === 'warning',
        'message-bar--center': center
      })}
    >
      <div className='message-bar__container'>{children}</div>
    </div>
  )
}
