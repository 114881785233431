import React from 'react'
import classnames from 'classnames'

import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import OrderMealItem from 'components/newOrderWorkflow/shared/orderMealItem/OrderMealItem'
import DateTimeFormatted from 'components/newOrderWorkflow/shared/dateTimeFormatted/DateTimeFormatted'
import MealBudgetFormatted from 'components/newOrderWorkflow/shared/mealBudgetFormatted/MealBudgetFormatted'
import DeliveryDetailsFormatted from 'components/newOrderWorkflow/shared/deliveryDetailsFormatted/DeliveryDetailsFormatted'
import { ReactComponent as AttendeesIcon } from 'assets/dfo/icon--attendees.svg'
import { fromCents, fromCentsToCeil } from 'utils/money'
import { formatAddress } from 'utils/formatters'

const OrderContactSection = ({
  suiteNumber,
  contactName,
  contactPhoneNumber,
  contactPhoneExtension,
  contactEmail,
  dropoffInstructions,
  onClickAction
}) => (
  <OrderOverviewSection
    className='order-contact-section'
    name='Delivery Details'
    onClickAction={onClickAction}
  >
    <DeliveryDetailsFormatted
      suiteNumber={suiteNumber}
      contactName={contactName}
      contactPhoneNumber={contactPhoneNumber}
      contactPhoneExtension={contactPhoneExtension}
      contactEmail={contactEmail}
      dropoffInstructions={dropoffInstructions}
    />
  </OrderOverviewSection>
)

const DeliverSection = ({ dropoff, attendeesCount, onClickAction }) => (
  <OrderOverviewSection
    className='deliver-section'
    name='Deliver on'
    onClickAction={onClickAction}
  >
    <DateTimeFormatted date={dropoff} />

    <div className='deliver-section__attendees'>
      <AttendeesIcon className='deliver-section__attendees-icon' />
      for&nbsp;
      <span
        className={classnames('deliver-section__attendees-count', {
          'deliver-section__attendees-count--placeholder': !attendeesCount
        })}
      >
        {attendeesCount ? attendeesCount : 10}
      </span>
      &nbsp;people
    </div>
  </OrderOverviewSection>
)

const DeliveryDetailsSection = ({
  dropoffLocation,
  dropoff,
  attendeesCount,
  onClickAction
}) => (
  <OrderOverviewSection
    className='delivery-details-section'
    name='Deliver'
    onClickAction={onClickAction}
  >
    <div className='delivery-details-section__location-info'>
      <span className='delivery-details-section__location'>
        {formatAddress(dropoffLocation)}
      </span>
    </div>

    <div className='delivery-details-section__dropoff-and-attendees'>
      <DateTimeFormatted date={dropoff} />

      <span className='delivery-details-section__attendees'>
        &nbsp;for&nbsp;
        <span className='delivery-details-section__attendees-count'>
          {attendeesCount}
        </span>
        &nbsp;people
      </span>
    </div>
  </OrderOverviewSection>
)

const BackupMealSection = ({ storeName, meals, onClickAction }) => (
  <OrderOverviewSection
    className='backup-meal-section'
    name='Backup Meal'
    onClickAction={onClickAction}
  >
    <div className='dfo-title'>{storeName}</div>
    <div className='backup-meal-section__meal-item-list'>
      {meals.map((mealItem, index) => (
        <OrderMealItem key={index} mealItem={mealItem} />
      ))}
    </div>
  </OrderOverviewSection>
)

const EditMealSection = ({
  storeName,
  meals,
  onEditMealItem,
  onRemoveMealItem,
  showPrice,
  name,
  mealTotalCost,
  noBudget
}) => (
  <OrderOverviewSection className='edit-meal-section' name={name}>
    <div className='edit-meal-section__store-name'>{storeName}</div>

    <div className='edit-meal-section__meal-item-list'>
      {meals.map((mealItem, index) => (
        <OrderMealItem
          key={index}
          mealItem={mealItem}
          onEditMealItem={() => onEditMealItem(index)}
          onRemoveMealItem={() => onRemoveMealItem(index)}
          showPrice={showPrice}
        />
      ))}
    </div>

    {mealTotalCost && (
      <div className='edit-meal-section__pricing'>
        <div className='edit-meal-section__total'>
          <span>Backup Meal Total</span> {fromCents(mealTotalCost).format()}
        </div>
        {!noBudget && (
          <div className='edit-meal-section__recommended-budget'>
            <span>Recommended Budget</span>&nbsp;
            {fromCentsToCeil(mealTotalCost).format()}
          </div>
        )}
      </div>
    )}
  </OrderOverviewSection>
)

const MealBudgetSection = ({
  budgetPerAttendeeInCents,
  onClickAction,
  noBudget
}) => (
  <OrderOverviewSection
    className='meal-budget-section'
    name='Meal Budget'
    onClickAction={onClickAction}
  >
    <MealBudgetFormatted
      budgetPerAttendeeInCents={budgetPerAttendeeInCents}
      noBudget={noBudget}
    />
  </OrderOverviewSection>
)

const OrderOverview = ({ children }) => (
  <div className='order-overview'>{children}</div>
)

const OrderOverviewSection = ({
  className,
  name,
  actionLabel = 'Edit',
  onClickAction,
  children
}) => (
  <div className={classnames('order-overview-section', className)}>
    <div className='order-overview-section__header'>
      <div className='order-overview-section__name'>{name}</div>
      {onClickAction && (
        <DfoAction onClick={onClickAction}>{actionLabel}</DfoAction>
      )}
    </div>
    {children}
  </div>
)

export {
  OrderContactSection,
  DeliveryDetailsSection,
  DeliverSection,
  BackupMealSection,
  EditMealSection,
  MealBudgetSection
}

export default OrderOverview
