import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'
import findIndex from 'lodash/findIndex'
import times from 'lodash/times'
import noop from 'lodash/noop'
import get from 'lodash/get'

import { ReactComponent as NextStepIcon } from 'assets/dfo/icon--chevron.svg'
import OrderWorkflowButton from 'components/newOrderWorkflow/orderWorkflowButton/OrderWorkflowButton'
import AlertLoader from 'components/newOrderWorkflow/navAlerts/components/AlertLoader'
import DeliveryOptionsNav from 'components/newOrderWorkflow/deliveryOptionsNav/DeliveryOptionsNav'
import {
  ORDER_STEPS,
  SHARE_ORDER_STEP,
  LOCATION_ORDER_STEP,
  RESTAURANT_ORDER_STEP
} from 'components/newOrderWorkflow/shared/orderSteps'
import {
  newGroupOrder,
  selectNavAlert,
  selectNewGroupOrder,
  selectMeal,
  selectDeliveryOptionsFormOpen,
  selectLocation
} from 'redux/selectors'
import {
  setGroupOrder,
  setCurrentOrderStep,
  setDeliveryOptionsFormOpen
} from 'redux/modules/newGroupOrder'
import { getLocation } from 'redux/modules/location'
import { setNavAlert } from 'redux/modules/alerts'
import { isTimeSet } from 'utils/datetime'

const TRANSITION_TIME = 300

const Dots = ({ numStepsRemaining, onClick }) => (
  <div className='order-workflow-header__dots'>
    {times(5, index => {
      const size = 32 - (index + 1) * 4
      const showDot = numStepsRemaining - index - 1 > 0
      return (
        <svg
          onClick={onClick}
          key={index}
          style={{
            visibility: showDot ? 'visible' : 'hidden',
            cursor: 'pointer'
          }}
          width={size}
          height={size}
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            cx='187'
            cy='10'
            r='10'
            transform='translate(-177)'
            fillRule='evenodd'
          />
        </svg>
      )
    })}
  </div>
)

const OrderWorkflowHeaderExpanded = ({
  key,
  stepIndex,
  furthestStepIndex,
  onClickPastStep,
  onClickFutureStep,
  closeExpanded,
  toggleDeliveryOptions
}) => {
  const [blurrable, setBlurrable] = useState(true)

  const containerRef = useRef()

  useLayoutEffect(() => containerRef.current.focus(), [])

  const currentStep = ORDER_STEPS[stepIndex]

  const icons = ORDER_STEPS.map((step, index) => {
    const active = index === stepIndex
    const futureStep = index > stepIndex
    const completedFutureStep = futureStep && index <= furthestStepIndex
    const shareOrderStep = currentStep.name === SHARE_ORDER_STEP && !active
    const disabled = (futureStep && !completedFutureStep) || shareOrderStep

    return (
      <OrderWorkflowButton
        key={index}
        step={step}
        iconActive={active}
        iconDisabled={disabled}
        onMouseDown={() => setBlurrable(false)}
        onMouseUp={() => setBlurrable(true)}
        onClick={() => {
          if (active || shareOrderStep) {
            return
          }

          if (step.name === LOCATION_ORDER_STEP) {
            toggleDeliveryOptions(true)
          } else if (futureStep) {
            onClickFutureStep(step, completedFutureStep)
          } else {
            closeExpanded()
            setTimeout(() => onClickPastStep(step), TRANSITION_TIME)
          }
        }}
      />
    )
  })

  return (
    <div
      key={key}
      className='order-workflow-header-expanded'
      ref={containerRef}
      tabIndex={1}
      onBlur={blurrable ? closeExpanded : noop}
    >
      {icons}
    </div>
  )
}

const OrderWorkflowHeaderCollapsed = ({
  stepIndex,
  furthestStepIndex,
  isDesktop,
  showNextStepLabel,
  onClickExpand,
  onClickPastStep,
  onClickFutureStep,
  onClickNextStep,
  toggleDeliveryOptions
}) => {
  const currentStep = ORDER_STEPS[stepIndex]
  const numStepsRemaining = ORDER_STEPS.length - furthestStepIndex - 1
  const nextStep = ORDER_STEPS[stepIndex + 1]
  const visibleSteps = ORDER_STEPS.slice(0, furthestStepIndex + 1)
  const furthestNextStep = ORDER_STEPS[furthestStepIndex + 1]

  const icons = visibleSteps.map((step, index) => {
    const active = index === stepIndex
    const futureStep = index > stepIndex
    const completedFutureStep = futureStep && index <= furthestStepIndex
    const shareOrderStep = currentStep.name === SHARE_ORDER_STEP && !active

    return (
      <OrderWorkflowButton
        key={index}
        step={step}
        iconActive={active}
        iconDisabled={shareOrderStep}
        label={currentStep.label}
        showLabel={isDesktop || !showNextStepLabel}
        onClick={() => {
          if (active || shareOrderStep) {
            return
          }

          if (step.name === LOCATION_ORDER_STEP) {
            toggleDeliveryOptions(true)
          } else if (futureStep) {
            onClickFutureStep(step, completedFutureStep)
          } else {
            onClickPastStep(step)
          }
        }}
      />
    )
  })

  const nextStepButton =
    furthestNextStep && furthestStepIndex < ORDER_STEPS.length ? (
      <OrderWorkflowButton
        step={furthestNextStep}
        iconDisabled
        disabled={furthestNextStep.name === 'share-order'}
        onClick={() => onClickNextStep(nextStep)}
      />
    ) : null

  return (
    <>
      <div className='order-workflow-header__left'>
        <div className='order-workflow-header__steps'>
          <div
            className='order-workflow-header__expand-steps-overlay'
            onClick={onClickExpand}
          />
          {icons}
        </div>
      </div>

      {numStepsRemaining > 0 && (
        <div className='order-workflow-header__right'>
          {nextStepButton}
          <Dots
            numStepsRemaining={numStepsRemaining}
            onClick={() => onClickNextStep(nextStep)}
          />

          <div className='order-workflow-header__next-step'>
            {showNextStepLabel && nextStep.navLabel && (
              <button
                type='button'
                onClick={() => onClickNextStep(nextStep)}
                className='order-workflow-header__next-step-label-button'
              >
                <div className='order-workflow-header__next-step-label'>
                  {nextStep.navLabel}
                </div>
              </button>
            )}
            <button
              type='button'
              className='order-workflow-header__next-step-button'
              onClick={() => onClickNextStep(nextStep)}
            >
              <NextStepIcon />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

const OrderWorkflowHeader = ({
  stepName,
  onClickNextStep = noop,
  onClickFutureStep = noop,
  setCurrentOrderStep,
  setGroupOrder,
  showNextStepLabel,
  furthestStep,
  navAlert,
  setNavAlert,
  newGroupOrder,
  meal,
  location,
  getLocation,
  deliveryOptionsFormOpen,
  setDeliveryOptionsFormOpen,
  history,
  match
}) => {
  const [expanded, setExpanded] = useState(false)
  const [showCollapsed, setShowCollapsed] = useState(true)

  useEffect(() => {
    const locationId = get(match, 'params.locationId')
    if (!location.id || locationId !== location.id) {
      // even if locationId is undefined, we still want to fetch it... redux logic deals with getting right location
      getLocation(locationId)
    }
  }, [match.params.locationId, location])

  useEffect(() => {
    const isDropoffDateSet = newGroupOrder.dropoff
    const isDropoffTimeSet =
      isDropoffDateSet && isTimeSet(newGroupOrder.dropoff)
    const isAttendeeCountSet =
      newGroupOrder.attendeesCount && newGroupOrder.attendeesCount > 0
    const allDeliveryOptionsSet =
      isDropoffDateSet && isDropoffTimeSet && isAttendeeCountSet
    const isShareOrderPage = stepName === SHARE_ORDER_STEP

    setDeliveryOptionsFormOpen(!allDeliveryOptionsSet && !isShareOrderPage)
  }, [stepName])

  const isDesktop = useMediaQuery({ query: '(min-width: 769px)' })

  if (isDesktop && expanded) {
    setExpanded(false)
  }

  const stepIndex = findIndex(ORDER_STEPS, ['name', stepName])
  const furthestStepIndex = findIndex(ORDER_STEPS, ['name', furthestStep])

  useEffect(() => {
    if (
      furthestStep === SHARE_ORDER_STEP &&
      stepName === RESTAURANT_ORDER_STEP
    ) {
      setCurrentOrderStep(stepName)
      return
    }

    if (stepIndex > furthestStepIndex && !deliveryOptionsFormOpen) {
      setCurrentOrderStep(stepName)
    }

    if (stepIndex < furthestStepIndex) {
      setNavAlert({ name: ORDER_STEPS[stepIndex].navAlertName })
    } else {
      setNavAlert(null)
    }
  }, [stepName, deliveryOptionsFormOpen])

  useEffect(() => {
    // reset current step to restaurant step if all meals are removed
    if (meal.length === 0 && stepName === RESTAURANT_ORDER_STEP) {
      setGroupOrder({ storeId: null })
      setCurrentOrderStep(RESTAURANT_ORDER_STEP)
      setNavAlert(null)
    }
  }, [meal, stepName])

  useEffect(() => {
    if (
      !newGroupOrder.locationId &&
      ![RESTAURANT_ORDER_STEP, SHARE_ORDER_STEP].includes(stepName)
    ) {
      history.push('/restaurants')
    }
  }, [newGroupOrder.locationId, stepName])

  const closeExpanded = () => {
    setExpanded(false)
    setTimeout(() => setShowCollapsed(true), TRANSITION_TIME)
  }

  const onClickPastStep = step => history.push(step.path)

  if (deliveryOptionsFormOpen) {
    return (
      <DeliveryOptionsNav
        onClose={() => {
          setDeliveryOptionsFormOpen(false)
          setNavAlert(null)
        }}
        navAlertName={navAlert.name}
        navAlertMessageOverride={navAlert.messageOverride}
      />
    )
  }

  return (
    <header
      className={classnames('order-workflow-header sticky', {
        'order-workflow-header--extended': !!navAlert.name
      })}
    >
      <div className='order-workflow-header__wrapper'>
        <div className='order-workflow-header__body'>
          {!(expanded && !isDesktop) && showCollapsed && (
            <OrderWorkflowHeaderCollapsed
              stepIndex={stepIndex}
              furthestStepIndex={furthestStepIndex}
              onClickExpand={() => {
                setExpanded(true)
                setShowCollapsed(false)
              }}
              onClickPastStep={onClickPastStep}
              onClickFutureStep={onClickFutureStep}
              onClickNextStep={onClickNextStep}
              showNextStepLabel={showNextStepLabel}
              isDesktop={isDesktop}
              toggleDeliveryOptions={setDeliveryOptionsFormOpen}
            />
          )}

          <ReactCSSTransitionGroup
            transitionName='steps'
            transitionEnterTimeout={TRANSITION_TIME}
            transitionLeaveTimeout={TRANSITION_TIME}
            className={classnames('step-animator', {
              active: expanded && !isDesktop
            })}
          >
            {expanded && !isDesktop && (
              <OrderWorkflowHeaderExpanded
                key='order-workflow'
                stepIndex={stepIndex}
                furthestStepIndex={furthestStepIndex}
                onClickPastStep={onClickPastStep}
                onClickFutureStep={onClickFutureStep}
                closeExpanded={closeExpanded}
                toggleDeliveryOptions={setDeliveryOptionsFormOpen}
              />
            )}
          </ReactCSSTransitionGroup>
        </div>
      </div>

      {!!navAlert.name && (
        <AlertLoader
          alertName={navAlert.name}
          messageOverride={navAlert.messageOverride}
        />
      )}
    </header>
  )
}

const mapStateToProps = state => ({
  furthestStep: newGroupOrder.selectCurrentStep(state),
  deliveryOptionsFormOpen: selectDeliveryOptionsFormOpen(state),
  navAlert: selectNavAlert(state),
  newGroupOrder: selectNewGroupOrder(state),
  meal: selectMeal(state),
  location: selectLocation(state)
})

const mapDispatchToProps = {
  setCurrentOrderStep,
  setNavAlert,
  setGroupOrder,
  getLocation,
  setDeliveryOptionsFormOpen
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderWorkflowHeader))
