import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  fromDateTime,
  isTimeSet,
  toMidnight,
  getFirstValidDatetime,
  isDayDisabled
} from 'utils/datetime'

import {
  isBefore,
  isAfter,
  addDays,
  isValid,
  isWeekend,
  setHours,
  format,
  endOfDay
} from 'date-fns'
import classnames from 'classnames'

import { Field, connect as connectFormik } from 'formik'

import { selectConfig, selectNewGroupOrder } from 'redux/selectors'
import { updateGroupOrder } from 'redux/modules/newGroupOrder'

import DatePicker from 'components/newOrderWorkflow/shared/datePicker/DatePicker'
import DropdownWrapper from 'components/newOrderWorkflow/deliveryOptionsNav/DropdownWrapper'

import { ReactComponent as CalendarActiveIcon } from 'assets/dfo/icon--calendar.svg'
import { ReactComponent as CalendarInactiveIcon } from 'assets/dfo/icon--calendar-outline.svg'

function DateSearch ({
  formik,
  updateGroupOrder,
  currentDropoff,
  deliveryHourOffset,
  validate,
  setValue,
  trackChange
}) {
  const setDateTouched = () => formik.setFieldTouched('date', true)
  const firstValidDateTime = getFirstValidDatetime(deliveryHourOffset)

  useEffect(() => {
    setValue(
      'date',
      currentDropoff ? format(new Date(currentDropoff), 'MM/dd/yy') : ''
    )
  }, [currentDropoff])

  const onDateSelected = changedDate => {
    onChangeDate(
      changedDate,
      isValid(currentDropoff) && isAfter(changedDate, firstValidDateTime)
        ? currentDropoff
        : null
    )
    setTimeout(setDateTouched, 100)
    setIsActive(false)
  }

  const onChangeDate = (date, time) => {
    const dropoff = fromDateTime(
      date,
      isTimeSet(time) ? time : toMidnight(date)
    )

    updateGroupOrder({ dropoff })

    trackChange()
  }

  const changeToValidDate = (date, addDay) => {
    const resultDate = addDay ? addDays(date, 1) : addDays(date, -1)

    if (isBefore(endOfDay(resultDate), firstValidDateTime)) {
      return date
    }

    if (
      isDayDisabled(resultDate, firstValidDateTime) ||
      isWeekend(resultDate)
    ) {
      return changeToValidDate(resultDate, addDay)
    } else {
      return resultDate
    }
  }

  const handleKeyDown = e => {
    // if tab
    if (e.keyCode === 9) {
      setIsActive(false)
      return
    }

    let dropoff = new Date(currentDropoff ? currentDropoff : firstValidDateTime)
    //left or right key
    if ([37, 39].includes(e.keyCode)) {
      //add date if right key, subtract if left
      dropoff = changeToValidDate(dropoff, e.keyCode === 39)
      if (!currentDropoff) {
        dropoff = setHours(dropoff, 0)
      }
      updateGroupOrder({ dropoff: fromDateTime(dropoff, dropoff) })
      trackChange()
    }
  }

  const [isActive, setIsActive] = useState(false)

  const handleBlur = () => {
    setTimeout(() => {
      setDateTouched()
      setIsActive(false)
    }, 0)
  }

  return (
    <>
      <div
        tabIndex='0'
        className={classnames('delivery-options__date', {
          'delivery-options__date--active': isActive,
          'delivery-options__date--error':
            formik.touched['date'] && formik.errors['date']
        })}
        onFocus={() => setIsActive(true)}
        onKeyDown={handleKeyDown}
        onClick={e => e.stopPropagation()}
      >
        <label>on</label>
        <div className='date__dropdown-container'>
          <div className='date__current-value'>
            {!!currentDropoff ? (
              format(currentDropoff, 'MM/dd/yy')
            ) : (
              <span>mm/dd/yy</span>
            )}{' '}
          </div>
          {isActive ? <CalendarActiveIcon /> : <CalendarInactiveIcon />}
          {isActive && (
            <DropdownWrapper setIsOpen={handleBlur}>
              <div className='date__picker'>
                <DatePicker
                  className='date-dropdown__dfo'
                  selectedDate={
                    currentDropoff
                      ? new Date(currentDropoff)
                      : new Date(firstValidDateTime)
                  }
                  disabledDays={date => isDayDisabled(date, firstValidDateTime)}
                  onDateSelected={onDateSelected}
                  firstValidDateTime={new Date(firstValidDateTime)}
                />
              </div>
            </DropdownWrapper>
          )}
        </div>
      </div>
      <Field id='date' name='date' tabIndex='-1' validate={validate} />
    </>
  )
}

const mapStateToProps = state => ({
  deliveryHourOffset: selectConfig(state).orderDropoffOffsetInHours,
  currentDropoff: selectNewGroupOrder(state).dropoff
})

const mapDispatchToProps = {
  updateGroupOrder
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectFormik(DateSearch))
