import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

const StickySidebar = ({ children, className }) => {
  const defaultTop = 81
  const [top, setTop] = useState(defaultTop)

  const minTabletView = !!window.matchMedia('(min-width: 770px)').matches

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
  }, [])

  const [handleScroll] = useDebouncedCallback(() => {
    const sidebar = document.querySelector('.sticky-sidebar')

    if (sidebar && sidebar.scrollHeight >= window.innerHeight - defaultTop) {
      setTop(window.innerHeight - (sidebar.scrollHeight + defaultTop))
    } else {
      setTop(defaultTop)
    }
  }, 30)

  return (
    <div
      className={classnames(className, {
        'sticky sticky-sidebar': minTabletView
      })}
      style={{ top: top }}
    >
      {children}
    </div>
  )
}

export default StickySidebar
