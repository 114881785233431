import React from 'react'
import classNames from 'classnames'
import noop from 'lodash/noop'
import toInteger from 'lodash/toInteger'

import MoneyInput from 'components/form/MoneyInput'

const MoneyPicker = ({
  name,
  amountInCents,
  onChange,
  onBlur,
  onEnter = noop,
  error
}) => {
  const incrementAmount = () => {
    const newAmount = toInteger(amountInCents) + 100
    onChange(newAmount)
  }

  const decrementAmount = () => {
    let newAmount = toInteger(amountInCents) - 100

    if (newAmount < 0) {
      newAmount = 0
    }

    if (newAmount !== amountInCents) {
      onChange(newAmount)
    }
  }

  return (
    <div className='money-picker'>
      <button
        className='money-picker__decrement'
        type='button'
        onClick={decrementAmount}
      >
        –
      </button>
      <MoneyInput
        className={classNames('money-picker__input', {
          'money-picker__input--validation-error': !!error
        })}
        name={name}
        value={amountInCents / 100}
        onValueChange={({ value }) => onChange(value * 100)}
        isAllowed={({ floatValue }) =>
          floatValue === undefined || floatValue >= 0
        }
        onBlur={onBlur}
        onKeyPress={onEnter}
      />
      <button
        className='money-picker__increment'
        type='button'
        onClick={incrementAmount}
      >
        +
      </button>
    </div>
  )
}

export default MoneyPicker
