import React, { Component } from 'react'
import map from 'lodash/map'
import classnames from 'classnames'

import { fromCents } from 'utils/money'
import { formatDateTime } from 'utils/datetime'
import { getOrderStatus, displayMeal, isEditable } from 'utils/order'
import { ReactComponent as Pencil } from 'assets/icon--edit.svg'

const UpcomingOrderRow = ({ groupOrder, onClick, isAttendee }) => {
  const status = getOrderStatus(groupOrder)

  const className = classnames('order-list__upcoming-row', {
    clickable: !isAttendee || isEditable(groupOrder)
  })

  return (
    <tr className={className} onClick={onClick}>
      <td>{formatDateTime(groupOrder.dropoff)}</td>
      <td>{groupOrder.storeName}</td>
      {isAttendee ? (
        <td className='order-list__meal'>{displayMeal(groupOrder)}</td>
      ) : (
        <td className='order-list__attendees'>{groupOrder.attendeeCount}</td>
      )}
      <td className='order-list__lock-date'>
        {formatDateTime(groupOrder.cutoff)}
      </td>
      {!isAttendee && (
        <td className='order-list__money'>
          {fromCents(groupOrder.totalInCents).format()}
        </td>
      )}
      <td
        className={classnames('order-list__status', {
          'order-list__status--error': status.error
        })}
      >
        {status.label}
      </td>
      <td className='order-list__action'>
        {isEditable(groupOrder) && <Pencil className='pencil' alt='edit' />}
      </td>
    </tr>
  )
}

class UpcomingOrderList extends Component {
  render () {
    const { groupOrders, onClickRow, isAttendee } = this.props

    return (
      <div className='order-list'>
        <div className='order-list__title'>Upcoming</div>
        <div className='order-list__instructions'>
          All scheduled orders. If the meeting is not yet locked, you may make
          changes to your order.
        </div>

        <table className='order-list__table'>
          <thead>
            <tr>
              <th className='order-list__date'>Date</th>
              <th className='order-list__restaurant'>Restaurant</th>
              {isAttendee ? (
                <th className='order-list__meal'>Meal</th>
              ) : (
                <th className='order-list__attendees'>Attendees</th>
              )}
              <th className='order-list__lock-date'>Lock Date</th>
              {!isAttendee && <th className='order-list__money'>Cost</th>}
              <th className='order-list__status'>Status</th>
              <th className='order-list__action' />
            </tr>
          </thead>
          <tbody>
            {map(groupOrders, groupOrder => (
              <UpcomingOrderRow
                key={groupOrder.id}
                groupOrder={groupOrder}
                onClick={() => onClickRow(groupOrder)}
                isAttendee={isAttendee}
              />
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default UpcomingOrderList
