import React from 'react'
import { Form, Formik } from 'formik'
import { Modal } from '@foodsby/nutrient'

import DfoFormField from 'components/newOrderWorkflow/dfoFormField/DfoFormField'
import RoundTextInput from 'components/newOrderWorkflow/roundTextInput/RoundTextInput'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import {
  validateName,
  validateEmail,
  validateRequired,
  composeValidators
} from 'utils/validators'

const AttendeeNameForm = ({ isValid, isSubmitting, status }) => (
  <Form className='edit-attendee-modal__form'>
    {status && <div className='edit-attendee-modal__form-error'>{status}</div>}

    <DfoFormField
      id='attendeeFirstName'
      name='attendeeFirstName'
      label='First Name'
      placeholder='Jane'
      className='edit-attendee-modal__first-name'
      component={RoundTextInput}
      validate={composeValidators([validateRequired, validateName])}
    />

    <DfoFormField
      id='attendeeLastName'
      name='attendeeLastName'
      label='Last Name'
      placeholder='Dough'
      className='edit-attendee-modal__last-name'
      component={RoundTextInput}
      validate={composeValidators([validateRequired, validateName])}
    />

    <DfoFormField
      id='attendeeEmail'
      name='attendeeEmail'
      label='Email'
      placeholder='email.address@email.com'
      className='edit-attendee-modal__email'
      component={RoundTextInput}
      validate={composeValidators([validateRequired, validateEmail])}
    />

    <RoundButton
      type='submit'
      submitting={isSubmitting}
      disabled={!isValid || isSubmitting}
    >
      Save and Select Meal
    </RoundButton>
  </Form>
)

const EditAttendeeModal = ({ attendeeId, onCancel, onSubmit, showModal }) => (
  <Modal
    className='edit-attendee-modal'
    maxWidth='484px'
    showModal={showModal}
    onCancel={onCancel}
  >
    <h3>Add attendee and select meal</h3>

    <div className='modal-section'>
      Please add the attendee’s name and email to proceed.
    </div>

    <Formik
      initialValues={{
        attendeeFirstName: '',
        attendeeLastName: '',
        attendeeEmail: ''
      }}
      validateOnChange
      onSubmit={(values, actions) =>
        onSubmit(
          attendeeId,
          values['attendeeFirstName'],
          values['attendeeLastName'],
          values['attendeeEmail']
        ).catch(error => {
          actions.setSubmitting(false)
          if (error && error.message === 'ALL_ATTENDEE_SLOTS_TAKEN') {
            actions.setStatus('Attendee already assigned for email address')
            actions.setFieldError('attendeeEmail', ' ')
          } else {
            throw error
          }
        })
      }
      component={AttendeeNameForm}
    />
  </Modal>
)

export default EditAttendeeModal
