import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import get from 'lodash/get'

import { ReactComponent as CreditCardIcon } from 'assets/dfo/icon--credit-card.svg'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import PaymentMethod from 'components/newOrderWorkflow/checkoutPage/checkoutMain/paymentMethod/PaymentMethod'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import CCAuthErrorModal from 'components/newOrderWorkflow/shared/ccAuthErrorModal/CCAuthErrorModal'
import {
  AlertErrorMessage,
  AlertSuccessMessage
} from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import { createSecureToken } from 'services/checkout'
import {
  updateCreditCard,
  processGroupOrder,
  getGroupOrder
} from 'redux/modules/groupOrder'
import { getPayments } from 'redux/modules/user'
import { selectGroupOrder, selectPayments } from 'redux/selectors'
import {
  isChargeFailed,
  canUpdatePayment as checkCanUpdatePayment
} from 'utils/order'
import { CC_ERROR, MESSAGE_PROCESSING_ERROR } from 'errors'

const UsedPaymentMethod = ({
  profileData,
  paymentError,
  showPaymentUpdateSuccess,
  charged
}) => (
  <div className='used-payment-method'>
    <div className='used-payment-method__label'>Card Used for Order</div>
    <div className='used-payment-method__card'>
      <CreditCardIcon />
      <span>&bull;&bull;&bull;&bull;&nbsp;{profileData.lastFour}</span>
    </div>
    {paymentError && <AlertErrorMessage>{paymentError}</AlertErrorMessage>}
    {showPaymentUpdateSuccess && (
      <AlertSuccessMessage>
        Credit card for this order is updated
        {charged ? ' and charged successfully.' : '.'}
      </AlertSuccessMessage>
    )}
  </div>
)

const PaymentMethodForm = ({ ccProfile, payments, onSubmit, submitting }) => (
  <Formik
    initialValues={{
      ccProfile: {
        id: get(ccProfile, 'ccProfileId') || get(payments, '[0]ccProfileId'), // if these are undefined, 'Add New Card' form will open up
        saveCard: false,
        number: '',
        cvv: '',
        expirationDate: '',
        zipCode: '',
        firstName: '',
        lastName: ''
      }
    }}
    onSubmit={onSubmit}
    validateOnBlur={false}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <PaymentMethod payments={payments} {...formikProps} sidebar />
        <br />
        <RoundButton label='Update Payment Now' submitting={submitting} />
      </Form>
    )}
  </Formik>
)

const Payment = ({
  profileData,
  orderComplete,
  paymentError,
  groupOrder,
  payments,
  getPayments,
  updateCreditCard,
  processGroupOrder,
  getGroupOrder
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(
    paymentError ? true : false
  )
  const [paymentUpdateSuccessful, setPaymentUpdateSuccessful] = useState(false)
  const [ccUpdateError, setCcUpdateError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [chargeCard, setChargeCard] = useState(false)
  const canUpdatePayment = checkCanUpdatePayment(groupOrder)

  useEffect(() => {
    if (isChargeFailed(groupOrder)) {
      getPayments()
      setChargeCard(true)
    }
  }, [])

  const onSubmit = async (values, actions) => {
    let { ccProfile } = values
    const { setFieldError } = actions

    if (ccProfile.id === undefined) {
      try {
        ccProfile = {
          expMonth: ccProfile.expirationDate.slice(0, 2),
          expYear: ccProfile.expirationDate.slice(2),
          ...ccProfile
        }

        // new card: get secure-submit token from Heartland API
        ccProfile = await createSecureToken(ccProfile)
      } catch (err) {
        if (err && err.param === 'card.number') {
          setFieldError('ccProfile.number', err.message)
        } else if (err && err.param === 'card.exp_year') {
          setFieldError('ccProfile.expirationDate', err.message)
        } else {
          console.error(err)
          throw err
        }
        return
      }
    } else {
      // existing cards only require the 'id' field to be set:
      ccProfile = {
        id: ccProfile.id
      }
    }

    setSubmitting(true)
    try {
      await updateCreditCard(groupOrder.id, ccProfile)

      // If the order is in CHARGED_FAILED state
      // Process it right away
      if (isChargeFailed(groupOrder)) {
        await processGroupOrder(groupOrder.id)
      }

      await getGroupOrder(groupOrder.id)
      setCcUpdateError(null)
      setPaymentUpdateSuccessful(true)
      setShowPaymentMethod(false)
    } catch (err) {
      if (err && get(err, 'data.validationErrors[0].code') === CC_ERROR) {
        setCcUpdateError(err.message)
      } else {
        setCcUpdateError(MESSAGE_PROCESSING_ERROR)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const toggleButton = paymentError ? null : showPaymentMethod ? (
    <DfoAction onClick={() => setShowPaymentMethod(false)}>Cancel</DfoAction>
  ) : (
    <DfoAction
      onClick={() => {
        setShowPaymentMethod(true)
        setPaymentUpdateSuccessful(false)
        getPayments()
      }}
    >
      Edit
    </DfoAction>
  )

  return (
    <div className='payment'>
      <div className='payment__header'>
        <h2 className='payment__title'>
          {orderComplete ? 'Payment Method Used' : 'Payment Method'}
        </h2>
        {canUpdatePayment && toggleButton}
      </div>

      <UsedPaymentMethod
        profileData={profileData}
        paymentError={paymentError && MESSAGE_PROCESSING_ERROR}
        showPaymentUpdateSuccess={paymentUpdateSuccessful && !showPaymentMethod}
        charged={chargeCard}
      />

      {showPaymentMethod && (
        <>
          {payments && (
            <PaymentMethodForm
              ccProfile={profileData}
              payments={payments}
              onSubmit={onSubmit}
              submitting={submitting}
            />
          )}
          {paymentError && (
            <div className='payment__payment-method-warning'>
              <AlertErrorMessage>
                Your credit card will be processed immediately to ensure on-time
                delivery.
              </AlertErrorMessage>
            </div>
          )}
        </>
      )}

      <CCAuthErrorModal
        showModal={!!ccUpdateError}
        lastFour={profileData.lastFour}
        onClose={() => setCcUpdateError(false)}
        customMessage={ccUpdateError}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  groupOrder: selectGroupOrder(state),
  payments: selectPayments(state)
})

const mapDispatchToProps = {
  getPayments,
  updateCreditCard,
  processGroupOrder,
  getGroupOrder
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment)
