import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import keys from 'lodash/keys'
import intersection from 'lodash/intersection'
import { parse } from 'date-fns'

import LocationSearch from './fields/LocationSearch'
import TimeSearch from './fields/TimeSearch'
import DateSearch from './fields/DateSearch'
import PeopleCountInput from './fields/PeopleCountInput'

import track, {
  INPUT_CHANGE,
  UPDATE_DELIVERY_TIME,
  UPDATE_DELIVERY_DATE,
  SEARCH_LOCATION,
  UPDATE_ATTENDEE_COUNT
} from 'services/tracking'

import { setNavAlert } from 'redux/modules/alerts'
import { newGroupOrder, selectNavAlert } from 'redux/selectors'

import { BAD_DELIVERY_DETAILS_ALERT } from 'components/newOrderWorkflow/navAlerts/constants'

import { ReactComponent as NextStepIcon } from 'assets/dfo/icon--chevron.svg'

import {
  validateRequired,
  validateAttendeeCount,
  validateStoreTimeAvailability
} from 'utils/validators'

function DeliveryOptionsForm ({
  handleSubmit,
  errors,
  touched,
  validateField,
  setTouched,
  setFieldValue,
  setFieldTouched,
  setNavAlert,
  deliveryOptionsInvalid,
  store,
  navAlert
}) {
  useEffect(() => {
    if (deliveryOptionsInvalid) {
      processSubmit()
    }
  }, [deliveryOptionsInvalid])

  function getReadableErrorName (name) {
    switch (name) {
      case 'date':
        return 'date'
      case 'time':
        return 'time'
      case 'attendee_count':
        return 'number of people'
      default:
        return ''
    }
  }

  useEffect(() => {
    const errorsToConsider = intersection(keys(errors), keys(touched))
    if (
      errorsToConsider.length > 0 &&
      errors.attendee_count !== 'Too High' &&
      errors.time !== 'Not In Range'
    ) {
      let errNames = errorsToConsider.map(err => {
        return getReadableErrorName(err)
      })
      let lastErr = errNames.pop()
      let msgMiddle
      if (errNames.length > 0) {
        msgMiddle = errNames.join(', ') + ` and ${lastErr}`
      } else {
        msgMiddle = lastErr
      }
      const errorMsg = `Please provide the ${msgMiddle} for this order.`
      setNavAlert({
        name: BAD_DELIVERY_DETAILS_ALERT,
        messageOverride: errorMsg
      })
    }

    if (
      errorsToConsider.includes('attendee_count') &&
      errors.attendee_count === 'Too High' &&
      errorsToConsider.includes('time') &&
      errors.time === 'Not In Range'
    ) {
      setNavAlert({
        name: BAD_DELIVERY_DETAILS_ALERT,
        messageOverride: `This restaurant does not deliver at this time or allow more than ${
          store.maxHeadCount
        } attendees.`
      })
    } else if (
      errorsToConsider.includes('attendee_count') &&
      errors.attendee_count === 'Too High'
    ) {
      setNavAlert({
        name: BAD_DELIVERY_DETAILS_ALERT,
        messageOverride: `This restaurant does not allow more than ${
          store.maxHeadCount
        } attendees.`
      })
    } else if (
      errorsToConsider.includes('time') &&
      errors.time === 'Not In Range'
    ) {
      setNavAlert({
        name: BAD_DELIVERY_DETAILS_ALERT,
        messageOverride:
          'This restaurant is not available for the selected time.'
      })
    }
  }, [errors, touched])

  useEffect(() => {
    const errorsToConsider = intersection(keys(errors), keys(touched))
    if (
      errorsToConsider.length === 0 &&
      navAlert.name === BAD_DELIVERY_DETAILS_ALERT
    ) {
      setNavAlert(null)
    }
  }, [errors, navAlert])

  const handleTrack = action => () => {
    track({
      category: INPUT_CHANGE,
      action
    })
  }

  const processSubmit = () => {
    setTouched({ date: true, time: true, attendee_count: true })
    handleSubmit()
  }

  const handleTimeValidation = value => {
    const startTime = parse(store.startDeliveryTime, 'HH:mm:ss', 0)
    const endTime = parse(store.endDeliveryTime, 'HH:mm:ss', 0)
    const selectedTime = parse(value, 'hh:mm a', 0)

    return validateStoreTimeAvailability(startTime, endTime)(selectedTime)
  }

  return (
    <form>
      <LocationSearch trackChange={handleTrack(SEARCH_LOCATION)} />
      <DateSearch
        validate={validateRequired}
        setValue={setFieldValue}
        trackChange={handleTrack(UPDATE_DELIVERY_DATE)}
      />
      <TimeSearch
        validate={!!store ? handleTimeValidation : validateRequired}
        validateField={() => validateField('time')} //react-sel
        setValue={setFieldValue}
        trackChange={handleTrack(UPDATE_DELIVERY_TIME)}
        setFieldTouched={setFieldTouched}
      />
      <PeopleCountInput
        validate={validateAttendeeCount(0, !!store ? store.maxHeadCount : null)}
        setValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        trackChange={handleTrack(UPDATE_ATTENDEE_COUNT)}
      />
      <span className='delivery-options__text'>people.</span>
      <div className='delivery-options__submit'>
        <button
          className='order-workflow-header__next-step-button delivery-options-button'
          type='button'
          onClick={processSubmit}
        >
          <NextStepIcon />
        </button>
      </div>
    </form>
  )
}

export default connect(
  state => ({
    deliveryOptionsInvalid: newGroupOrder.selectDeliveryOptionsInvalid(state),
    store: newGroupOrder.selectStore(state),
    navAlert: selectNavAlert(state)
  }),
  {
    setNavAlert
  }
)(DeliveryOptionsForm)
