import React from 'react'
import { Modal } from '@foodsby/nutrient'

import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'

const DeleteAttendeeModal = ({ showModal, onCancel, onSubmit, submitting }) => (
  <Modal
    className='delete-attendee-modal'
    maxWidth='484px'
    showCancel={false}
    showModal={showModal}
    onCancel={onCancel}
  >
    <h3>
      <AlertErrorIcon />
      <span>Please confirm you want to delete this attendee’s order</span>
    </h3>

    <div className='modal-section'>This action can’t be undone.</div>

    <div className='modal-actions'>
      <DfoAction
        className='primary-alt'
        onClick={onCancel}
        disabled={submitting}
      >
        Don't Delete
      </DfoAction>
      <RoundButton
        className='primary-alt'
        onClick={onSubmit}
        submitting={submitting}
      >
        Delete Attendee Order
      </RoundButton>
    </div>
  </Modal>
)

export default DeleteAttendeeModal
