import React from 'react'

import { isIE11 } from 'utils/browser'

const StoreLogo = ({ store }) => (
  <div className='store-logo'>
    {isIE11 ? (
      <div
        className='store-logo__img'
        style={{ backgroundImage: `url('${store.logo}')` }}
      />
    ) : (
      <img
        className='store-logo__img'
        src={store.logo}
        alt={`${store.name} Logo`}
      />
    )}
  </div>
)

export default StoreLogo
