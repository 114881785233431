import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import persistState from 'redux-sessionstorage'
import LogRocket from 'logrocket'
import { isValid } from 'date-fns'

import { isDevEnvironment } from 'utils/browser'
import rootReducer from './reducers'

const middleware = [thunk, LogRocket.reduxMiddleware()]

// Only persist state for local testing so refreshing doesn't wipe out data when setting up order
const enhancers = isDevEnvironment()
  ? [
      persistState('newGroupOrder', {
        deserialize: data =>
          JSON.parse(data, (k, v) => {
            if (k === 'dropoff' && !!v) {
              const d = new Date(v)
              if (isValid(d)) {
                return d
              }
            }
            return v
          })
      })
    ]
  : []

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const composedEnhancers = composeEnhancers(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer, composedEnhancers)

export default store
