import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { Input } from '@foodsby/nutrient'

/*
 * To get rid of an annoying console warning the customInput needs to be class component :/
 * (see: https://github.com/s-yadav/react-number-format/issues/334#issuecomment-542412771)
 */
class WrappedInput extends Component {
  render () {
    return <Input {...this.props} />
  }
}

const RoundMonthYearInput = ({ field, form, ...props }) => (
  <div className='round-month-day-input'>
    <NumberFormat
      format='##/####'
      customInput={WrappedInput}
      onValueChange={({ value }) => form.setFieldValue(field.name, value)}
      onBlur={field.onBlur}
      {...props}
    />
  </div>
)

export default RoundMonthYearInput
