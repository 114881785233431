import React from 'react'
import { CheckboxOption } from '@foodsby/nutrient-v3'

const DfoCheckboxOption = ({ id, label, subtext, field, ...rest }) => (
  <div className='dfo-checkbox-option'>
    <CheckboxOption label={label} id={id} hint={subtext} {...field} {...rest} />
  </div>
)

export default DfoCheckboxOption
