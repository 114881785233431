import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import classnames from 'classnames'
import map from 'lodash/map'
import partition from 'lodash/partition'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { Modal } from '@foodsby/nutrient'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import LoadingDots from 'components/loadingDots/LoadingDots'
import AbandonOrderPrompt from 'components/newOrderWorkflow/shared/AbandonOrderPrompt'
import AwShucks, { randomMessage } from 'components/awShucks/AwShucks'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import { RESTAURANT_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'
import { INCOMPLETE_RESTAURANT_STEP_ALERT } from 'components/newOrderWorkflow/navAlerts/constants'
import StoreCard from './storeCard/StoreCard'
import ViewSelector from './viewSelector/ViewSelector'
import { getStores } from 'redux/modules/store'
import { setLocationFromAddress, setGoLocationId } from 'redux/modules/location'
import { setGroupOrder, setCurrentOrderStep } from 'redux/modules/newGroupOrder'
import { setNavAlert } from 'redux/modules/alerts'
import {
  newGroupOrder,
  selectCurrentUser,
  selectStores,
  selectLocation,
  selectConfig,
  selectSearchedAddress,
  selectNewGroupOrder
} from 'redux/selectors'
import {
  fromDateTime,
  isTimeSet,
  toMidnight,
  convertTimeToDate
} from 'utils/datetime'
import { formatAddress } from 'utils/formatters'
import track, { CLICK_EVENT, CLICK_VIEW_RESTAURANT } from 'services/tracking'
import {
  NEW_BACKUP_MEAL_PATH,
  STORES_FOR_LOCATION_PATH,
  STORES_FOR_NO_LOCATION_PATH
} from 'routes'

const LocationSearchingModal = ({ showModal }) => (
  <Modal
    className='modal--center location-searching-modal'
    showModal={showModal}
    showCancel={false}
  >
    <h3>Searching...</h3>
    <LoadingDots />
  </Modal>
)

const StoreSearchResults = ({
  stores,
  storesLoading,
  shucksMessage,
  onClick,
  viewAsColumn
}) => {
  const centered = storesLoading || stores.length === 0
  return (
    <div
      className={classnames('store-search-results', {
        'store-search-results--centered': centered
      })}
    >
      {storesLoading && <div>Loading...</div>}
      {!storesLoading &&
        (stores.length > 0 ? (
          <StoresList
            stores={stores}
            onClick={onClick}
            viewAsColumn={viewAsColumn}
          />
        ) : (
          <AwShucks message={shucksMessage} />
        ))}
    </div>
  )
}

const StoresList = ({ stores, onClick, viewAsColumn }) => {
  const [activeStores, inactiveStores] = partition(stores, {
    inactive: false
  })

  return (
    <div className='stores-list'>
      <div
        className={classnames('store-list', {
          'store-list--row-view': !viewAsColumn
        })}
      >
        {map(activeStores, store => (
          <StoreCard
            key={store.id}
            store={store}
            deliveryFeeInCents={store.deliveryFeeInCents}
            onClick={() => onClick(store)}
            viewAsColumn={viewAsColumn}
          />
        ))}
      </div>

      {inactiveStores.length > 0 && (
        <>
          <div
            className={classnames('store-list__inactive-header', {
              'store-list__inactive-header--row-view': !viewAsColumn
            })}
          >
            <h3>
              These restaurants aren't available based off your selected
              criteria.
            </h3>
          </div>

          <div
            className={classnames('store-list store-list--inactive', {
              'store-list--row-view': !viewAsColumn
            })}
          >
            {map(inactiveStores, store => (
              <StoreCard
                key={store.id}
                store={store}
                deliveryFeeInCents={store.deliveryFeeInCents}
                viewAsColumn={viewAsColumn}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

const StoreSearchMain = ({
  location,
  stores,
  searchedAddress,
  storesLoading,
  shucksMessage,
  onClickStore,
  history,
  viewAsColumn,
  toggleView
}) => {
  let address

  if (searchedAddress) {
    address = searchedAddress
  } else if (location) {
    address = formatAddress(location)
  }

  return (
    <div className='store-search-main'>
      <div
        className={classnames('store-search-main__header', {
          'store-search-main__header--row-view': !viewAsColumn
        })}
      >
        <h1 className='dfo-h1'>Select a restaurant for your group order</h1>
      </div>

      <ViewSelector toggleView={toggleView} viewAsColumn={viewAsColumn} />

      <StoreSearchResults
        stores={stores}
        storesLoading={storesLoading}
        shucksMessage={shucksMessage}
        onClick={onClickStore}
        history={history}
        viewAsColumn={viewAsColumn}
      />
    </div>
  )
}

class StoreSearchPage extends Component {
  constructor (props) {
    super(props)

    const locationId = get(this.props.match, 'params.locationId')

    let storesLoading = true
    if (locationId) {
      this.props
        .getStores(locationId)
        .then(() => this.props.setGoLocationId(locationId))
        .then(() => this.setState({ storesLoading: false }))
    } else {
      storesLoading = false
    }

    const viewAsColumn =
      window.localStorage.getItem('viewPreference') === 'true' ? true : false

    this.state = {
      viewAsColumn,
      storesLoading,
      searchingLocation: false,
      shucksMessage: randomMessage()
    }
  }

  componentDidUpdate (prevProps) {
    const prevLocationId = get(prevProps.match, 'params.locationId')
    const locationId = get(this.props.match, 'params.locationId')

    if (locationId && prevLocationId !== locationId) {
      this.props
        .getStores(locationId)
        .then(() => this.setState({ storesLoading: false }))
    } else if (locationId === undefined && prevLocationId) {
      const shucksMessage = randomMessage(this.state.shucksMessage)
      this.setState({ storesLoading: false, shucksMessage })
    }
  }

  onClickStore = store => {
    track({
      category: CLICK_EVENT,
      action: CLICK_VIEW_RESTAURANT
    })
    this.props.setCurrentOrderStep(null)
    this.initGroupOrderAndContinue(store.id)
  }

  initGroupOrderAndContinue = storeId => {
    const { date } = this.state

    const time = isTimeSet(this.state.time)
      ? this.state.time
      : toMidnight(new Date())
    const dropoff = fromDateTime(date, time)

    this.props.setGroupOrder({
      locationId: this.props.match.params.locationId,
      attendeesCount: this.props.attendeesCount || 0,
      dropoff,
      storeId
    })

    this.props.history.push(NEW_BACKUP_MEAL_PATH)
  }

  applyFilters = stores =>
    map(stores, store => {
      const { attendeesCount, dropoff } = this.props
      let active = !attendeesCount || attendeesCount <= store.maxHeadCount

      // 12:00:00 AM is when dropoff time is not set
      if (dropoff && dropoff.toLocaleTimeString() !== '12:00:00 AM') {
        const startDelivery = convertTimeToDate(
          store.startDeliveryTime,
          dropoff
        )
        const endDelivery = convertTimeToDate(store.endDeliveryTime, dropoff)

        // Determine activeness based on the attendee count and dropoff time (if selected)
        active = active && (dropoff >= startDelivery && dropoff <= endDelivery)
      }

      return { ...store, inactive: !active }
    })

  onSelectLocation = async (address, placeId) => {
    this.setState({ searchingLocation: true })

    const locationId = await this.props.setLocationFromAddress(address, placeId)
    const state = { address }

    if (locationId) {
      this.props.history.push(
        formatRoute(STORES_FOR_LOCATION_PATH, { locationId }, state)
      )
    } else {
      this.props.history.push(STORES_FOR_NO_LOCATION_PATH, state)
      const shucksMessage = randomMessage(this.state.shucksMessage)
      this.setState({ shucksMessage })
    }

    this.setState({ searchingLocation: false })
  }

  toggleView = viewAsColumn => {
    this.setState({ viewAsColumn })
    window.localStorage.setItem('viewPreference', viewAsColumn)
  }

  render () {
    const {
      currentUser,
      currentLocation,
      searchedAddress,
      stores,
      newGroupOrder,
      history
    } = this.props

    const {
      storesLoading,
      searchingLocation,
      shucksMessage,
      viewAsColumn
    } = this.state

    // If we don't have a location, we're going to show the 'Aw Shucks' content for the searched address
    const locationId = get(this.props.match, 'params.locationId')
    const filteredAndSortedStores = locationId
      ? sortBy(this.applyFilters(stores), ['inactive', 'name'])
      : []

    return (
      <DfoLayout currentUser={currentUser} backgroundColor='grey'>
        <OrderWorkflowLayout
          headerProps={{
            stepName: RESTAURANT_ORDER_STEP,
            onClickFutureStep: (step, isComplete) => {
              if (isComplete) {
                this.props.history.push(step.path)
              }
            },
            onClickNextStep: step => {
              if (!newGroupOrder.storeId) {
                this.props.setNavAlert({
                  name: INCOMPLETE_RESTAURANT_STEP_ALERT
                })
              } else {
                this.props.history.push(step.path)
              }
            }
          }}
          mainComponent={
            <StoreSearchMain
              location={currentLocation}
              searchedAddress={searchedAddress}
              stores={filteredAndSortedStores}
              storesLoading={storesLoading}
              shucksMessage={shucksMessage}
              onClickStore={this.onClickStore}
              history={history}
              viewAsColumn={viewAsColumn}
              toggleView={this.toggleView}
            />
          }
        />

        <LocationSearchingModal showModal={searchingLocation} />

        <AbandonOrderPrompt />
      </DfoLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  currentLocation: selectLocation(state),
  stores: selectStores(state),
  config: selectConfig(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  dropoff: newGroupOrder.selectDropoff(state),
  searchedAddress: selectSearchedAddress(state),
  newGroupOrder: selectNewGroupOrder(state)
})

const mapDispatchToProps = {
  getStores,
  setGroupOrder,
  setLocationFromAddress,
  setGoLocationId,
  setCurrentOrderStep,
  setNavAlert
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSearchPage)
