import React from 'react'
import WarningModal from 'components/newOrderWorkflow/shared/warningModal/WarningModal'
import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'

const CCAuthErrorModal = ({ showModal, lastFour, onClose, customMessage }) => {
  const title = "There's a problem with your credit card"
  const message = `There was a problem authorizing your card ending in ${lastFour}.
                      Please update your payment information and try again.`

  const buttons = [<RoundButton onClick={onClose}>Okay</RoundButton>]

  return (
    <WarningModal
      showModal={showModal}
      title={title}
      message={customMessage || message}
      buttons={buttons}
      icon={<AlertErrorIcon />}
      maxWidth='500px'
    />
  )
}

export default CCAuthErrorModal
