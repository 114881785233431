import React from 'react'
import classNames from 'classnames'

import StoreLogo from 'components/storeLogo/StoreLogo'
import { fromCentsDollarFormatNoSymbol } from 'utils/money'
import { ReactComponent as AttendeesIcon } from 'assets/dfo/icon--attendees.svg'

const StoreCard = ({ store, onClick, viewAsColumn }) => (
  <div
    className={classNames('store-card', {
      'store-card--inactive': store.inactive,
      'store-card--row-view': !viewAsColumn,
      'store-card--col-view': viewAsColumn
    })}
    onClick={() => !store.inactive && onClick()}
    onKeyPress={evt => {
      if (evt.which === '13' && !store.inactive) {
        onClick()
      }
    }}
    tabIndex='0'
  >
    <StoreLogo store={store} />
    <div className='store-card__store-info'>
      <div className='store-card__store-name'>{store.name}</div>
      <div className='store-card__max-attendees'>
        <AttendeesIcon />
        <span>
          Good for up to&nbsp;
          <strong>{store.maxHeadCount} people</strong>
        </span>
      </div>

      <div className='store-card__details'>
        <div className='store-card__block'>
          <div className='store-card__block-wrapper'>
            <div className='store-card__value'>
              <span className='store-card__currency'>$</span>
              <span>
                {fromCentsDollarFormatNoSymbol(
                  store.minimumPriceInCents
                ).format()}
              </span>
            </div>
            <div className='store-card__label'>Minimum</div>
          </div>
        </div>
        <div className='store-card__block'>
          <div className='store-card__block-wrapper'>
            <div className='store-card__value'>
              <span className='store-card__currency'>$</span>
              <span>
                {fromCentsDollarFormatNoSymbol(
                  store.deliveryFeeInCents
                ).format()}
              </span>
            </div>
            <div className='store-card__label'>Delivery fee</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default StoreCard
