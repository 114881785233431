import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { formatRoute } from 'react-router-named-routes'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import partition from 'lodash/partition'
import classnames from 'classnames'

import NextMeeting from 'components/nextMeeting/NextMeeting'
import UpcomingOrderList from './upcomingOrderList/UpcomingOrderList'
import PastOrderList from './pastOrderList/PastOrderList'
import {
  selectGroupOrderList,
  selectAttendeeGroupOrders
} from 'redux/selectors'
import { EDIT_GROUP_ORDER_PATH, ATTENDEE_TOKEN_MEAL_PATH } from 'routes'
import { isPastDropoff, isCancelled, isEditable } from 'utils/order'
import {
  GROUP_ORDER_LIST_ORGANIZING_PATH,
  GROUP_ORDER_LIST_ATTENDING_PATH
} from 'routes'
import { loadLocation } from 'api'

class GroupOrderListMain extends Component {
  handleRowClick = groupOrder => {
    if (groupOrder.attendeeId) {
      if (isEditable(groupOrder)) {
        this.props.history.push(
          formatRoute(ATTENDEE_TOKEN_MEAL_PATH, {
            token: groupOrder.attendeeUrlToken
          })
        )
      }
    } else {
      this.props.history.push(
        formatRoute(EDIT_GROUP_ORDER_PATH, { groupOrderId: groupOrder.id })
      )
    }
  }

  state = {
    nextMeetingLocation: null
  }

  async componentDidUpdate (prevProps) {
    const nextMeeting = this.getNextMeeting()
    if (nextMeeting && !this.state.nextMeetingLocation) {
      const location = await loadLocation(nextMeeting.monolithLocationId)
      this.setState({ nextMeetingLocation: location })
    }
  }

  getNextMeeting = () => {
    const nextMeeting = sortBy(
      this.props.attendeeGroupOrders,
      'dropoff'
    ).filter(go => !isPastDropoff(go) && !isCancelled(go))[0]
    return nextMeeting
  }

  render () {
    const { groupOrderList, attendeeGroupOrders, location } = this.props
    const isAttendeeSelected = location.pathname.endsWith('/attending')

    const groupOrders = isAttendeeSelected
      ? attendeeGroupOrders
      : groupOrderList

    const [pastOrders, upcomingOrders] = partition(
      groupOrders,
      o => isPastDropoff(o) || isCancelled(o)
    )

    const nextMeeting = this.getNextMeeting()

    const upcomingOrdersSorted = sortBy(upcomingOrders, 'dropoff')
    const pastOrdersSorted = sortBy(pastOrders, 'dropoff')

    const Tab = ({ isActive, href, children }) => {
      const className = classnames('group-order-list-main__tab', {
        'group-order-list-main__tab--active': isActive
      })
      return (
        <li className={className}>
          <Link to={href}>
            <div>{children}</div>
          </Link>
        </li>
      )
    }

    return (
      <div className='group-order-list-main'>
        {nextMeeting && this.state.nextMeetingLocation && (
          <NextMeeting
            meeting={nextMeeting}
            location={this.state.nextMeetingLocation}
            editOrder={this.handleRowClick}
          />
        )}
        <ul className='group-order-list-main__tabs'>
          <Tab
            href={GROUP_ORDER_LIST_ORGANIZING_PATH}
            isActive={!isAttendeeSelected}
          >
            Organizing
          </Tab>
          <Tab
            href={GROUP_ORDER_LIST_ATTENDING_PATH}
            isActive={isAttendeeSelected}
          >
            Attending
          </Tab>
        </ul>
        <div className='group-order-list-main__lists'>
          <UpcomingOrderList
            groupOrders={upcomingOrdersSorted}
            onClickRow={this.handleRowClick}
            isAttendee={isAttendeeSelected}
          />

          <PastOrderList
            groupOrders={pastOrdersSorted}
            onClickRow={this.handleRowClick}
            isAttendee={isAttendeeSelected}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  groupOrderList: selectGroupOrderList(state),
  attendeeGroupOrders: selectAttendeeGroupOrders(state)
})

const mapDispatchToProps = {}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GroupOrderListMain)
)
