import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from '@foodsby/nutrient'

import LoginToggle from 'components/login/LoginToggle'
import { selectGoLocationId } from 'redux/selectors'
import { setAccessToken } from 'redux/modules/auth'
import { register, login } from 'utils/api'

const LoginModal = ({
  show,
  onCancel,
  onSuccess,
  setAccessToken,
  locationId,
  hideRegister
}) => {
  const [loginFocused, setLoginFocused] = useState(hideRegister)

  const onToggleForm = () => setLoginFocused(!loginFocused)

  const onLogin = async ({ username, password }) => {
    const tokens = await login(username, password)
    setAccessToken(tokens.access_token)
    onSuccess()
  }

  const onRegister = async values => {
    const body = {
      ...values,
      locationId: locationId,
      platform: 'WEB',
      entryPoint: 'GROUP_ORDER_SELF_SIGN_UP'
    }

    await register(body)
    await onLogin({ username: body.email, password: body.password })
  }

  const loginToggleProps = {}
  if (!hideRegister) {
    loginToggleProps.onRegister = onRegister
    loginToggleProps.onToggleForm = onToggleForm
  }

  return (
    <Modal showModal={show} onCancel={onCancel}>
      <h3>
        {hideRegister ? 'Log In to Continue' : 'Log In or Sign Up to Continue'}
      </h3>
      <LoginToggle
        onLogin={onLogin}
        loginFocused={loginFocused || hideRegister}
        {...loginToggleProps}
      />
    </Modal>
  )
}

const mapStateToProps = state => ({
  locationId: selectGoLocationId(state)
})

const mapDispatchToProps = {
  setAccessToken,
  selectGoLocationId
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModal)
