import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { getByToken } from 'api'
import TokenAttendee from 'components/tokenAttendee/TokenAttendee'
import AttendeeLoginPage from 'components/attendeeLoginPage/AttendeeLoginPage'
import AttendeeMealPage from '../newOrderWorkflow/attendeeMealPage/AttendeeMealPage'
import AttendeeMealStatusPage from 'components/attendeeMealStatusPage/AttendeeMealStatusPage'

import {
  ATTENDEE_TOKEN_PATH,
  ATTENDEE_TOKEN_LOGIN_PATH,
  ATTENDEE_TOKEN_MEAL_PATH,
  ATTENDEE_TOKEN_STATUS_PATH
} from 'routes'

class AttendeeToken extends Component {
  state = {
    tokenResponse: null
  }

  async componentDidMount () {
    const { token } = this.props.match.params

    const tokenResponse = await getByToken(token)

    this.setState({ tokenResponse })
  }

  render () {
    const { tokenResponse } = this.state

    if (!tokenResponse) {
      return null
    }

    return (
      <Switch>
        <Route
          path={ATTENDEE_TOKEN_PATH}
          exact
          render={props => (
            <TokenAttendee tokenResponse={tokenResponse} {...props} />
          )}
        />
        <Route
          path={ATTENDEE_TOKEN_LOGIN_PATH}
          exact
          render={props => (
            <AttendeeLoginPage tokenResponse={tokenResponse} {...props} />
          )}
        />
        <Route
          path={ATTENDEE_TOKEN_MEAL_PATH}
          exact
          render={props => (
            <AttendeeMealPage tokenResponse={tokenResponse} {...props} />
          )}
        />
        <Route
          path={ATTENDEE_TOKEN_STATUS_PATH}
          exact
          render={props => <AttendeeMealStatusPage {...props} />}
        />
      </Switch>
    )
  }
}

export default AttendeeToken
