import React from 'react'
import classnames from 'classnames'
import { Field } from 'formik'

import DfoCheckboxOption from 'components/newOrderWorkflow/shared/dfoCheckboxOption/DfoCheckboxOption'
import DfoFormField from 'components/newOrderWorkflow/dfoFormField/DfoFormField'
import RoundTextInput from 'components/newOrderWorkflow/roundTextInput/RoundTextInput'
import RoundMonthYearInput from 'components/newOrderWorkflow/roundMonthYearInput/RoundMonthYearInput'
import {
  validateCCNumber,
  validateCVV,
  validateZipCode,
  validateCCExpiration,
  validateRequired
} from 'utils/validators'
import { ignoreOnEnter } from 'utils/inputs'

const CreditCardDetail = ({ sidebar }) => (
  <div
    className={classnames('credit-card-detail', {
      'credit-card-detail--sidebar': sidebar
    })}
  >
    <div className='credit-card-detail__number'>
      <DfoFormField
        id='ccProfile.number'
        name='ccProfile.number'
        fieldClassName='_lr-hide'
        label='Card Number'
        placeholder='**** **** **** 1234'
        validate={validateCCNumber}
        maxLength={19}
        component={RoundTextInput}
        onKeyPress={ignoreOnEnter}
      />
    </div>

    <div className='credit-card-detail__expiration-date'>
      <DfoFormField
        id='ccProfile.expirationDate'
        name='ccProfile.expirationDate'
        fieldClassName='_lr-hide'
        label='Expiration Date'
        placeholder='MM/YYYY'
        validate={validateCCExpiration}
        component={RoundMonthYearInput}
        onKeyPress={ignoreOnEnter}
      />
    </div>

    <div className='credit-card-detail__cvv'>
      <DfoFormField
        id='ccProfile.cvv'
        name='ccProfile.cvv'
        fieldClassName='_lr-hide'
        label='CVV'
        placeholder='1234'
        validate={validateCVV}
        maxLength={4}
        component={RoundTextInput}
        onKeyPress={ignoreOnEnter}
      />
    </div>

    <div className='credit-card-detail__zip-code'>
      <DfoFormField
        id='ccProfile.zipCode'
        name='ccProfile.zipCode'
        fieldClassName='_lr-hide'
        label='Zip Code'
        placeholder='55555'
        maxLength={5}
        validate={validateZipCode}
        component={RoundTextInput}
        onKeyPress={ignoreOnEnter}
      />
    </div>

    <div className='credit-card-detail__first-name'>
      <DfoFormField
        id='ccProfile.firstName'
        name='ccProfile.firstName'
        fieldClassName='_lr-hide'
        label='First Name'
        placeholder='Jane'
        validate={validateRequired}
        component={RoundTextInput}
        onKeyPress={ignoreOnEnter}
      />
    </div>

    <div className='credit-card-detail__last-name'>
      <DfoFormField
        id='ccProfile.lastName'
        name='ccProfile.lastName'
        fieldClassName='_lr-hide'
        label='Last Name'
        placeholder='Dough'
        validate={validateRequired}
        component={RoundTextInput}
        onKeyPress={ignoreOnEnter}
      />
    </div>

    <div className='credit-card-detail__save-card'>
      <Field
        id='ccProfile.saveCard'
        name='ccProfile.saveCard'
        label='Save this card for future use'
        component={DfoCheckboxOption}
        onKeyPress={ignoreOnEnter}
      />
    </div>
  </div>
)

export default CreditCardDetail
