import React, { useState, useRef } from 'react'
import { Button } from '@foodsby/nutrient'

import LocationSearch from './LocationSearch'
import LoadingDots from 'components/loadingDots/LoadingDots'

const LocationSearchWithButton = ({
  onSelect,
  submitting,
  autoFocus = true
}) => {
  const [value, setValue] = useState()

  const autocompleteRef = useRef(null)

  const clickButton = () => {
    if (autocompleteRef.current) {
      autocompleteRef.current.fetchPredictions()
    }
  }

  return (
    <div className='location-search-with-button'>
      <LocationSearch
        value={value}
        onChange={setValue}
        onSelect={onSelect}
        autocompleteRef={autocompleteRef}
        autoFocus={autoFocus}
      />
      {submitting && <LoadingDots count={3} />}
      {!submitting && (
        <Button
          disabled={!value}
          onClick={clickButton}
          className='location-search-with-button__button'
        >
          Search
        </Button>
      )}
    </div>
  )
}

export default LocationSearchWithButton
