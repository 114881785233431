import React, { useState } from 'react'
import classnames from 'classnames'
import Collapsible from 'react-collapsible'

import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import { AlertSuccessMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import { RoundTextarea } from 'components/newOrderWorkflow/roundTextarea/RoundTextarea'
import { createSupportTicket, createAttendeeSupportTicket } from 'api'
import { Spinner } from '@foodsby/nutrient'

const OrderSupportForm = ({
  isOpen,
  setIsOpen,
  isComplete,
  setIsComplete,
  body,
  setBody,
  isSubmitting,
  createTicket
}) => {
  const [closing, setClosing] = useState(false)

  return (
    <div
      className={classnames('order-support-form', {
        'order-support-form--opened': isOpen && !isComplete,
        'order-support-form--complete': !isOpen && isComplete,
        'order-support-form--closing': closing,
        'order-support-form--closed': !isOpen && !isComplete
      })}
    >
      <div className='order-support-form__top'>
        <h1 className='dfo-h1 order-support-form__title'>
          Need help with your order?
        </h1>

        <Collapsible
          classParentString='order-support-form__collapsible'
          open={isOpen}
          onClosing={() => setClosing(true)}
          onClose={() => setClosing(false)}
        >
          <div className='order-support-form__wrapper'>
            <RoundTextarea
              className={classnames('order-support-form__textarea', {
                'order-support-form__textarea--submitting': isSubmitting
              })}
              rows={4}
              onChange={e => setBody(e.target.value)}
              value={body}
              placeholder='Type your message here.'
            />

            {isSubmitting && <Spinner size='sm' />}
          </div>
        </Collapsible>

        <div className='order-support-form__actions'>
          {isOpen && !isComplete && (
            <RoundButton
              className='secondary-alt'
              disabled={isSubmitting || !body}
              onClick={createTicket}
            >
              Send to Support
            </RoundButton>
          )}
          {!isOpen && (
            <RoundButton
              className='secondary-alt'
              onClick={() => {
                setIsComplete(false)
                setIsOpen(true)
              }}
            >
              Contact Support
            </RoundButton>
          )}
        </div>
      </div>

      {isComplete && (
        <AlertSuccessMessage>Your message has been sent.</AlertSuccessMessage>
      )}
    </div>
  )
}

const ContactSupport = ({ groupOrder, attendee }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [body, setBody] = useState('')

  const createTicket = async () => {
    setIsSubmitting(true)
    try {
      attendee
        ? await createAttendeeSupportTicket(attendee.id, body)
        : await createSupportTicket(groupOrder.id, body)
      setIsComplete(true)
    } finally {
      setIsSubmitting(false)
      setBody('')
      setIsOpen(false)
    }
  }

  return (
    <OrderSupportForm
      body={body}
      setBody={setBody}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isComplete={isComplete}
      setIsComplete={setIsComplete}
      isSubmitting={isSubmitting}
      createTicket={createTicket}
    />
  )
}

export default ContactSupport
