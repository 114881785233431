import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import AppLayout from 'components/appLayout/AppLayout'
import LoginToggle from 'components/login/LoginToggle'
import { login } from 'utils/api'
import { setAccessToken } from 'redux/modules/auth'
import { selectCurrentUser } from 'redux/selectors'
import { STORES_PATH } from 'routes'

const GroupLoginPage = ({ history, setAccessToken, currentUser, location }) => {
  useEffect(() => {
    if (!isEmpty(currentUser)) {
      const redirectPath =
        location && location.state && location.state.pathname
          ? location.state.pathname
          : STORES_PATH
      history.push(redirectPath)
    }
  }, [currentUser])

  const onLogin = async ({ username, password }) => {
    const tokens = await login(username, password)
    setAccessToken(tokens.access_token)
  }

  return (
    <AppLayout headerProps={{ fixedHeader: true }}>
      <div className='login-page group-login-page'>
        <div className='login-page__wrapper'>
          <div className='login-page__header'>Welcome!</div>
          <div className='group-login-page__party-started'>
            Let's get this party started!
          </div>
          <LoginToggle onLogin={onLogin} loginFocused />
        </div>
      </div>
    </AppLayout>
  )
}

const mapDispatchToProps = {
  setAccessToken
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupLoginPage)
