import React from 'react'

import { formatTenDigitPhoneNumber } from 'utils/formatters'

const DeliveryDetailsFormatted = ({
  contactName,
  contactPhoneNumber,
  contactPhoneExtension,
  contactEmail,
  suiteNumber,
  dropoffInstructions
}) => (
  <div className='delivery-details-formatted'>
    <div className='delivery-details-formatted__table'>
      <div className='delivery-details-formatted__row'>
        <div className='delivery-details-formatted__name'>{contactName}</div>
        <div className='delivery-details-formatted__phone'>
          {formatTenDigitPhoneNumber(contactPhoneNumber)}
        </div>
        <div className='delivery-details-formatted__phone-ext'>
          {contactPhoneExtension && <>ext. {contactPhoneExtension}</>}
        </div>
      </div>

      <div className='delivery-details-formatted__row'>
        <div className='delivery-details-formatted__email'>{contactEmail}</div>
        <div className='delivery-details-formatted__suite-number'>
          {suiteNumber && <>Suite #{suiteNumber}</>}
        </div>
      </div>
    </div>

    <div className='delivery-details-formatted__delivery-instructions'>
      <span className='delivery-details-formatted__delivery-instructions-label'>
        Delivery Instructions:
      </span>{' '}
      <span className='delivery-details-formatted__delivery-instructions'>
        {dropoffInstructions}
      </span>
    </div>
  </div>
)

export default DeliveryDetailsFormatted
