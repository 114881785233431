import trim from 'lodash/trim'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import { isDateInRange } from 'utils/datetime'

export const validateRequired = value => (value ? undefined : 'Required')

export const validateMinLength = minLength => value =>
  value && value.length >= minLength
    ? undefined
    : `Minimum of ${minLength} characters`

export const validateMaxLength = maxLength => value =>
  value && value.length <= maxLength
    ? undefined
    : `Maximum of ${maxLength} characters`

export const validateNoSpaces = value =>
  /^\S*$/.test(value) ? undefined : 'Cannot contain spaces'

export const validateName = value => validateRequired(trim(value))

export const validateEmail = value =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? undefined
    : 'Invalid email address'

export const validatePhone = value =>
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
    value
  )
    ? undefined
    : 'Invalid'

export const validateCCNumber = value =>
  /^[0-9]{15,19}$/.test(value) ? undefined : 'Invalid Credit Card Number'

export const validateCVV = value =>
  /^[0-9]{3,4}$/.test(value) ? undefined : 'Invalid'

export const validateCCExpiration = value => {
  const now = new Date()
  const nowMonth = now.getMonth()
  const nowYear = now.getFullYear()

  const valueMonth = parseInt(value.slice(0, 2))
  const valueYear = parseInt(value.slice(2))

  const isDateValid =
    valueYear > nowYear || (valueYear === nowYear && valueMonth >= nowMonth)

  return /^(0[1-9]|1[0-2])\/?[0-9]{4}$/.test(value) && isDateValid
    ? undefined
    : 'Invalid'
}

export const validateZipCode = value =>
  /^[0-9]{5}$/.test(value) ? undefined : 'Invalid'

export const validateAttendeeCount = (mt, lte) => value => {
  if (!value) return 'Required'
  let result = value > mt ? undefined : 'Too Low'
  if (!result && !!lte) {
    result = value <= lte ? undefined : 'Too High'
  }
  return result
}

export const validateStoreTimeAvailability = (st, et) => value => {
  if (!value) return 'Required'

  return isDateInRange(value, st, et) ? undefined : 'Not In Range'
}

export const composeValidators = (validators, cb = noop) => value => {
  for (const validator of validators) {
    const error = validator(value)
    if (error) {
      return error
    }
  }
  cb(value)
  return undefined
}

export const validateDropoffInstructions = value => {
  if (isEmpty(value)) {
    return 'Please give us some delivery instructions first!'
  }
}

export const validateBudget = (
  value,
  mealPrice,
  highestMealCost,
  cb = noop
) => {
  if (value < 0) {
    return 'Budget must be a positive value'
  } else if (value < highestMealCost) {
    return "Your budget can't be less than the highest attendee meal selected."
  } else if (value < mealPrice) {
    return 'Your budget can’t be less than the backup meal selected.'
  } else if (value > 50000) {
    return "Budget can't be greater than $500"
  }
  cb(value)
  return undefined
}
