import React from 'react'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import OrderOverview, {
  DeliverSection,
  EditMealSection
} from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import { ReactComponent as MealIcon } from 'assets/dfo/icon--backup-meal-grey.svg'
import { calculateBackupMealTotal } from 'utils/order'

const EditBackupMealSidebar = ({
  meal,
  groupOrder,
  isValid,
  submitting,
  submitAttempted,
  onEdit,
  onRemove,
  saveMeal,
  currentStore
}) => {
  const { attendees, dropoff } = groupOrder

  const mealTotalCost = calculateBackupMealTotal(meal)
  const hasBudget = groupOrder && !!groupOrder.budgetPerAttendeeInCents

  return (
    <StickySidebar className='dfo-sidebar edit-default-meal-sidebar'>
      <OrderOverview>
        <DeliverSection
          dropoff={new Date(dropoff)}
          attendeesCount={size(attendees)}
        />

        {!isEmpty(meal) && (
          <EditMealSection
            storeName={currentStore.name}
            meals={meal}
            onEditMealItem={onEdit}
            onRemoveMealItem={onRemove}
            name='Backup Meal'
            showPrice
            mealTotalCost={mealTotalCost}
            noBudget={!hasBudget}
          />
        )}

        <div className='order-overview-section backup-meal-instructions-section'>
          <div className='backup-meal-instructions-section__header'>
            <MealIcon />
            <span>Backup Meal</span>
          </div>
          <div className='backup-meal-instructions-section__content'>
            {isEmpty(meal)
              ? "Choose a meal for attendees to receive if they don't make their own selection after you invite them to your group order."
              : "Attendees will receive this meal if they don't make their own selection after you invite them to your group order."}
          </div>
        </div>
      </OrderOverview>

      <DfoSubmitButton
        label='Save Changes'
        onClick={() => saveMeal(meal)}
        disabled={submitting}
      />

      {submitAttempted && !isValid && (
        <AlertErrorMessage>Please choose a backup meal</AlertErrorMessage>
      )}
    </StickySidebar>
  )
}

export default EditBackupMealSidebar
