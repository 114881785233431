import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import {
  OrderContactSection,
  DeliveryDetailsSection,
  BackupMealSection,
  MealBudgetSection
} from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import { newGroupOrder, selectCurrentUser } from 'redux/selectors'
import {
  NEW_MEAL_BUDGET_PATH,
  NEW_DELIVERY_DETAILS_PATH,
  NEW_BACKUP_MEAL_PATH
} from 'routes'

let OrderSummaryMain = ({
  currentStore,
  currentLocation,
  contactName,
  contactPhoneNumber,
  contactPhoneExtension,
  contactEmail,
  suiteNumber,
  attendeesCount,
  dropoff,
  dropoffInstructions,
  defaultMeal,
  budgetPerAttendeeInCents,
  noBudget,
  setDeliveryOptionsFormOpen,
  onSubmit,
  history
}) => {
  if (!currentStore || !currentLocation) {
    return null
  }

  return (
    <div className='order-summary-main'>
      <div className='order-summary-main__content'>
        <h1 className='dfo-h1'>Is all order information correct?</h1>

        <div className='order-summary-main__overview'>
          <DeliveryDetailsSection
            dropoffLocation={currentLocation}
            dropoff={dropoff}
            dropoffInstructions={dropoffInstructions}
            attendeesCount={attendeesCount}
            onClickAction={() => setDeliveryOptionsFormOpen(true)}
          />
          <BackupMealSection
            storeName={currentStore.name}
            meals={defaultMeal}
            onClickAction={() => history.push(NEW_BACKUP_MEAL_PATH)}
          />
          <MealBudgetSection
            budgetPerAttendeeInCents={budgetPerAttendeeInCents}
            onClickAction={() => history.push(NEW_MEAL_BUDGET_PATH)}
            noBudget={noBudget}
          />
          <OrderContactSection
            suiteNumber={suiteNumber}
            contactName={contactName}
            contactPhoneNumber={contactPhoneNumber}
            contactPhoneExtension={contactPhoneExtension}
            contactEmail={contactEmail}
            dropoffInstructions={dropoffInstructions}
            onClickAction={() => history.push(NEW_DELIVERY_DETAILS_PATH)}
          />
        </div>

        <DfoSubmitButton label='Continue to Checkout' onClick={onSubmit} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  currentStore: newGroupOrder.selectStore(state),
  currentLocation: newGroupOrder.selectLocation(state),
  contactName: newGroupOrder.selectContactName(state),
  contactPhoneNumber: newGroupOrder.selectContactPhoneNumber(state),
  contactPhoneExtension: newGroupOrder.selectContactPhoneExtension(state),
  contactEmail: newGroupOrder.selectContactEmail(state),
  suiteNumber: newGroupOrder.selectSuiteNumber(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  dropoff: newGroupOrder.selectDropoff(state),
  dropoffInstructions: newGroupOrder.selectDropoffInstructions(state),
  defaultMeal: newGroupOrder.selectDefaultMeal(state),
  budgetPerAttendeeInCents: newGroupOrder.selectBudgetPerAttendeeInCents(state),
  noBudget: newGroupOrder.selectNoBudget(state)
})

OrderSummaryMain = withRouter(OrderSummaryMain)

export default connect(
  mapStateToProps,
  null
)(OrderSummaryMain)
