import React from 'react'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import WarningModal from 'components/newOrderWorkflow/shared/warningModal/WarningModal'
import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'

const RestaurantNotAvailableModal = ({
  showModal,
  type,
  maxCount,
  onModify,
  onViewAll
}) => {
  let message, title, modifyButton
  if (type && (type.time && type.attendeesCount)) {
    title = 'Restaurant not available'
    message = `This restaurant does not deliver at this time or allow more than ${maxCount} attendees. Please adjust them or select a different restaurant.`
    modifyButton = 'Update Order'
  } else if (type && type.attendeesCount) {
    title = 'The number of attendees is too high'
    message = `This restaurant supports orders for ${maxCount} people or fewer.
              Please adjust your headcount or select a different restaurant.`
    modifyButton = 'Change Headcount'
  } else if (type && type.time) {
    title = 'This delivery time is not available'
    message =
      'This restaurant does not deliver at this time. Please adjust the delivery time or select a different restaurant'
    modifyButton = 'Change Time'
  }

  const buttons = [
    <RoundButton className='borderless' onClick={onModify}>
      {modifyButton}
    </RoundButton>,
    <RoundButton onClick={onViewAll}>View Restaurants</RoundButton>
  ]

  return (
    <WarningModal
      showModal={showModal}
      title={title}
      message={message}
      buttons={buttons}
      icon={<AlertErrorIcon />}
      maxWidth='520px'
    />
  )
}

export default RestaurantNotAvailableModal
