import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { selectNewGroupOrder } from 'redux/selectors'

import { updateGroupOrder } from 'redux/modules/newGroupOrder'

import DfoFormField from 'components/newOrderWorkflow/dfoFormField/DfoFormField'
import RoundTextInput from 'components/newOrderWorkflow/roundTextInput/RoundTextInput'

function AttendeeCountInput ({
  currentCount,
  updateGroupOrder,
  validate,
  setValue,
  setFieldTouched,
  trackChange
}) {
  const setAttendeeFieldTouched = () => setFieldTouched('attendee_count', true)
  const onChange = e => {
    trackChange()
    updateGroupOrder({ attendeesCount: e.target.value })
  }

  const onIncrement = directionUp => () => {
    if (parseInt(currentCount || 0) - 1 < 1 && !directionUp) {
      return
    }
    trackChange()
    const newVal = parseInt(currentCount || '0') + (directionUp ? 1 : -1)
    updateGroupOrder({ attendeesCount: newVal.toString() })
    setTimeout(setAttendeeFieldTouched, 100)
  }

  useEffect(() => {
    setValue('attendee_count', currentCount || '')
  }, [currentCount])

  return (
    <DfoFormField
      id='attendee_count'
      name='attendee_count'
      className='delivery-options__attendee_count'
      label='for'
      placeholder='10'
      autoComplete='off'
      component={RoundTextInput}
      buttonLeftText='-'
      buttonRightText='+'
      onButtonLeftClick={onIncrement(false)}
      onButtonRightClick={onIncrement(true)}
      value={currentCount || ''}
      onChange={onChange}
      type='number'
      validate={validate}
    />
  )
}

const mapStateToProps = state => ({
  currentCount: selectNewGroupOrder(state).attendeesCount
})

const mapDispatchToProps = {
  updateGroupOrder
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendeeCountInput)
