import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'query-string'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import ShareOrderMain from './shareOrderMain/ShareOrderMain'
import {
  selectGroupOrder,
  selectCurrentUser,
  selectLocation
} from 'redux/selectors'
import { clearGroupOrder } from 'redux/modules/newGroupOrder'
import { getGroupOrder } from 'redux/modules/groupOrder'
import { SHARE_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'

const ShareOrderPage = props => {
  const { groupOrderId } = queryString.parse(props.location.search)

  useEffect(() => {
    // Wipe out in-progress order now that checkout is complete
    props.clearGroupOrder()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await props.getGroupOrder(groupOrderId)
    }
    fetchData()
  }, [groupOrderId])

  return (
    <DfoLayout currentUser={props.currentUser}>
      <OrderWorkflowLayout
        headerProps={{ stepName: SHARE_ORDER_STEP }}
        mainComponent={<ShareOrderMain {...props} />}
      />
    </DfoLayout>
  )
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  currentLocation: selectLocation(state),
  groupOrder: selectGroupOrder(state)
})

const mapDispatchToProps = {
  getGroupOrder,
  clearGroupOrder
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShareOrderPage))
