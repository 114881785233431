import queryString from 'query-string'

import api from './utils/api'

const GROUP_ORDER_BASE = '/api/v1/group-order'
const MONOLITH_BASE = '/api-monolith'
const AUTH_BASE = '/api/v1/uaa/users'

export const loadStoresForLocation = locationId =>
  api.get(`${GROUP_ORDER_BASE}/location/${locationId}/stores`)

export const loadMenuForStore = storeId =>
  api.get(`${MONOLITH_BASE}/store/${storeId}/menu`)

export const loadGroupOrderLocation = locationName =>
  api.get(`${GROUP_ORDER_BASE}/location/name/${locationName}`)

export const loadGroupOrderSupportedLocation = locationId =>
  api.get(`${GROUP_ORDER_BASE}/location/${locationId}/supported`)

export const loadLocation = locationId =>
  api.get(`${MONOLITH_BASE}/location/${locationId}`)

export const loadMenuItem = itemId =>
  api.get(`${MONOLITH_BASE}/menu/item/${itemId}`)

export const loadUserPayments = () => api.get(`${MONOLITH_BASE}/payments`)

export const loadUserProfile = userId =>
  api.get(`${AUTH_BASE}/${userId}/profiles`)

export const loadDefaultMealForStore = (
  storeId,
  headCount,
  storeMinInCents
) => {
  const qs = queryString.stringify({ headCount, storeMinInCents })
  return api.get(`${GROUP_ORDER_BASE}/store/${storeId}/default-menu-item?${qs}`)
}

export const loadAllGroupOrders = () => api.get(`${GROUP_ORDER_BASE}/order`)

export const loadGroupOrder = groupOrderId =>
  api.get(`${GROUP_ORDER_BASE}/order/${groupOrderId}`)

export const createGroupOrder = groupOrderBody =>
  api.post(`${GROUP_ORDER_BASE}/order`, groupOrderBody)

export const updateGroupOrder = (groupOrderId, body) =>
  api.patch(`${GROUP_ORDER_BASE}/order/${groupOrderId}`, body)

export const createAttendee = groupOrderId =>
  api.post(`${GROUP_ORDER_BASE}/order/${groupOrderId}/attendee`)

export const assignAttendee = (groupOrderId, body) =>
  api.post(`${GROUP_ORDER_BASE}/order/${groupOrderId}/assign-attendee`, body)

export const deleteAttendee = (groupOrderId, attendeeId) =>
  api.delete(`${GROUP_ORDER_BASE}/order/${groupOrderId}/attendee/${attendeeId}`)

export const loadConfig = () => api.get(`${GROUP_ORDER_BASE}/configuration`)

export const updateDefaultMeal = (groupOrderId, body) =>
  api.put(`${GROUP_ORDER_BASE}/order/${groupOrderId}/default-meal`, body)

export const getByToken = token =>
  api.get(`${GROUP_ORDER_BASE}/order/token/${token}`)

export const joinGroupOrder = groupOrderId =>
  api.post(`${GROUP_ORDER_BASE}/order/${groupOrderId}/join`)

export const getTaxes = (
  storeId,
  monolithLocationId,
  deliveryDate,
  foodCostInCents,
  foodCostDiscountInCents,
  feeCostDiscountAmountInCents
) => {
  const body = {
    storeId,
    monolithLocationId,
    deliveryDate,
    foodCostInCents,
    foodCostDiscountInCents,
    feeCostDiscountAmountInCents
  }
  return api.put(`${GROUP_ORDER_BASE}/payment/estimate-taxes`, body)
}

export const updateAttendeeMeal = (attendeeId, body) =>
  api.put(`${GROUP_ORDER_BASE}/attendee/${attendeeId}/meal`, body)

export const createSupportTicket = (groupOrderId, comments) =>
  api.post(`${GROUP_ORDER_BASE}/order/${groupOrderId}/support`, { comments })

export const createAttendeeSupportTicket = (attendeeId, comments) =>
  api.post(`${GROUP_ORDER_BASE}/attendee/${attendeeId}/support`, { comments })

export const validateCoupon = body =>
  api.put(`${GROUP_ORDER_BASE}/payment/validate-coupon`, body)

export const loadLocationFromAddress = ({ address, latLng }) =>
  api.post(`${GROUP_ORDER_BASE}/location/address/stores`, {
    ...address,
    latitude: latLng.lat,
    longitude: latLng.lng
  })

export const loadAttendeeGroupOrders = () =>
  api.get(`${GROUP_ORDER_BASE}/order/attendee`)

export const updateCreditCard = (groupOrderId, body) =>
  api.put(
    `${GROUP_ORDER_BASE}/payment/order/${groupOrderId}/payment-profile`,
    body
  )

export const processGroupOrder = groupOrderId =>
  api.post(`${GROUP_ORDER_BASE}/order/${groupOrderId}/process?charge=true`)
