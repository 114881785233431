import React from 'react'
import isEmpty from 'lodash/isEmpty'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import OrderOverview, {
  DeliverSection,
  EditMealSection
} from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import { ReactComponent as MealIcon } from 'assets/dfo/icon--backup-meal-grey.svg'
import { calculateBackupMealTotal } from 'utils/order'

const NewBackupMealSidebar = ({
  meal,
  groupOrder,
  isValid,
  deliveryOptionsValid,
  submitting,
  submitAttempted,
  onEdit,
  onRemove,
  saveMeal,
  currentStore,
  setDeliveryOptionsFormOpen
}) => {
  const { attendeesCount, dropoff } = groupOrder

  const onClickDeliver = deliveryOptionsValid
    ? () => setDeliveryOptionsFormOpen(true)
    : null

  const mealTotalCost = calculateBackupMealTotal(meal)

  return (
    <StickySidebar className='dfo-sidebar new-default-meal-sidebar'>
      <OrderOverview>
        <DeliverSection
          dropoff={dropoff}
          attendeesCount={attendeesCount}
          onClickAction={onClickDeliver}
        />

        {!isEmpty(meal) && (
          <EditMealSection
            storeName={currentStore.name}
            meals={meal}
            onEditMealItem={onEdit}
            onRemoveMealItem={onRemove}
            name='Backup Meal'
            showPrice
            mealTotalCost={mealTotalCost}
          />
        )}

        <div className='order-overview-section backup-meal-instructions-section'>
          <div className='backup-meal-instructions-section__header'>
            <MealIcon />
            <span>Backup Meal</span>
          </div>
          <div className='backup-meal-instructions-section__content'>
            {isEmpty(meal)
              ? "Choose a meal for attendees to receive if they don't make their own selection after you invite them to your group order."
              : "Attendees will receive this meal if they don't make their own selection after you invite them to your group order."}
          </div>
        </div>
      </OrderOverview>

      <DfoSubmitButton
        label='Set Meal Budget'
        onClick={() => saveMeal(meal)}
        submitting={submitting}
      />

      {submitAttempted && !isValid && (
        <AlertErrorMessage>Please choose a backup meal</AlertErrorMessage>
      )}
      {submitAttempted && isValid && !deliveryOptionsValid && (
        <AlertErrorMessage>
          Please complete all required fields before proceeding.
        </AlertErrorMessage>
      )}
    </StickySidebar>
  )
}

export default NewBackupMealSidebar
