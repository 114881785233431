import React from 'react'
import { FastField } from 'formik'
import pluralize from 'pluralize'

import AnswerRadioOption from './AnswerRadioOption'
import AnswerCheckboxOption from './AnswerCheckboxOption'

const validateLength = (min, max, isRadio) => val => {
  if (isRadio) {
    return val ? undefined : { footer: 'Required', inline: 'Required' }
  }

  if (min > 0 && !val) {
    return { footer: 'Required', inline: 'Required' }
  } else if (val && val.length < min) {
    return {
      footer: `Make at least ${pluralize('selection', min, true)}`,
      inline: `Pick at least ${min}`
    }
  } else if (max > 0 && val && val.length > max) {
    return {
      footer: `Maximum ${pluralize('selection', max, true)} allowed`,
      inline: `Pick up to ${max}`
    }
  }
}

const AnswerField = ({ answer, question }) => {
  const { minimumSelection, maximumSelection } = question
  const isRadio = minimumSelection === 1 && maximumSelection === 1
  const validator = validateLength(minimumSelection, maximumSelection, isRadio)
  return (
    <FastField key={answer.id} name={question.formId} validate={validator}>
      {props =>
        isRadio ? (
          <AnswerRadioOption {...props} item={answer} />
        ) : (
          <AnswerCheckboxOption {...props} item={answer} />
        )
      }
    </FastField>
  )
}

export default AnswerField
