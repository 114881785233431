import React from 'react'

import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import { formatTenDigitPhoneNumber } from 'utils/formatters'

const OrderContactInfo = ({
  contactName,
  contactEmail,
  contactPhoneNumber,
  contactPhoneExtension,
  suiteNumber,
  onClickAction
}) => (
  <div className='order-contact-info'>
    <div className='order-contact-info__header'>
      <div className='order-contact-info__title'>Deliver on</div>
      <DfoAction className='order-contact-info__action' onClick={onClickAction}>
        Edit
      </DfoAction>
    </div>

    <div className='order-contact-info__contact-info'>
      <div className='order-contact-info__table'>
        <div className='order-contact-info__row'>
          <div className='order-contact-info__name'>{contactName}</div>
          <div className='order-contact-info__phone'>
            {formatTenDigitPhoneNumber(contactPhoneNumber)}
          </div>
          <div className='order-contact-info__phone-ext'>
            {contactPhoneExtension && <>ext. {contactPhoneExtension}</>}
          </div>
        </div>

        <div className='order-contact-info__row'>
          <div className='order-contact-info__email'>{contactEmail}</div>
          <div className='order-contact-info__suite-number'>
            {suiteNumber && <>Suite #{suiteNumber}&nbsp;</>}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default OrderContactInfo
