import React from 'react'

import DfoCheckboxOption from 'components/newOrderWorkflow/shared/dfoCheckboxOption/DfoCheckboxOption'
import Answer from './Answer'
import { getPriceText } from './utils'
import track, { INPUT_CHANGE, SELECT_MENU_ITEM_OPTION } from 'services/tracking'

const AnswerCheckboxOption = ({ item, field, form }) => {
  const value = field.value || []

  const onChange = () => {
    let nextValue
    if (value.includes(item.id)) {
      nextValue = value.filter(v => v !== item.id)
      form.setFieldValue(field.name, nextValue)
    } else {
      nextValue = [...value, item.id]
      form.setFieldValue(field.name, nextValue)
    }

    track({
      category: INPUT_CHANGE,
      action: SELECT_MENU_ITEM_OPTION
    })
  }

  return (
    <Answer>
      <DfoCheckboxOption
        id={`item_${item.id}`}
        name={field.name}
        value={item.id}
        checked={value.includes(item.id)}
        label={item.text}
        subtext={getPriceText(item.price)}
        onChange={onChange}
        onBlur={field.onBlur}
      />
    </Answer>
  )
}

export default AnswerCheckboxOption
