import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'

import SplashLayout from 'components/splashLayout/SplashLayout'
import LocationSearchWithButton from 'components/locationSearch/LocationSearchWithButton'
import AwShucks, {
  randomMessage,
  messageContents
} from 'components/awShucks/AwShucks'
import { setLocationFromAddress } from 'redux/modules/location'
import { selectSearchedAddress, selectCurrentUser } from 'redux/selectors'
import { STORES_FOR_LOCATION_PATH } from 'routes'

const headerColors = ['secondary', 'primary-alt', 'grey', 'blue']
const messageContentsExtended = messageContents.map((m, i) => ({
  ...m,
  headerType: headerColors[i]
}))

class AwShucksPage extends Component {
  state = {
    submitting: false,
    message: randomMessage(null, messageContentsExtended)
  }

  onSelectLocation = async (address, placeId) => {
    this.setState({ submitting: true })

    const locationId = await this.props.setLocationFromAddress(address, placeId)

    if (locationId) {
      this.props.history.push(
        formatRoute(STORES_FOR_LOCATION_PATH, { locationId })
      )
    } else {
      const message = randomMessage(this.state.message, messageContentsExtended)
      this.setState({ submitting: false, message })
    }
  }

  render () {
    const { searchedAddress, currentUser } = this.props
    const { submitting, message } = this.state

    return (
      <SplashLayout
        className='aw-shucks-page'
        headerType={message.headerType}
        headerProps={{ fixedHeader: true }}
        currentUser={currentUser}
      >
        <div className='aw-shucks-page__body'>
          <AwShucks message={message} />

          <div className='aw-shucks-page__you-searched-for'>
            {searchedAddress && <div>You searched for: {searchedAddress}</div>}
          </div>

          <div className='aw-shucks-page__location-search'>
            <LocationSearchWithButton
              onSelect={this.onSelectLocation}
              submitting={submitting}
            />
          </div>
        </div>
      </SplashLayout>
    )
  }
}

const mapStateToProps = state => ({
  searchedAddress: selectSearchedAddress(state),
  currentUser: selectCurrentUser(state)
})

const mapDispatchToProps = {
  setLocationFromAddress
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AwShucksPage)
