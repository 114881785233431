import React from 'react'

import OrderWorkflowHeader from 'components/newOrderWorkflow/orderWorkflowHeader/OrderWorkflowHeader'
import DfoPageLayout from 'components/newOrderWorkflow/dfoPageLayout/DfoPageLayout'

const OrderWorkflowLayout = ({
  mainComponent,
  sidebarComponent,
  headerProps,
  inlineMobileSidebar = false
}) => (
  <DfoPageLayout
    headerComponent={<OrderWorkflowHeader {...headerProps} />}
    mainComponent={mainComponent}
    sidebarComponent={sidebarComponent}
    inlineMobileSidebar={inlineMobileSidebar}
  />
)

export default OrderWorkflowLayout
