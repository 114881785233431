import React, { Component } from 'react'

import { isIE11 } from 'utils/browser'

class PageFooter extends Component {
  constructor (props) {
    super(props)

    this.footer = React.createRef()
  }

  resizeFooter = () => {
    setTimeout(() => {
      const footerEl = this.footer.current
      const parent = footerEl.parentElement
      const box = parent.getBoundingClientRect()

      parent.style.paddingBottom = `${footerEl.offsetHeight}px`

      footerEl.style.left = `${box.left}px`
      footerEl.style.width = `${box.width}px`
    })
  }

  componentDidMount () {
    if (isIE11) {
      window.addEventListener('resize', this.resizeFooter)

      this.resizeFooter()
    }
  }

  render () {
    return (
      <footer className='page-footer' ref={this.footer}>
        {this.props.children}
      </footer>
    )
  }
}

export default PageFooter
