import React, { useState, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import { format } from 'date-fns'

import { Formik } from 'formik'
import classnames from 'classnames'

import { isTimeSet } from 'utils/datetime'

import { newGroupOrder } from 'redux/selectors'
import { setNavAlert } from 'redux/modules/alerts'

import DeliveryOptionsForm from './DeliveryOptionsForm'
import AlertLoader from 'components/newOrderWorkflow/navAlerts/components/AlertLoader'

const DeliveryOptionsPortal = ({ children }) =>
  ReactDOM.createPortal(
    children,
    document.getElementById('delivery-options-portal')
  )

function DeliveryOptionsNav ({
  onClose,
  dropoff,
  navAlertName,
  navAlertMessageOverride,
  initialCount,
  setNavAlert
}) {
  const [renderPortal, shouldRenderPortal] = useState(false)

  useLayoutEffect(() => {
    shouldRenderPortal(true)
  }, [])

  const onSubmit = () => {
    setNavAlert()
    onClose()
  }

  if (renderPortal) {
    return (
      <DeliveryOptionsPortal>
        <header
          className={classnames(
            'order-workflow-header order-workflow-header__open',
            {
              'order-workflow-header--extended': !!navAlertName
            }
          )}
        >
          <div className='order-workflow-header__wrapper'>
            <div className='order-workflow-header__body'>
              <Formik
                initialValues={{
                  location: '',
                  date: !!dropoff ? format(dropoff, 'MM/dd/yy') : '',
                  time:
                    !!dropoff && isTimeSet(dropoff)
                      ? format(new Date(dropoff), 'h:mm a')
                      : '',
                  attendee_count: initialCount || ''
                }}
                onSubmit={onSubmit}
                validateOnBlur={false}
                component={DeliveryOptionsForm}
              />
            </div>
          </div>
          {!!navAlertName && (
            <AlertLoader
              alertName={navAlertName}
              messageOverride={navAlertMessageOverride}
            />
          )}
        </header>
      </DeliveryOptionsPortal>
    )
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  dropoff: newGroupOrder.selectDropoff(state),
  initialCount: newGroupOrder.selectAttendeesCount(state)
})

const mapDispatchToProps = {
  setNavAlert
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryOptionsNav)
