export function ignoreOnEnter (e, cb) {
  if (e.which === 13) {
    e.preventDefault()
    e.stopPropagation()
    if (cb) {
      cb(e)
    }
  }
}

export const neuterEvent = callback => event => {
  event.preventDefault()
  event.stopPropagation()
  if (callback) {
    callback(event)
  }
}
