import React from 'react'

import { fromCentsNoSymbol } from 'utils/money'

const MealBudgetFormatted = ({ budgetPerAttendeeInCents, noBudget }) => (
  <div className='meal-budget-formatted'>
    {noBudget ? (
      'No Budget'
    ) : (
      <>
        <span className='meal-budget-formatted__currency'>$</span>
        {fromCentsNoSymbol(budgetPerAttendeeInCents).format()}
      </>
    )}
    <span className='meal-budget-formatted__per-person'> / person</span>
  </div>
)

export default MealBudgetFormatted
