const data = {
  headerMenu: [
    {
      copy: 'Profile',
      href: `${process.env.REACT_APP_ORDER_URL}/profile`
    },
    {
      copy: 'Order History',
      href: '/group-order/orders'
    },
    {
      copy: 'Credits',
      href: `${process.env.REACT_APP_ORDER_URL}/credits`
    },
    {
      copy: 'Friends',
      href: `${process.env.REACT_APP_ORDER_URL}/friends`
    }
  ],
  simpleMenu: [
    {
      copy: 'My Home',
      href: `${process.env.REACT_APP_ORDER_URL}/home`
    }
  ],
  adminMenu: [
    {
      copy: 'My Admin Account',
      href: `${process.env.REACT_APP_BASE_URL}/Admin/EditAccount`
    },
    {
      copy: 'Success',
      href: `${process.env.REACT_APP_BASE_URL}/support/app#/Dashboard/Old`,
      flyoutLinks: [
        {
          copy: 'Dashboard',
          href: `${process.env.REACT_APP_BASE_URL}/support/app#/Dashboard/Main`
        },
        {
          copy: 'Dashboard Late',
          href: `${process.env.REACT_APP_BASE_URL}/support/app#/Dashboard/Late`
        },
        {
          copy: 'Order/Refund Management',
          href: `${process.env.REACT_APP_BASE_URL}/support/app#/Orders/Find`
        },
        {
          copy: 'Find Order Details',
          href: `${process.env.REACT_APP_BASE_URL}/Admin/FindOrder`
        },
        {
          copy: 'Drivers',
          href: `${process.env.REACT_APP_BASE_URL}/Driver`
        },
        {
          copy: 'Messages',
          href: `${process.env.REACT_APP_BASE_URL}/Inbox/Index`
        },
        {
          copy: 'Stores/Menus',
          href: `${process.env.REACT_APP_BASE_URL}/Store`
        },
        {
          copy: 'Markets',
          href: `${
            process.env.REACT_APP_BASE_URL
          }/Sales/App#/FoodsbyMarket/list`
        },
        {
          copy: 'Coupons',
          href: `${process.env.REACT_APP_BASE_URL}/Navigation/Coupons`
        }
      ]
    },
    {
      copy: 'Scheduling',
      href: `${process.env.REACT_APP_BASE_URL}/Sales/App#/Schedule/Calendar`,
      flyoutLinks: [
        {
          copy: 'Schedule',
          href: `${process.env.REACT_APP_BASE_URL}/Sales/App#/Schedule/Calendar`
        },
        {
          copy: 'Zoning',
          href: `${process.env.REACT_APP_BASE_URL}/Sales/App#/Schedule/Zones`
        },
        {
          copy: 'Maps',
          href: `${process.env.REACT_APP_BASE_URL}/Map/Index`
        },
        {
          copy: 'Groups',
          href: `${process.env.REACT_APP_BASE_URL}/Sales/App#/Schedule/Groups`
        }
      ]
    },
    {
      copy: 'Sales/Marketing',
      href: `${process.env.REACT_APP_BASE_URL}/Sales/App`,
      flyoutLinks: [
        {
          copy: 'Maps',
          href: `${process.env.REACT_APP_BASE_URL}/Map/Index`
        },
        {
          copy: 'Locations',
          href: `${process.env.REACT_APP_BASE_URL}/Sales/App#/Location/List`
        },
        {
          copy: 'Stores/Menus',
          href: `${process.env.REACT_APP_BASE_URL}/Store`
        },
        {
          copy: 'Markets',
          href: `${
            process.env.REACT_APP_BASE_URL
          }/Sales/App#/FoodsbyMarket/list`
        },
        {
          copy: 'States',
          href: `${process.env.REACT_APP_BASE_URL}/Sales/App#/market/list`
        },
        {
          copy: 'Notifications',
          href: `${
            process.env.REACT_APP_BASE_URL
          }/Sales/App#/Campaign/dailyEmails`
        }
      ]
    },
    {
      copy: 'Users',
      href: `${
        process.env.REACT_APP_BASE_URL
      }/Support/App#/UserManagement/List`,
      flyoutLinks: [
        {
          copy: 'List',
          href: `${
            process.env.REACT_APP_BASE_URL
          }/Support/App#/UserManagement/List`
        },
        {
          copy: 'Make Admin',
          href: `${
            process.env.REACT_APP_BASE_URL
          }/Support/App#/UserManagement/Roles`
        }
      ]
    },
    {
      copy: 'Stats',
      href: 'https://stats.foodsby.com'
    }
  ],
  foodsbyOne: [
    {
      copy: 'Foodsby One',
      href: process.env.REACT_APP_FOODSBY_ONE_URL
    }
  ],
  logout: [
    {
      copy: 'Log Out',
      href: `${process.env.REACT_APP_ORDER_URL}/logout`
    }
  ]
}

export default data
