import React from 'react'

import onClickOutside from 'react-onclickoutside'

const Dropdown = ({ children, setIsOpen }) => {
  Dropdown.handleClickOutside = () => setIsOpen()
  return <div>{children}</div>
}

const clickOutsideConfig = {
  handleClickOutside: () => Dropdown.handleClickOutside
}

export default onClickOutside(Dropdown, clickOutsideConfig)
