// categories
export const CLICK_EVENT = 'click'
export const INPUT_CHANGE = 'inputChange'
export const PAGE_VIEW = 'pageview'

// ACTIONS

// input changes
export const UPDATE_DELIVERY_TIME = 'updateDeliveryTime'
export const UPDATE_DELIVERY_DATE = 'updateDeliveryDate'
export const SEARCH_LOCATION = 'searchLocation'
export const UPDATE_ATTENDEE_COUNT = 'updateAttendeeCount'
export const SELECT_MENU_ITEM_OPTION = 'selectMenuItemOption'

// click events
export const CLICK_VIEW_RESTAURANT = 'viewRestaurant'
export const CLICK_MENU_ITEM = 'menuItem'
export const CLICK_SAVE_DEFAULT_ITEM = 'saveDefaultItem'
export const CLICK_CHECKOUT = 'checkout'
export const CLICK_CHOOSE_BACKUP_MEAL = 'chooseBackupMeal'
export const CLICK_ROW_VIEW_RESTAURANT = 'rowViewRestaurant'
export const CLICK_COLUMN_VIEW_RESTAURANT = 'columnViewRestaurant'

// eventually we'll swap these internals out with actual eventing
// system, so this creates a global service for tracking
export default async function track (data) {
  const { category, action } = data
  window.dataLayer && window.dataLayer.push({ event: `${category}-${action}` })
}
