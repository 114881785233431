import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import MediaQuery from 'react-responsive'

import AppLayout from 'components/appLayout/AppLayout'
import LoginToggle from 'components/login/LoginToggle'
import AttendeeOrderSummary from './attendeeOrderSummary/AttendeeOrderSummary'
import { setAccessToken } from 'redux/modules/auth'
import { getLocation } from 'redux/modules/location'
import { selectLocation, selectIsLoading } from 'redux/selectors'
import { ATTENDEE_TOKEN_PATH } from 'routes'
import { toDisplayName } from 'utils/user'
import { attendeeLogin, attendeeRegister } from 'redux/modules/auth'
import MobileLoadingSpinner from 'components/mobileLoadingSpinner/MobileLoadingSpinner'

class AttendeeLoginPage extends Component {
  state = {
    loginFocused: true
  }

  componentDidMount () {
    const { monolithLocationId } = this.props.tokenResponse

    this.props.getLocation(monolithLocationId)
  }

  onToggleForm = () => this.setState({ loginFocused: !this.state.loginFocused })

  onLogin = async ({ username, password }) => {
    await this.props.attendeeLogin(username, password)

    this.goToAttendeePage()
  }

  onRegister = async values => {
    const { monolithLocationId } = this.props.tokenResponse

    const body = {
      ...values,
      locationId: monolithLocationId,
      platform: 'WEB',
      entryPoint: 'GROUP_ORDER_ATTENDEE'
    }

    await this.props.attendeeRegister(body)

    this.goToAttendeePage()
  }

  goToAttendeePage = () => {
    const attendeeToken = this.props.tokenResponse.attendeeUrlToken

    this.props.history.replace(
      formatRoute(ATTENDEE_TOKEN_PATH, {
        token: attendeeToken
      })
    )
  }

  render () {
    const { tokenResponse, currentLocation, isLoading } = this.props
    const { loginFocused } = this.state

    if (!tokenResponse || !currentLocation) {
      return null
    }

    const { store, owner, cutoff, dropoff } = tokenResponse

    const ownerName = toDisplayName(owner)

    return (
      <AppLayout headerProps={{ fixedHeader: true }}>
        <div className='login-page attendee-login-page'>
          <div className='login-page__wrapper'>
            <MediaQuery query='(max-width: 768px)'>
              {loginFocused && (
                <AttendeeOrderSummary
                  store={store}
                  location={currentLocation}
                  ownerName={ownerName}
                  cutoff={cutoff}
                  dropoff={dropoff}
                />
              )}

              {!loginFocused && (
                <div className='attendee-login-page__slogan mobile'>
                  A lunch delivery service
                  <br />
                  for office professionals
                </div>
              )}
            </MediaQuery>

            <MediaQuery query='(min-width: 769px)'>
              <AttendeeOrderSummary
                store={store}
                location={currentLocation}
                ownerName={ownerName}
                cutoff={cutoff}
                dropoff={dropoff}
              />
            </MediaQuery>

            <div className='attendee-login-page__forms'>
              <LoginToggle
                onLogin={this.onLogin}
                onRegister={this.onRegister}
                loginFocused={loginFocused}
                onToggleForm={this.onToggleForm}
              />
            </div>
          </div>
          {isLoading && <MobileLoadingSpinner />}
        </div>
      </AppLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentLocation: selectLocation(state),
  isLoading: selectIsLoading(state)
})

const mapDispatchToProps = {
  setAccessToken,
  getLocation,
  attendeeLogin,
  attendeeRegister
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendeeLoginPage)
