import React from 'react'

import { useMediaQuery } from 'react-responsive'

import MessageBar from 'components/newOrderWorkflow/shared/MessageBar'
import { mapAlert } from 'components/newOrderWorkflow/navAlerts/constants'

export default function AlertLoader ({ alertName, messageOverride }) {
  const isDesktop = useMediaQuery({ query: '(min-width: 769px)' })

  const { Component, shortMessage, message, Icon } = mapAlert(alertName)

  const componentSet = !!Component
  return (
    <MessageBar center>
      {Icon && <Icon style={{ height: 10 }} />}{' '}
      {componentSet && !messageOverride && <Component />}
      {!componentSet && !messageOverride && isDesktop ? message : shortMessage}
      {messageOverride}
    </MessageBar>
  )
}
