import React from 'react'
import { Form } from 'formik'
import { Action, Button } from '@foodsby/nutrient'
import { FORGOT_PASSWORD_URL } from 'routes'

import Field from 'components/form/formField/FormField'
import TextInput from 'components/form/TextInput'
import {
  validateEmail,
  validateRequired,
  composeValidators
} from 'utils/validators'

const LoginForm = ({
  onToggleShowPassword,
  onToggleForm,
  showPassword,
  isSubmitting,
  status
}) => (
  <Form>
    <Field
      label='Email'
      id='username'
      name='username'
      component={TextInput}
      validate={composeValidators([validateRequired, validateEmail])}
      showUntouchedError
    />

    <div className='login__password-field'>
      <div className='login__show-password'>
        <Action
          style={{ fontSize: '14px' }}
          onClick={onToggleShowPassword}
          type='button'
          tabIndex='-1'
        >
          Show Password
        </Action>
      </div>
      <Field
        className='_lr-hide'
        label='Password'
        id='password'
        name='password'
        type={showPassword ? 'text' : 'password'}
        component={TextInput}
        validate={validateRequired}
        showUntouchedError
      />
    </div>

    {status && <div className='login__error'>{status}</div>}

    <div className='login__button-group'>
      <Button
        className='primary'
        style={{ margin: 0 }}
        type='submit'
        disabled={isSubmitting}
      >
        Log in
      </Button>

      {onToggleForm && (
        <>
          <div className='login__or mobile'>or</div>
          <Button
            type='button'
            className='primary inverted mobile'
            style={{ margin: 0 }}
            onClick={onToggleForm}
          >
            Sign Up
          </Button>
        </>
      )}
    </div>

    <a className='login__forgot-password' href={FORGOT_PASSWORD_URL}>
      Forgot Password?
    </a>
  </Form>
)

export default LoginForm
