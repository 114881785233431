import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import OrderManagePage from 'components/newOrderWorkflow/orderManagePage/OrderManagePage'
import GroupOrderListPage from 'components/groupOrderListPage/GroupOrderListPage'
import AttendeeMealPage from 'components/newOrderWorkflow/attendeeMealPage/AttendeeMealPage'
import EditBackupMealPage from 'components/newOrderWorkflow/editBackupMealPage/EditBackupMealPage'
import NewBackupMealPage from 'components/newOrderWorkflow/newBackupMealPage/NewBackupMealPage'
import MealBudgetPage from 'components/newOrderWorkflow/mealBudgetPage/MealBudgetPage'
import DeliveryDetailsPage from 'components/newOrderWorkflow/deliveryDetailsPage/DeliveryDetailsPage'
import OrderSummaryPage from 'components/newOrderWorkflow/orderSummaryPage/OrderSummaryPage'
import CheckoutPage from 'components/newOrderWorkflow/checkoutPage/CheckoutPage'
import ShareOrderPage from 'components/newOrderWorkflow/shareOrderPage/ShareOrderPage'
import {
  NEW_MEAL_BUDGET_PATH,
  NEW_DELIVERY_DETAILS_PATH,
  NEW_ORDER_SUMMARY_PATH,
  NEW_GROUP_ORDER_PATH,
  NEW_BACKUP_MEAL_PATH,
  NEW_CHECKOUT_PATH,
  SHARE_ORDER_PATH,
  GROUP_ORDERS_PATH,
  EDIT_GROUP_ORDER_PATH,
  EDIT_BACKUP_MEAL_PATH,
  EDIT_DEFAULT_MEAL_PATH,
  EDIT_ATTENDEE_MEAL_PATH
} from 'routes'
import PrivateRoute from 'components/PrivateRoute'

const GroupOrder = () => (
  <Switch>
    <Route path={NEW_BACKUP_MEAL_PATH} exact component={NewBackupMealPage} />
    <PrivateRoute
      path={NEW_MEAL_BUDGET_PATH}
      exact
      component={MealBudgetPage}
    />
    <PrivateRoute
      path={NEW_DELIVERY_DETAILS_PATH}
      exact
      component={DeliveryDetailsPage}
    />
    <PrivateRoute
      path={NEW_ORDER_SUMMARY_PATH}
      exact
      component={OrderSummaryPage}
    />
    <Redirect from={NEW_GROUP_ORDER_PATH} to={NEW_CHECKOUT_PATH} exact />
    <PrivateRoute path={NEW_CHECKOUT_PATH} exact component={CheckoutPage} />
    <PrivateRoute path={SHARE_ORDER_PATH} exact component={ShareOrderPage} />
    <PrivateRoute path={GROUP_ORDERS_PATH} component={GroupOrderListPage} />
    <PrivateRoute
      path={EDIT_GROUP_ORDER_PATH}
      exact
      component={OrderManagePage}
    />
    <Redirect from={EDIT_DEFAULT_MEAL_PATH} to={EDIT_BACKUP_MEAL_PATH} exact />
    <PrivateRoute
      path={EDIT_BACKUP_MEAL_PATH}
      exact
      component={EditBackupMealPage}
    />
    <PrivateRoute
      path={EDIT_ATTENDEE_MEAL_PATH}
      exact
      component={AttendeeMealPage}
    />
    <Redirect to='/' />
  </Switch>
)

export default GroupOrder
