import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { Input } from '@foodsby/nutrient'

/*
 * To get rid of an annoying console warning the customInput needs to be class component :/
 * (see: https://github.com/s-yadav/react-number-format/issues/334#issuecomment-542412771)
 */
class WrappedInput extends Component {
  render () {
    return <Input {...this.props} />
  }
}

const MoneyInput = props => (
  <NumberFormat
    prefix='$'
    decimalScale={2}
    allowNegative={false}
    fixedDecimalScale
    customInput={WrappedInput}
    {...props}
  />
)

export default MoneyInput
