import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { isIE11 } from 'utils/browser'
import AppHeader from 'components/appHeader/AppHeader'
import { selectCurrentUser } from 'redux/selectors'

class GroupOrderSidebar extends Component {
  constructor (props) {
    super(props)

    this.sidebar = React.createRef()
  }

  resizeSidebar = () => {
    const sidebarEl = this.sidebar.current
    const parent = sidebarEl.parentElement
    const box = parent.getBoundingClientRect()
    const initialWidth = box.width * 0.25
    const sidebarWidth = `${initialWidth > 460 ? initialWidth : 460}px`

    sidebarEl.style.width = sidebarWidth
    parent.style.paddingRight = `${sidebarEl.offsetWidth}px`
    sidebarEl.style.left = `${box.right - sidebarEl.offsetWidth}px`
  }

  componentDidMount () {
    if (isIE11) {
      window.addEventListener('resize', this.resizeSidebar)
      this.resizeSidebar()
    }
  }

  render () {
    return (
      <div
        className={classnames('app-layout-with-sidebar__sidebar', {
          'app-layout-with-sidebar__sidebar--mobile': this.props.mobileReady
        })}
        ref={this.sidebar}
      >
        {this.props.children}
      </div>
    )
  }
}

const GroupOrderMain = ({ children }) => (
  <div className='app-layout-with-sidebar__main'>{children}</div>
)

const AppLayoutWithSidebar = ({
  mainComponent,
  sidebarComponent,
  currentUser,
  mobileReady,
  headerProps
}) => (
  <div className='app-layout-with-sidebar'>
    <AppHeader {...headerProps} />

    <div className='app-layout-with-sidebar__content'>
      <GroupOrderMain>{mainComponent}</GroupOrderMain>
      <GroupOrderSidebar mobileReady={mobileReady}>
        {sidebarComponent}
      </GroupOrderSidebar>
    </div>
  </div>
)

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
})

export default connect(mapStateToProps)(AppLayoutWithSidebar)
