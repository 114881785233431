import React, { Component } from 'react'
import sortBy from 'lodash/sortBy'
import classnames from 'classnames'

import { fromCents } from 'utils/money'
import { formatDateTime } from 'utils/datetime'
import { getOrderStatus, displayMeal } from 'utils/order'

const PastOrderRow = ({ groupOrder, onClick, isAttendee }) => {
  const status = getOrderStatus(groupOrder)

  const className = classnames('order-list__past-row', {
    clickable: !isAttendee
  })

  return (
    <tr onClick={onClick} className={className}>
      <td>{formatDateTime(groupOrder.dropoff)}</td>
      <td>{groupOrder.storeName}</td>
      {isAttendee ? (
        <td className='order-list__meal'>{displayMeal(groupOrder)}</td>
      ) : (
        <td className='order-list__attendees'>{groupOrder.attendeeCount}</td>
      )}
      <td />
      {!isAttendee && (
        <td className='order-list__money'>
          {fromCents(groupOrder.totalInCents).format()}
        </td>
      )}
      <td
        className={classnames('order-list__status', {
          'order-list__status--error': status.error
        })}
      >
        {status.label}
      </td>
      <td className='order-list__action' />
    </tr>
  )
}

class PastOrderList extends Component {
  render () {
    const { groupOrders, onClickRow, isAttendee } = this.props

    return (
      <div className='order-list'>
        <div className='order-list__title'>History</div>
        <div className='order-list__instructions'>
          All completed or canceled orders.
        </div>

        <table className='order-list__table'>
          <thead>
            <tr>
              <th className='order-list__date'>Date</th>
              <th className='order-list__restaurant'>Restaurant</th>
              {isAttendee ? (
                <th className='order-list__meal'>Meal</th>
              ) : (
                <th className='order-list__attendees'>Attendees</th>
              )}
              <th className='order-list__lock-date' />
              {!isAttendee && <th className='order-list__money'>Cost</th>}
              <th className='order-list__status'>Status</th>
              <th className='order-list__action' />
            </tr>
          </thead>
          <tbody>
            {sortBy(groupOrders, go => go.dropoff)
              .reverse()
              .map(groupOrder => (
                <PastOrderRow
                  key={groupOrder.id}
                  groupOrder={groupOrder}
                  onClick={() => onClickRow(groupOrder)}
                  isAttendee={isAttendee}
                />
              ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default PastOrderList
