import React from 'react'
import { connect } from 'react-redux'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import OrderOverview, {
  DeliverSection,
  BackupMealSection
} from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import { newGroupOrder } from 'redux/selectors'
import { NEW_BACKUP_MEAL_PATH } from 'routes'

const MealBudgetSidebar = ({
  dropoff,
  attendeesCount,
  defaultMeals,
  currentStore,
  setDeliveryOptionsFormOpen,
  history
}) => {
  if (!(attendeesCount && currentStore && dropoff && defaultMeals)) {
    return null
  }

  return (
    <StickySidebar className='dfo-sidebar meal-budget-sidebar'>
      <OrderOverview>
        <DeliverSection
          dropoff={dropoff}
          attendeesCount={attendeesCount}
          onClickAction={() => setDeliveryOptionsFormOpen(true)}
        />
        <BackupMealSection
          storeName={currentStore.name}
          meals={defaultMeals}
          onClickAction={() => history.push(NEW_BACKUP_MEAL_PATH)}
        />
      </OrderOverview>

      <DfoSubmitButton label='Add Delivery Details' />
    </StickySidebar>
  )
}

const mapStateToProps = state => ({
  currentStore: newGroupOrder.selectStore(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  defaultMeals: newGroupOrder.selectDefaultMeal(state),
  dropoff: newGroupOrder.selectDropoff(state)
})

export default connect(mapStateToProps)(MealBudgetSidebar)
