import React, { Component } from 'react'
import { Formik } from 'formik'
import { withRouter } from 'react-router'
import { Action } from '@foodsby/nutrient'

import LoginForm from './LoginForm'

class Login extends Component {
  state = {
    showPassword: false
  }

  onToggleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword })

  onSubmit = async (values, actions) => {
    try {
      actions.setStatus(null)
      actions.setSubmitting(true)

      await this.props.onSubmit(values)
    } catch (error) {
      actions.setSubmitting(false)

      if (error && error.code === 400) {
        actions.setStatus('Invalid username or password')
        actions.setFieldError('username', ' ')
        actions.setFieldError('password', ' ')
      } else {
        actions.setStatus(
          'Something went wrong while logging in. Please contact support'
        )
      }
    }
  }

  render () {
    const { opened, onToggleForm } = this.props

    return (
      <div className='login'>
        {opened && (
          <>
            {onToggleForm && (
              <div className='login__header'>
                Log in
                <span className='mobile'> or Sign up</span>
              </div>
            )}
            <Formik
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                username: '',
                password: ''
              }}
            >
              {props => (
                <LoginForm
                  showPassword={this.state.showPassword}
                  onToggleShowPassword={this.onToggleShowPassword}
                  onToggleForm={onToggleForm}
                  {...props}
                />
              )}
            </Formik>
          </>
        )}

        {onToggleForm && !opened && (
          <span className='tablet-up'>
            <div className='login__instructions'>Foodsby member?</div>
            <div className='login__button-group'>
              <Action type='button' onClick={onToggleForm}>
                I already have an account
              </Action>
            </div>
          </span>
        )}
      </div>
    )
  }
}

export default withRouter(Login)
