import React, { Component, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Formik, Form } from 'formik'
import { format } from 'date-fns'
import map from 'lodash/map'
import get from 'lodash/get'
import { Modal, Spinner } from '@foodsby/nutrient'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import AbandonOrderPrompt from 'components/newOrderWorkflow/shared/AbandonOrderPrompt'
import CCAuthErrorModal from 'components/newOrderWorkflow/shared/ccAuthErrorModal/CCAuthErrorModal'
import RestaurantNotAvailableModal from 'components/newOrderWorkflow/shared/restaurantNotAvailableModal/RestaurantNotAvailableModal'
import CheckoutMain from './checkoutMain/CheckoutMain'
import CheckoutSidebar from './checkoutSidebar/CheckoutSidebar'
import { getPayments } from 'redux/modules/user'
import { getStores } from 'redux/modules/store'
import {
  recalculateTaxes,
  setBudget,
  setDropoffInstructions,
  calculateMealTaxes,
  setHasDateError,
  applyCoupon,
  setDeliveryOptionsFormOpen,
  setDeliveryOptionsInvalid
} from 'redux/modules/newGroupOrder'
import { createGroupOrder, setHasAuthError } from 'redux/modules/groupOrder'
import {
  newGroupOrder,
  selectNewGroupOrder,
  selectPayments,
  selectHasAuthError,
  selectCurrentUser
} from 'redux/selectors'
import { setNavAlert } from 'redux/modules/alerts'
import { SHARE_ORDER_PATH, STORES_PATH } from 'routes'
import { createSecureToken } from 'services/checkout'
import track, { CLICK_EVENT, CLICK_CHECKOUT } from 'services/tracking'
import { sumMealItems } from 'utils/order'
import { routeToHomeIfNoData } from 'higherOrderComponents'
import { CC_ERROR, INVALID_DELIVERY_DATE } from 'errors'
import { CHECKOUT_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'
import {
  INCOMPLETE_STEP_ALERT,
  buildIncompleteStepAlert
} from 'components/newOrderWorkflow/navAlerts/constants'

const OrderProcessingModal = ({ showModal }) => (
  <Modal
    className='modal--center order-processing-modal'
    showCancel={false}
    showModal={showModal}
  >
    <h1 className='dfo-h1'>We're processing your order</h1>
    <Spinner className='spinner-md' />
  </Modal>
)

const toCreateRequest = defaultMeal =>
  map(defaultMeal, meal => {
    const modifiers = map(meal.modifiers, q => {
      const answers = map(q.answers, a => ({ answerId: a.answerId }))
      return {
        questionId: q.questionId,
        depth: q.depth,
        answers
      }
    })

    return {
      menuItemId: meal.menuItemId,
      modifiers,
      specialInstructions: meal.specialInstructions
    }
  })

const CheckoutForm = props => {
  const { values, payments, hasAuthError, setHasAuthError } = props
  const { ccProfile } = values

  let lastFour
  if (!ccProfile.id && ccProfile.number) {
    lastFour = ccProfile.number.slice(-4)
  } else if (ccProfile.id && payments) {
    const profile = payments.find(
      payment => ccProfile.id === payment.ccProfileId
    )
    lastFour = profile.lastFour
  }

  useEffect(() => {
    // Workaround to validate form with initial values on mount. Formik has some issues around this:
    // https://github.com/jaredpalmer/formik/issues/1950
    props.validateForm()
  }, [])

  return (
    <Form style={{ height: '100%' }}>
      <OrderWorkflowLayout
        headerProps={{
          stepName: CHECKOUT_ORDER_STEP,
          onClickFutureStep: (step, isComplete) => {
            if (!props.isValid) {
              props.setNavAlert({
                name: INCOMPLETE_STEP_ALERT,
                messageOverride: buildIncompleteStepAlert(CHECKOUT_ORDER_STEP)
              })
            } else if (isComplete) {
              props.history.push(step.path)
            }
          },
          onClickNextStep: step => {
            if (!props.isValid) {
              props.setNavAlert({
                name: INCOMPLETE_STEP_ALERT,
                messageOverride: buildIncompleteStepAlert(CHECKOUT_ORDER_STEP)
              })
            } else {
              props.submitForm()
            }
          },
          showNextStepLabel: props.isValid
        }}
        mainComponent={<CheckoutMain {...props} />}
        sidebarComponent={<CheckoutSidebar {...props} />}
        inlineMobileSidebar
      />

      <CCAuthErrorModal
        showModal={hasAuthError}
        lastFour={lastFour}
        onClose={() => setHasAuthError(false)}
      />
    </Form>
  )
}

class CheckoutPage extends Component {
  state = {
    submitting: false,
    applyingDiscount: false,
    restaurantNotAvailable: false
  }

  componentDidMount () {
    this.props.getPayments()
    // TODO This should really just get a single store, not all of them
    this.props.getStores(this.props.locationId)
    this.props.recalculateTaxes()
  }

  componentDidUpdate (prevProps) {
    if (this.props.attendeesCount !== prevProps.attendeesCount) {
      this.props.recalculateTaxes()
    }
  }

  componentWillUnmount () {
    this.props.setHasAuthError(false)
    this.props.setHasDateError(false)
  }

  onApplyDiscount = async code => {
    const {
      currentUser,
      currentLocation,
      currentStore,
      dropoff,
      attendeesCount,
      budgetPerAttendeeInCents,
      defaultMeal
    } = this.props

    const mealCostInCents = sumMealItems(defaultMeal).intValue

    const body = {
      code,
      userId: currentUser.userId,
      locationId: currentLocation.deliveryLocationId,
      storeId: currentStore.id,
      actualFoodCostInCents: attendeesCount * mealCostInCents,
      budgetedFoodCostInCents: attendeesCount * budgetPerAttendeeInCents
    }

    this.setState({ applyingDiscount: true })
    const coupon = await this.props.applyCoupon(body)
    this.setState({ applyingDiscount: false })

    this.props.calculateMealTaxes(
      defaultMeal,
      currentLocation.deliveryLocationId,
      currentStore.id,
      dropoff,
      attendeesCount,
      budgetPerAttendeeInCents,
      coupon
    )

    return coupon
  }

  onSubmit = async (values, actions) => {
    const {
      history,
      dropoff,
      currentStore,
      attendeesCount,
      dropoffInstructions,
      budgetPerAttendeeInCents,
      noBudget,
      currentLocation,
      defaultMeal,
      coupon,
      setHasAuthError,
      setHasDateError,
      createGroupOrder,
      contactEmail,
      contactName,
      contactPhoneExtension: contactPhoneExt,
      contactPhoneNumber,
      suiteNumber,
      deliveryOptionsValid
    } = this.props

    if (!deliveryOptionsValid) {
      this.setState({ restaurantNotAvailable: true })
      return
    }

    let { ccProfile } = values
    const { setFieldError } = actions

    if (ccProfile.id === undefined) {
      try {
        ccProfile = {
          expMonth: ccProfile.expirationDate.slice(0, 2),
          expYear: ccProfile.expirationDate.slice(2),
          ...ccProfile
        }

        // new card: get secure-submit token from Heartland API
        ccProfile = await createSecureToken(ccProfile)
      } catch (err) {
        if (err && err.param === 'card.number') {
          setFieldError('ccProfile.number', err.message)
        } else if (err && err.param === 'card.exp_year') {
          setFieldError('ccProfile.expirationDate', err.message)
        } else {
          console.error(err)
          throw err
        }
        return
      }
    } else {
      // existing cards only require the 'id' field to be set:
      ccProfile = {
        id: ccProfile.id
      }
    }

    const couponCode = coupon && coupon.valid ? coupon.code : null

    const newGroupOrder = {
      dropoff: format(dropoff, "yyyy-MM-dd'T'HH:mm:ss"),
      storeId: currentStore.id,
      locationId: currentLocation.deliveryLocationId,
      defaultMealItems: toCreateRequest(defaultMeal),
      attendeesCount,
      budgetPerAttendeeInCents: noBudget ? null : budgetPerAttendeeInCents,
      dropoffInstructions,
      ccProfile,
      couponCode,
      contactName,
      contactEmail,
      contactPhoneNumber,
      contactPhoneExt,
      suiteNumber
    }

    try {
      this.setState({ submitting: true })
      setHasAuthError(false)

      const groupOrder = await createGroupOrder(newGroupOrder)

      history.push(`${SHARE_ORDER_PATH}?groupOrderId=${groupOrder.id}`)
    } catch (err) {
      if (err && err.code === 400 && err.message === INVALID_DELIVERY_DATE) {
        setHasDateError(true)
      } else if (
        err &&
        get(err, 'data.validationErrors[0].code') === CC_ERROR
      ) {
        setHasAuthError(true)
        // if existing payment card failed, reset the new credit card form
        if (ccProfile.id) {
          actions.resetForm({
            values: {
              ccProfile: {
                id: ccProfile.id,
                saveCard: false,
                number: '',
                cvv: '',
                expirationDate: '',
                zipCode: '',
                firstName: '',
                lastName: ''
              }
            }
          })
        }
      } else {
        throw err
      }
    } finally {
      this.setState({ submitting: false })
    }

    track({
      category: CLICK_EVENT,
      action: CLICK_CHECKOUT
    })
  }

  render () {
    const {
      currentUser,
      currentStore,
      payments,
      setDeliveryOptionsFormOpen,
      setDeliveryOptionsInvalid,
      history
    } = this.props
    const { submitting } = this.state

    // pre-select first payment
    // if user has no payments, then select the 'Add New Card' form
    const initialSelectedPaymentId = get(payments, '[0]ccProfileId')

    return (
      <DfoLayout currentUser={currentUser}>
        {payments && (
          <Formik
            initialValues={{
              ccProfile: {
                id: initialSelectedPaymentId,
                saveCard: false,
                number: '',
                cvv: '',
                expirationDate: '',
                zipCode: '',
                firstName: '',
                lastName: ''
              }
            }}
            onSubmit={this.onSubmit}
            validateOnBlur={false}
            validateOnChange
          >
            {formikProps => (
              <CheckoutForm
                {...formikProps}
                {...this.props}
                onApplyDiscount={this.onApplyDiscount}
              />
            )}
          </Formik>
        )}

        <OrderProcessingModal showModal={submitting} />

        <RestaurantNotAvailableModal
          showModal={this.state.restaurantNotAvailable}
          onModify={() => {
            setDeliveryOptionsFormOpen(true)
            setDeliveryOptionsInvalid(true)
            this.setState({ restaurantNotAvailable: false })
          }}
          onViewAll={() => history.push(STORES_PATH)}
          maxCount={currentStore && currentStore.maxHeadCount}
        />

        <AbandonOrderPrompt />
      </DfoLayout>
    )
  }
}

CheckoutPage = routeToHomeIfNoData(CheckoutPage)

CheckoutPage = withRouter(CheckoutPage)

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  newGroupOrder: selectNewGroupOrder(state),
  contactName: newGroupOrder.selectContactName(state),
  contactPhoneNumber: newGroupOrder.selectContactPhoneNumber(state),
  contactPhoneExtension: newGroupOrder.selectContactPhoneExtension(state),
  contactEmail: newGroupOrder.selectContactEmail(state),
  suiteNumber: newGroupOrder.selectSuiteNumber(state),
  payments: selectPayments(state),
  dropoffInstructions: newGroupOrder.selectDropoffInstructions(state),
  budgetPerAttendeeInCents: newGroupOrder.selectBudgetPerAttendeeInCents(state),
  noBudget: newGroupOrder.selectNoBudget(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  locationId: newGroupOrder.selectLocationId(state),
  currentLocation: newGroupOrder.selectLocation(state),
  currentStore: newGroupOrder.selectStore(state),
  defaultMeal: newGroupOrder.selectDefaultMeal(state),
  dropoff: newGroupOrder.selectDropoff(state),
  hasAuthError: selectHasAuthError(state),
  taxes: newGroupOrder.selectTaxes(state),
  coupon: newGroupOrder.selectCoupon(state),
  deliveryOptionsValid: newGroupOrder.selectDeliveryOptionsValid(state)
})

const mapDispatchToProps = {
  getPayments,
  getStores,
  recalculateTaxes,
  setBudget,
  setDropoffInstructions,
  createGroupOrder,
  calculateMealTaxes,
  setHasAuthError,
  setHasDateError,
  setNavAlert,
  applyCoupon,
  setDeliveryOptionsFormOpen,
  setDeliveryOptionsInvalid
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutPage)
