import { logException } from 'utils/errorUtils'

const HEARTLAND_ERROR =
  'Your network is preventing us from loading a necessary resource. Please email us at foodsbytesupport@foodsby.com.'

export const createSecureToken = ccProfile => {
  return new Promise((resolve, reject) => {
    if (!window.Heartland) {
      const ex = new Error(HEARTLAND_ERROR)
      logException(ex)
      throw ex
    }

    const {
      number,
      cvv,
      expMonth,
      expYear,
      zipCode,
      saveCard,
      firstName,
      lastName
    } = ccProfile

    const hps = new window.Heartland.HPS({
      publicKey: process.env.REACT_APP_HEARTLAND_PUBLIC_KEY,
      cardNumber: number,
      cardCvv: cvv,
      cardExpMonth: expMonth.toString(),
      cardExpYear: expYear.toString(),
      success: resp => {
        /* eslint-disable camelcase */
        const { token_value, last_four, exp_month, exp_year } = resp

        const request = {
          id: null,
          zipCode,
          saveCard,
          firstName,
          lastName,
          token: token_value,
          lastFour: last_four,
          expMonth: exp_month,
          expYear: exp_year
        }
        /* eslint-enable camelcase */
        resolve(request)
      },
      error: resp => {
        logException(
          `Error processing Heartland token request: ${resp.error.message}`
        )
        reject(resp.error)
      }
    })
    hps.tokenize()
  })
}
