import React from 'react'
import isEmpty from 'lodash/isEmpty'

import MessageBar from 'components/newOrderWorkflow/shared/MessageBar'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import { ReactComponent as AttendeesIcon } from 'assets/dfo/icon--attendees-alt.svg'
import { toDisplayFirstName } from 'utils/user'
import { formatTime, formatDateLongWithoutYear } from 'utils/datetime'
import { fromCents } from 'utils/money'

const AddedMealItem = ({ index, onClick, mealItem }) => (
  <div key={index} className='added-meal-item'>
    <div className='added-meal-item__item'>
      <div className='added-meal-item__item-name'>{mealItem.name}</div>
      <div className='added-meal-item__item-price'>
        {fromCents(mealItem.totalPriceInCents).format()}
      </div>
    </div>
    <DfoAction className='added-meal-item__details-button' onClick={onClick}>
      + Show Details
    </DfoAction>
  </div>
)

const AttendeeMealMobileHeader = ({
  addedMeals,
  saveMeal,
  toggleMobileSidebar,
  overbudget,
  budget,
  submitting
}) => {
  const hasBudget = !!budget
  return (
    <div className='attendee-meal-mobile-header'>
      <div className='attendee-meal-mobile-header__title'>Your Meal Order</div>
      {addedMeals.map((addedMeal, index) => (
        <AddedMealItem
          key={index}
          mealItem={addedMeal}
          onClick={toggleMobileSidebar}
        />
      ))}

      {hasBudget && overbudget && (
        <AlertErrorMessage>
          <b>You’re over budget.</b> Please update your order to be{' '}
          <b>{fromCents(budget).format()}</b> or less.
        </AlertErrorMessage>
      )}

      <RoundButton
        className='primary-alt'
        label='Save Meal'
        submitting={submitting}
        onClick={() => saveMeal(addedMeals)}
      />
    </div>
  )
}

const AttendeeMealPageHeader = ({
  groupOrder,
  addedMeals,
  saveMeal,
  toggleMobileSidebar,
  overbudget,
  budget,
  submitting
}) => (
  <>
    <header className='attendee-meal-page-header'>
      <div className='attendee-meal-page-header__description'>
        <AttendeesIcon />
        <span>
          {toDisplayFirstName(groupOrder.owner)}'s group order on{' '}
          {formatDateLongWithoutYear(groupOrder.dropoff)} at{' '}
          {formatTime(groupOrder.dropoff)}
        </span>
      </div>

      <MessageBar center>
        <AlertErrorMessage>
          This order will lock at <b>{formatTime(groupOrder.cutoff)}</b> on{' '}
          <b>{formatDateLongWithoutYear(groupOrder.cutoff)}</b>
        </AlertErrorMessage>
      </MessageBar>
    </header>
    {!isEmpty(addedMeals) && (
      <AttendeeMealMobileHeader
        addedMeals={addedMeals}
        saveMeal={saveMeal}
        toggleMobileSidebar={toggleMobileSidebar}
        submitting={submitting}
        overbudget={overbudget}
        budget={budget}
      />
    )}
  </>
)

export default AttendeeMealPageHeader
