import React, { Component } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import MenuMain from './menuMain/MenuMain'
import {
  getMenu,
  getMenuItem,
  setMenuItem,
  setMeal,
  clearMeal,
  addMealItem,
  removeMealItem,
  replaceMealItem,
} from 'redux/modules/menu'
import { selectMeal, selectMenu } from 'redux/selectors'

class MenuPage extends Component {
  state = {
    menuLoading: true,
    mealItem: null,
    mealItemIndex: null,
  }

  componentDidMount () {
    this.props.setMeal(this.props.initialMeal)

    if (this.props.currentStore) {
      this.loadMenu()
    }
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.currentStore &&
      this.props.currentStore.monolithId !== get(prevProps.currentStore, 'monolithId')
    ) {
      this.loadMenu()
    }

    if (!isEqual(this.props.initialMeal, prevProps.initialMeal)) {
      this.props.setMeal(this.props.initialMeal)
    }
  }

  async loadMenu () {
    await this.props.getMenu(this.props.currentStore.monolithId)
    this.setState({ menuLoading: false })
  }

  saveMealItem = mealItem => {
    this.state.mealItemIndex != null ? this.replaceMealItem(mealItem) : this.addToMeal(mealItem)
  }

  replaceMealItem = mealItem => {
    const { mealItemIndex } = this.state
    this.props.replaceMealItem({ mealItem, mealItemIndex })
    this.setState({ mealItemIndex: null, mealItem: null })
  }

  onRemove = idx => {
    this.props.removeMealItem(idx)
  }

  addToMeal = mealItem => {
    this.props.addMealItem(mealItem)
  }

  onEdit = index => {
    const mealItem = this.props.meal[index]
    this.props.getMenuItem(mealItem.menuItemId)
    this.setState({
      mealItem,
      mealItemIndex: index,
    })
  }

  unselectMenuItem = () => {
    this.props.setMenuItem(null)
    this.setState({
      mealItem: null,
      mealItemIndex: null,
    })
  }

  render () {
    const {
      meal,
      currentStore,
      groupOrder,
      isValid,
      submitting,
      submitAttempted,
      isBackupMeal,
      onSelectMenuItem,
      saveMeal,
      layoutComponent,
      menuHeaderComponent,
      sidebarComponent,
      attendee,
    } = this.props

    const { mealItem, menuLoading } = this.state

    const menuHeader = props =>
      menuHeaderComponent({
        onEdit: this.onEdit,
        onRemove: this.onRemove,
        meal,
        ...props,
      })

    const main = (
      <MenuMain
        loading={menuLoading}
        isBackupMeal={isBackupMeal}
        groupOrder={groupOrder}
        currentStore={currentStore}
        mealItem={mealItem}
        saveMealItem={this.saveMealItem}
        onSelectMenuItem={onSelectMenuItem}
        unselectMenuItem={this.unselectMenuItem}
        saveMeal={saveMeal}
        menuHeaderComponent={menuHeader}
        attendee={attendee}
      />
    )

    const sidebar = sidebarComponent({
      meal,
      groupOrder,
      isValid,
      submitting,
      submitAttempted,
      saveMeal,
      onEdit: this.onEdit,
      onRemove: this.onRemove,
    })

    const layout = layoutComponent({
      mainComponent: main,
      sidebarComponent: sidebar,
    })

    return layout
  }
}

const mapStateToProps = state => ({
  meal: selectMeal(state),
  menu: selectMenu(state),
})

const mapDispatchToProps = {
  getMenu,
  getMenuItem,
  setMenuItem,
  setMeal,
  clearMeal,
  addMealItem,
  removeMealItem,
  replaceMealItem,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MenuPage)
