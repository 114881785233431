import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { withRouter } from 'react-router'
import classnames from 'classnames'
import PlacesAutocomplete from 'react-places-autocomplete'
import truncate from 'lodash/truncate'
import { Modal } from '@foodsby/nutrient'

import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import DfoFormField from 'components/newOrderWorkflow/dfoFormField/DfoFormField'
import RoundTextInput from 'components/newOrderWorkflow/roundTextInput/RoundTextInput'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import { ReactComponent as LocationIconActive } from 'assets/dfo/icon--location--active.svg'
import { ReactComponent as LocationIconOutline } from 'assets/dfo/icon--location--outline.svg'
import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import {
  newGroupOrder,
  selectCurrentUser,
  selectLocation,
  selectGoLocationId,
  selectSearchedAddress
} from 'redux/selectors'
import { setLocationFromAddress } from 'redux/modules/location'
import { formatAddress } from 'utils/formatters'
import { STORES_FOR_LOCATION_PATH, STORES_FOR_NO_LOCATION_PATH } from 'routes'

export const getHighlightedText = (text, highlight) => {
  const highlightParts = highlight.split(' ')
  const parts = truncate(text, { length: 65 }).split(' ')

  return (
    <span>
      {parts &&
        parts.map((part, i) => (
          <span
            key={i}
            style={
              highlightParts &&
              highlightParts.some(
                highlight =>
                  highlight &&
                  part
                    .replace(/[^a-z0-9]/gi, '')
                    .toLowerCase()
                    .indexOf(
                      highlight.toLowerCase().replace(/[^a-z0-9]/gi, '')
                    ) > -1
              )
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {`${part} `}
          </span>
        ))}
    </span>
  )
}

function LocationSearch ({
  trackChange,
  currentLocation,
  searchedAddress,
  setLocationFromAddress,
  history
}) {
  const [currentAddress, setAddress] = useState(searchedAddress)

  useEffect(() => {
    setAddress(formatAddress(currentLocation))
  }, [currentLocation])

  const [showLocationConfirmation, setShowLocationConfirmation] = useState(
    false
  )
  const [ignoreBlur, setIgnoreBlur] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const closeDropdownRef = useRef()

  const onSelectLocation = address => {
    setIgnoreBlur(true)
    closeDropdownRef.current.click()
    setAddress(address)
    setShowLocationConfirmation(true)
  }

  const confirmNewLocation = async () => {
    trackChange()
    setModalLoading(true)
    const locationId = await setLocationFromAddress(currentAddress)
    setModalLoading(false)
    setShowLocationConfirmation(false)
    setIgnoreBlur(false)

    if (locationId) {
      history.push(formatRoute(STORES_FOR_LOCATION_PATH, { locationId }))
    } else {
      history.push(STORES_FOR_NO_LOCATION_PATH)
    }
  }

  const cancelNewLocation = () => {
    setIgnoreBlur(false)
    setAddress(formatAddress(currentLocation))
    setShowLocationConfirmation(false)
  }

  return (
    <>
      <PlacesAutocomplete
        value={currentAddress || ''}
        onChange={address => setAddress(address)}
        highlightFirstSuggestion
        onSelect={onSelectLocation}
        onError={err => {
          if (err !== 'ZERO_RESULTS') {
            throw err
          }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          const LocationSearchDropdownContent = () => {
            return (
              <div className='delivery-options-dropdown'>
                <div className='delivery-options-dropdown__empty'>
                  {suggestions.length === 0 && (
                    <span>Enter a valid address</span>
                  )}
                </div>
                {suggestions.length > 0 &&
                  suggestions.map((suggestion, i) => {
                    const className = classnames(
                      'delivery-options-dropdown__suggestion',
                      {
                        'delivery-options-dropdown__suggestion--active':
                          suggestion.active
                      }
                    )
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                        key={i}
                      >
                        {getHighlightedText(
                          suggestion.description,
                          currentAddress
                        )}
                      </div>
                    )
                  })}
                {suggestions.length > 0 && (
                  <div
                    className='location-search__google-attr'
                    style={{ paddingLeft: 25 }}
                  >
                    <img
                      src='https://images.foodsby.com/third-party/powered_by_google_on_white.png'
                      alt='Powered by Google'
                    />
                  </div>
                )}
              </div>
            )
          }

          return (
            <DfoFormField
              containerProps={{
                onBlur: () =>
                  !ignoreBlur && setAddress(formatAddress(currentLocation))
              }}
              id='location'
              name='location'
              className='delivery-options__location'
              label='Lunch at'
              placeholder='123 Spaghetti Street'
              autoComplete='off'
              component={RoundTextInput}
              iconPlacement='left'
              ActiveIcon={LocationIconActive}
              InactiveIcon={LocationIconOutline}
              showDropdown={true}
              DropdownChildren={LocationSearchDropdownContent}
              onClick={e => e.target.select()}
              closeRef={closeDropdownRef}
              {...getInputProps()}
              tabIndex='0'
            />
          )
        }}
      </PlacesAutocomplete>

      <Modal
        showModal={showLocationConfirmation}
        showCancel={false}
        className='location-confirmation'
      >
        <h3>
          <AlertErrorIcon />
          Are you sure?
        </h3>
        <p>
          By changing location, your in-progress order will be lost. Your
          restaurant options may also change.
        </p>
        <div className='location-confirmation__actions'>
          <DfoAction className='no-btn' onClick={cancelNewLocation}>
            No, Cancel
          </DfoAction>

          <RoundButton
            className='destructive yes-btn'
            onClick={confirmNewLocation}
            submitting={modalLoading}
          >
            Yes, Change Location
          </RoundButton>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  currentLocation: selectLocation(state),
  goLocationId: selectGoLocationId(state),
  newGroupOrderLocationId: newGroupOrder.selectLocationId(state),
  searchedAddress: selectSearchedAddress(state)
})

const mapDispatchToProps = {
  setLocationFromAddress
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LocationSearch))
