import React from 'react'

import DeliveryDetails from 'components/newOrderWorkflow/deliveryDetails/DeliveryDetails'

const DeliveryDetailsMain = ({
  setContactInfo,
  setDropoffInstructions,
  ...rest
}) => (
  <div className='delivery-details-main'>
    <div className='delivery-details-main__content'>
      <h1 className='dfo-h1'>How should we reach you?</h1>

      <DeliveryDetails
        setContactInfo={setContactInfo}
        setDropoffInstructions={setDropoffInstructions}
        contactPhoneNumberLabel='at'
        suiteNumberLabel='Deliver to suite number'
        dropoffInstructionsLabel='Delivery instructions for the driver'
        {...rest}
      />
    </div>
  </div>
)

export default DeliveryDetailsMain
