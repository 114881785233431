import React from 'react'

import isEmpty from 'lodash/isEmpty'
import { STORES_PATH } from 'routes'

/*
 * If the user happens to land on a route without going throught the necessary steps to create an order,
 * then redirect them back to the start
 */
export const routeToHomeIfNoData = WrappedComponent => props => {
  if (!isEmpty(props.newGroupOrder)) {
    return <WrappedComponent {...props} />
  } else {
    props.history.push(STORES_PATH)
    return null
  }
}
