import React from 'react'

import { fromDollars } from 'utils/money'

const MenuItem = ({ menuItem, onClick }) => {
  const { name, description, price } = menuItem

  return (
    <div className='menu-item' onClick={onClick}>
      <div className='menu-item__header'>
        <div className='menu-item__name'>{name}</div>
        <div className='menu-item__price'>{fromDollars(price).format()}</div>
      </div>

      {description && (
        <div className='menu-item__description'>{description}</div>
      )}
    </div>
  )
}

export default MenuItem
