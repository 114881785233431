import React from 'react'
import classnames from 'classnames'
import Tooltip from 'rc-tooltip'

import { ReactComponent as InfoIcon } from 'assets/icon--info.svg'

const Alert = ({
  type = 'info',
  title,
  iconTooltip,
  className,
  onClose,
  children
}) => (
  <div className={classnames('alert', `alert__${type}`, className)}>
    {type === 'info' && (
      <div className='alert__icon'>
        {iconTooltip && (
          <Tooltip
            overlay={<span>{iconTooltip}</span>}
            overlayClassName='go-tooltip'
            placement='top'
            transitionName='tooltip'
          >
            <InfoIcon className='icon tooltip' />
          </Tooltip>
        )}
        {!iconTooltip && <InfoIcon />}
      </div>
    )}
    <div className='alert__content'>
      {onClose && (
        <div className='alert__close' onClick={onClose}>
          &times;
        </div>
      )}
      <div className='alert__body'>{children}</div>
    </div>
  </div>
)

export default Alert
