import React from 'react'

import MenuItem from './menuItem/MenuItem'
import MenuItemModal from 'components/newOrderWorkflow/menuItemModal/MenuItemModal'
import track, { CLICK_EVENT, CLICK_MENU_ITEM } from 'services/tracking'

const TopLevelMenuItem = ({ subMenu, onClickMenuItem }) => (
  <div className='top-level-menu' key={subMenu.id}>
    <div className='top-level-menu__header'>
      <div className='anchor' id={subMenu.id} />

      <h1 className='dfo-h1'>{subMenu.name}</h1>

      {subMenu.description && (
        <div className='top-level-menu__description'>{subMenu.description}</div>
      )}
    </div>

    <div className='top-level-menu__list'>
      {subMenu.menuItems.map(menuItem => (
        <MenuItem
          key={menuItem.id}
          menuItem={menuItem}
          onClick={() => onClickMenuItem(menuItem.id)}
        />
      ))}
    </div>
  </div>
)

const MenuItems = ({ menu, onClickMenuItem }) => {
  const handleClickMenuItem = id => {
    onClickMenuItem(id)
    track({
      category: CLICK_EVENT,
      action: CLICK_MENU_ITEM,
    })
  }

  return (
    <>
      {(menu.subMenus || []).map((subMenu, i) => (
        <TopLevelMenuItem key={i} subMenu={subMenu} onClickMenuItem={handleClickMenuItem} />
      ))}
    </>
  )
}

const Menu = ({
  menu,
  onSelectMenuItem,
  selectedMenuItem,
  saveMealItem,
  mealItem,
  unselectMenuItem,
  isBackupMeal,
  attendee,
}) => (
  <div className='menu'>
    <MenuItems menu={menu} onClickMenuItem={onSelectMenuItem} />
    <MenuItemModal
      isBackupMeal={isBackupMeal}
      menuItem={selectedMenuItem}
      saveMealItem={saveMealItem}
      mealItem={mealItem}
      unselectMenuItem={unselectMenuItem}
      onCancel={unselectMenuItem}
      attendee={attendee}
    />
  </div>
)

export default Menu
