import React from 'react'
import times from 'lodash/times'

import { ReactComponent as Circle } from 'assets/circle.svg'

const LoadingDots = ({ count = 5 }) => (
  <div className='loading-dots'>
    {times(count, i => (
      <Circle key={i} />
    ))}
  </div>
)

export default LoadingDots
