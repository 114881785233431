import React from 'react'
import { connect } from 'react-redux'
import Tooltip from 'rc-tooltip'

import LineItem, {
  LineItemCoupon,
  LineItemTotal
} from 'components/lineItem/LineItem'
import { ReactComponent as InfoIcon } from 'assets/dfo/icon--info.svg'
import { selectConfig } from 'redux/selectors'
import { fromCentsNoSymbol } from 'utils/money'
import { getCutoff, formatDateTimeAsParts } from 'utils/datetime'

const MealTotalTooltip = ({
  attendeesCount,
  costPerAttendee,
  noBudget,
  mealTotal
}) => {
  const overlay = (
    <div className='meal-total-overlay'>
      <div className='meal-total-overlay__block'>
        <div className='meal-total-overlay__value'>
          <span className='meal-total-overlay__currency'>$</span>
          <span>{fromCentsNoSymbol(costPerAttendee).format()}</span>
        </div>
        <div className='meal-total-overlay__label'>
          {noBudget ? 'Backup Meal' : 'Budget'}
        </div>
      </div>

      <div className='meal-total-overlay__block'>
        <span className='meal-total-overlay__operator'>&times;</span>
      </div>

      <div className='meal-total-overlay__block'>
        <div>{attendeesCount}</div>
        <div className='meal-total-overlay__label'>People</div>
      </div>

      <div className='meal-total-overlay__block'>
        <span className='meal-total-overlay__operator'>=</span>
      </div>

      <div className='meal-total-overlay__block'>
        <div>
          <span className='meal-total-overlay__currency'>$</span>
          <span>{fromCentsNoSymbol(mealTotal.intValue).format()}</span>
        </div>
        <div className='meal-total-overlay__label'>est. Total</div>
      </div>
    </div>
  )

  return (
    <Tooltip
      overlay={overlay}
      placement='bottom'
      transitionName='tooltip'
      overlayClassName='dfo-tooltip'
    >
      <InfoIcon />
    </Tooltip>
  )
}

const MinOrderFeeTooltip = () => {
  const overlay = (
    <div className='min-order-fee-overlay'>
      Assessed if the estimated meal total is less than the order minimum for
      the restaurant you selected.
    </div>
  )

  return (
    <Tooltip
      overlay={overlay}
      placement='bottom'
      transitionName='tooltip'
      overlayClassName='dfo-tooltip'
    >
      <InfoIcon />
    </Tooltip>
  )
}

const OrderTotal = ({
  attendeesCount,
  deliveryFee,
  coupon,
  costPerAttendee,
  noBudget,
  mealTotal,
  couponDiscount,
  orderFee,
  taxes,
  total,
  dropoff,
  orderCutoffOffset,
  orderComplete,
  hideMealTotalTooltip
}) => {
  if (!dropoff || !orderCutoffOffset) {
    return null
  }

  const cutoff = getCutoff(dropoff, orderCutoffOffset)
  const cutoffDateParts = formatDateTimeAsParts(cutoff)

  return (
    <div className='estimated-total'>
      <div className='estimated-total__title'>Order Summary</div>

      <LineItem
        name={orderComplete ? 'Meal Total' : 'Estimated Meal Total'}
        tooltip={
          !hideMealTotalTooltip && (
            <MealTotalTooltip
              attendeesCount={attendeesCount}
              costPerAttendee={costPerAttendee}
              noBudget={noBudget}
              mealTotal={mealTotal}
            />
          )
        }
        price={mealTotal}
      />

      <LineItem
        name='Minimum Order Fee'
        price={orderFee}
        tooltip={<MinOrderFeeTooltip />}
      />

      <LineItem name='Delivery Fee' price={deliveryFee} />

      {coupon && coupon.valid && couponDiscount && (
        <LineItemCoupon code={coupon.code} discount={couponDiscount} />
      )}

      <LineItem name='Tax' price={taxes} />

      <LineItemTotal
        name={orderComplete ? 'Total' : 'Est. Total'}
        price={total}
      />

      {!orderComplete && (
        <div className='estimated-total__legal'>
          The total cost of this order will change as attendees make their meal
          selections.{' '}
          <b>
            A final total will be available when the order locks at{' '}
            {cutoffDateParts.time} {cutoffDateParts.meridiem} on{' '}
            {cutoffDateParts.date}.
          </b>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  orderCutoffOffset: selectConfig(state).orderCutoffOffsetInHours
})

export default connect(mapStateToProps)(OrderTotal)
