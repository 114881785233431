export const ORDER_PAST_CUTOFF = 'ORDER_PAST_CUTOFF'
export const INVALID_DELIVERY_DATE = 'INVALID_DELIVERY_DATE'
export const ALL_ATTENDEE_SLOTS_TAKEN = 'ALL_ATTENDEE_SLOTS_TAKEN'
export const CC_ERROR = 'CC_ERROR'

// TODO: consider each of these potential errors and display error messaging accordingly
// Credit card errors
export const INVALID_INFORMATION_FOR_EXISTING_CARD =
  'INVALID_INFORMATION_FOR_EXISTING_CARD'
export const PAYMENT_METHOD_NOT_FOUND = 'PAYMENT_METHOD_NOT_FOUND'
export const PROBLEM_SAVING_CARD = 'PROBLEM_SAVING_CARD'
export const INSUFFICIENT_CREDIT_CARD_DATA = 'INSUFFICIENT_CREDIT_CARD_DATA'

// Error messaging
export const MESSAGE_PROCESSING_ERROR =
  'There was an error processing your card, please use a different card.'
