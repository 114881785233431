import React from 'react'
import MediaQuery from 'react-responsive'
import sumBy from 'lodash/sumBy'

import MeetingDetails from 'components/meetingDetails/MeetingDetails'
import SidebarLineItem from 'components/sidebarLineItem/SidebarLineItem'
import MealItemList from 'components/mealItemList/MealItemList'
import SupportForm from 'components/supportForm/SupportForm'
import { fromCents } from 'utils/money'
import { isEditable, isCancelled, sumMealItems } from 'utils/order'
import { isLoggedIn } from 'utils/user'

const AttendeeMealStatusSidebar = ({
  currentUser,
  currentLocation,
  groupOrder,
  meal,
  attendee,
  meetingDetailsHeaderText
}) => {
  if (!groupOrder) {
    return null
  }

  const { budgetPerAttendeeInCents } = groupOrder
  const totalPrice = sumBy(meal, 'totalPriceInCents')
  const remainingBalance = budgetPerAttendeeInCents
    ? budgetPerAttendeeInCents - totalPrice
    : undefined

  return (
    <div className='attendee-meal-status-sidebar'>
      <MeetingDetails
        groupOrder={groupOrder}
        location={currentLocation}
        headerText={meetingDetailsHeaderText}
        cutoff={groupOrder.cutoff}
        dropoff={groupOrder.dropoff}
        hideAttendeesCount
      />

      {!isCancelled(groupOrder) && meal && (
        <section className='attendee-meal-status-sidebar__meal-details'>
          <h3>Your Meal</h3>

          <SidebarLineItem
            className='attendee-meal-status-sidebar__total mobile'
            label='Your Meal Total'
            value={sumMealItems(meal).format()}
          />

          <MealItemList meal={meal} readOnly />

          <SidebarLineItem
            className='attendee-meal-status-sidebar__total tablet-up'
            label='Meal Total'
            value={sumMealItems(meal).format()}
          />

          {isEditable(groupOrder) && remainingBalance && (
            <SidebarLineItem
              className='attendee-meal-status-sidebar__remaining'
              label='Remaining Meal Budget'
              value={fromCents(remainingBalance).format()}
            />
          )}
        </section>
      )}

      <MediaQuery query='(min-width: 769px)'>
        <SupportForm
          showLink={!isLoggedIn(currentUser) || !attendee}
          groupOrder={groupOrder}
          attendee={attendee}
        />
      </MediaQuery>
    </div>
  )
}

export default AttendeeMealStatusSidebar
