import React from 'react'
import classnames from 'classnames'
import noop from 'lodash/noop'

const OrderWorkflowButton = ({
  step,
  iconActive,
  iconDisabled,
  disabled,
  showLabel,
  label,
  onClick = noop,
  onMouseDown = noop,
  onMouseUp = noop
}) => {
  let Icon

  if (iconActive) {
    Icon = step.iconActive
  } else if (iconDisabled) {
    Icon = step.iconDisabled
  } else {
    Icon = step.icon
  }

  const icon = (
    <div
      className={classnames(
        'order-workflow-button__icon',
        `order-workflow-button__icon--${step.name}`,
        {
          'order-workflow-button__icon--icon-active': iconActive
        }
      )}
    >
      <div className='order-workflow-button__icon-background-circle' />
      {Icon && <Icon />}
    </div>
  )

  return (
    <>
      <button
        type='button'
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchEnd={onMouseUp}
        className={classnames('order-workflow-button', {
          'order-workflow-button--icon-active': iconActive,
          'order-workflow-button--icon-disabled': iconDisabled
        })}
        disabled={disabled}
      >
        {icon}
        <div
          className={classnames('order-workflow-button__border', {
            'order-workflow-button__border--icon-active': iconActive
          })}
        >
          &nbsp;
        </div>
      </button>
      {showLabel && iconActive && (
        <div
          className={classnames('order-workflow-step-label', {
            'order-workflow-header__step-label--hidden': showLabel
          })}
        >
          {label}
        </div>
      )}
    </>
  )
}

export default OrderWorkflowButton
