import React from 'react'
import classnames from 'classnames'
import { Action } from '@foodsby/nutrient'

const DfoAction = ({ children, className, ...rest }) => (
  <Action className={classnames('dfo-action', className)} {...rest}>
    {children}
  </Action>
)

export default DfoAction
