import React, { Component } from 'react'
import flatMap from 'lodash/flatMap'

import logo from 'assets/foodsby-logo-menu.png'
import { loadGroupOrder, loadLocation } from 'api'
import { formatDateShort } from 'utils/datetime'
import { toDisplayName } from 'utils/user'
import { fromCents } from 'utils/money'

const OrderDetails = ({ location, order }) => (
  <div className='group-order-print-page__order-details'>
    <h3>Order Details</h3>
    <dl>
      <dt>Delivery Address</dt>
      <dd>{location.street}</dd>
      <dt>Delivery Date</dt>
      <dd>{formatDateShort(order.dropoff)}</dd>
      <dt>Order Number</dt>
      <dd>{order.id}</dd>
      <dt>Attendees</dt>
      <dd>{order.attendees.length}</dd>
      <dt>Created By</dt>
      <dd>{toDisplayName(order.owner)}</dd>
      <dt>Created On</dt>
      <dd>{formatDateShort(order.createdAt)}</dd>
    </dl>
  </div>
)

const OrderSummary = ({ order }) => (
  <div className='group-order-print-page__summary'>
    <h3>Order Summary</h3>
    <table className='group-order-print-page__payment-table'>
      <tbody>
        <tr className='group-order-print-page__payment-method'>
          <td>Payment Method</td>
          <td>Card ending in {order.pricing.profileData.lastFour}</td>
        </tr>
        {flatMap(order.attendees, 'meals').map((meal, index) => (
          <tr key={index}>
            <td>{meal.name}</td>
            <td>{fromCents(meal.totalPriceInCents).format()}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>Meal Total</td>
          <td>
            {fromCents(order.pricing.actualCost.foodCostInCents).format()}
          </td>
        </tr>
        <tr>
          <td>Minimum Order Fee</td>
          <td>
            {fromCents(
              order.pricing.actualCost.storeMinimumFeeInCents
            ).format()}
          </td>
        </tr>
        <tr>
          <td>Delivery Fee</td>
          <td>
            {fromCents(order.pricing.actualCost.deliveryFeeInCents).format()}
          </td>
        </tr>
        <tr>
          <td>Taxes</td>
          <td>
            {fromCents(order.pricing.actualCost.totalTaxesInCents).format()}
          </td>
        </tr>
        {order.coupon && (
          <tr>
            <td>Coupon {order.coupon.code}</td>
            <td>
              {'-'}
              {fromCents(
                order.pricing.actualCost.couponDiscountInCents
              ).format()}
            </td>
          </tr>
        )}
        <tr className='group-order-print-page__order-total'>
          <td>Order Total</td>
          <td>{fromCents(order.pricing.actualCost.totalInCents).format()}</td>
        </tr>
      </tfoot>
    </table>
  </div>
)

class GroupOrderPrintReceipt extends Component {
  state = {
    groupOrder: null,
    location: null
  }

  async componentDidMount () {
    const groupOrder = await loadGroupOrder(this.props.groupOrderId)
    const location = await loadLocation(groupOrder.monolithLocationId)
    this.setState({ groupOrder, location })
  }

  render () {
    const { groupOrder, location } = this.state

    if (!groupOrder || !location) {
      return null
    }

    return (
      <div className='group-order-print-page'>
        <img src={logo} alt='Foodsby' />
        <h1 className='group-order-print-page__store-name'>
          {groupOrder.store.name}
        </h1>
        <OrderDetails location={location} order={groupOrder} />
        <OrderSummary order={groupOrder} />
      </div>
    )
  }
}

const GroupOrderPrintPage = ({ match }) => (
  <GroupOrderPrintReceipt groupOrderId={match.params.groupOrderId} />
)

export default GroupOrderPrintPage

export { GroupOrderPrintReceipt }
