// Internal: React routes
export const GROUP_ORDER_PATH = '/group-order'
export const NEW_GROUP_ORDER_PATH = `${GROUP_ORDER_PATH}/new`
export const NEW_BACKUP_MEAL_PATH = `${NEW_GROUP_ORDER_PATH}/backup-meal`
export const NEW_MEAL_BUDGET_PATH = `${NEW_GROUP_ORDER_PATH}/meal-budget`
export const NEW_DELIVERY_DETAILS_PATH = `${NEW_GROUP_ORDER_PATH}/delivery-details`
export const NEW_ORDER_SUMMARY_PATH = `${NEW_GROUP_ORDER_PATH}/order-summary`
export const NEW_CHECKOUT_PATH = `${NEW_GROUP_ORDER_PATH}/checkout`
export const SHARE_ORDER_PATH = `${GROUP_ORDER_PATH}/share`

export const GROUP_ORDERS_PATH = `${GROUP_ORDER_PATH}/orders`
export const GROUP_ORDER_LIST_ORGANIZING_PATH = `${GROUP_ORDERS_PATH}/organizing`
export const GROUP_ORDER_LIST_ATTENDING_PATH = `${GROUP_ORDERS_PATH}/attending`

export const EDIT_GROUP_ORDER_PATH = `${GROUP_ORDER_PATH}/:groupOrderId`
export const GROUP_ORDER_PRINT_PATH = `${EDIT_GROUP_ORDER_PATH}/print`
export const EDIT_DEFAULT_MEAL_PATH = `${EDIT_GROUP_ORDER_PATH}/defaultMeal`
export const EDIT_BACKUP_MEAL_PATH = `${EDIT_GROUP_ORDER_PATH}/backup-meal`
export const EDIT_ATTENDEE_MEAL_PATH = `${EDIT_GROUP_ORDER_PATH}/attendee/:attendeeId`

export const STORES_PATH = '/restaurants'
export const STORES_FOR_LOCATION_PATH = `${STORES_PATH}/:locationId`
export const STORES_FOR_NO_LOCATION_PATH = `${STORES_PATH}/shucks`

export const ATTENDEE_TOKEN_PREFIX = '/attendee/token'
export const ATTENDEE_TOKEN_PATH = `${ATTENDEE_TOKEN_PREFIX}/:token`
export const ATTENDEE_TOKEN_LOGIN_PATH = `${ATTENDEE_TOKEN_PATH}/login`
export const ATTENDEE_TOKEN_MEAL_PATH = `${ATTENDEE_TOKEN_PATH}/meal`
export const ATTENDEE_TOKEN_STATUS_PATH = `${ATTENDEE_TOKEN_PATH}/status`

export const SPLASH_PATH = '/'
export const AW_SHUCKS_PATH = '/shucks'
export const PAGE_NOT_FOUND = '/404'
export const ERROR_PATH = '/error'
export const LOGIN_PATH = '/login'

// External: Order routes
export const ORDER_URL = process.env.REACT_APP_ORDER_URL
export const TERMS_AND_CONDITIONS_URL = `${ORDER_URL}/terms-and-conditions`
export const REFERRAL_TERMS_AND_CONDITIONS_URL = `${ORDER_URL}/referral-terms-and-conditions`
export const FORGOT_PASSWORD_URL = `${ORDER_URL}/forgot-password`
export const INDIVIDUAL_ORDER_URL = ORDER_URL
export const PRIVATE_POLICY_URL = `${ORDER_URL}/privacy`
export const AD_PRIVATE_POLICY_URL = `${ORDER_URL}/privacy#ad-choices`
export const USER_PROFILE_URL = `${ORDER_URL}/profile`
export const LOGIN_URL = `${ORDER_URL}/login`
export const LOG_OUT_URL = `${ORDER_URL}/logout`

// External: Foodsby One routes
export const FOODSBY_ONE_URL = process.env.REACT_APP_FOODSBY_ONE_URL

// External: Main routes
export const FOODSBY_URL = process.env.REACT_APP_MARKETING_URL
export const MAIN_URL = `${FOODSBY_URL}/main`
export const CONTACT_URL = `${MAIN_URL}/contact`
export const AUTHENTICATED_CONTACT_URL = `${ORDER_URL}/contact`
export const CAREERS_URL = `${MAIN_URL}/careers`
export const RESTAURANT_LOGIN = `${MAIN_URL}/restaurants-login`
