import LogRocket from 'logrocket'
import join from 'lodash/join'
import map from 'lodash/map'

export const logException = ex => {
  if (process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.captureException(ex)
  } else {
    console.error(ex)
  }
}

export const toErrorString = validationErrors =>
  join(map(validationErrors, err => err.message), ', ')
