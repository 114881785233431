import React from 'react'
import classnames from 'classnames'

const RoundTextareaField = ({ field, form, ...rest }) => {
  const error = !!form.errors[field.name]
  const touched = !!form.touched[field.name]
  const length = field.value ? field.value.length : 0

  return (
    <RoundTextarea
      error={error && touched}
      length={length}
      {...field}
      {...rest}
    />
  )
}

const RoundTextarea = ({ error, length, maxLength, ...rest }) => (
  <div
    className={classnames('round-textarea', {
      'round-textarea--error': error
    })}
  >
    <textarea {...rest} />
    <div className='round-textarea__footer'>
      {maxLength && (
        <div
          className={classnames('round-textarea__length-counter', {
            'round-textarea__length-counter--error': length > maxLength
          })}
        >
          {length} / {maxLength} characters
        </div>
      )}
    </div>
  </div>
)

export { RoundTextareaField, RoundTextarea }
