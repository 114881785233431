import React, { useState } from 'react'
import { connect } from 'react-redux'
import emojiRegex from 'emoji-regex'
import { NavBar } from '@foodsby/nutrient-v3'

import {
  selectGoLocationId,
  selectUserProfile,
  selectCurrentUser
} from 'redux/selectors'
import LoginModal from 'components/login/LoginModal'
import data from 'data/appData'
import ImpersonationHeader from './ImpersonationHeader'

const FOODSBY_ONE_AUTHORITIES = [
  'ROLE_ACCOUNTS_READ',
  'ROLE_FOODSBY_ACCOUNTS_READ'
]
const isAdmin = (authorities = []) => authorities.includes('ROLE_FOODSBY_ADMIN')
const isFoodsbyOne = authorities =>
  authorities &&
  authorities.some(auth => FOODSBY_ONE_AUTHORITIES.includes(auth))

const Initials = (name = '', props) => {
  const regex = emojiRegex()
  const match = regex.exec(name)
  if (match && match.length) {
    return match[0]
  } else {
    return name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
  }
}

const ProfileImage = ({ avatarId, name }) => {
  const imgSrc =
    avatarId &&
    `${
      process.env.REACT_APP_IMAGE_LAMBDA
    }/profiles/${avatarId}.jpg?height=50&width=50`

  return (
    <div className='profile-image_header--flyout'>
      {imgSrc ? (
        <img alt='avatar' height={20} src={imgSrc} width={20} />
      ) : name ? (
        <div className='no-profile-img'>{Initials(name)}</div>
      ) : (
        <img
          alt=''
          height={20}
          src='https://images.foodsby.com/conweb/profile-placeholder.png'
        />
      )}
    </div>
  )
}

const AppHeader = ({ currentUser, userProfile, goLocationId }) => {
  const [showLoginModal, setShowLoginModal] = useState(false)

  const isAuthenticated = currentUser && currentUser.userId

  let flyoutLinks =
    userProfile && userProfile.status !== 'CONFIRMED'
      ? [...data.simpleMenu]
      : [
          {
            ...data.headerMenu[0],
            copy: (
              <>
                <ProfileImage
                  avatarId={userProfile && userProfile.avatarId}
                  isWebpSupported={false}
                />{' '}
                {data.headerMenu[0].copy}
              </>
            )
          },
          ...data.headerMenu.slice(1)
        ]

  if (isAuthenticated) {
    if (isAdmin(currentUser.authorities)) {
      flyoutLinks = [...flyoutLinks, ...data.adminMenu]
    }
    if (isFoodsbyOne(currentUser.authorities)) {
      flyoutLinks = [...flyoutLinks, ...data.foodsbyOne]
    }
    flyoutLinks = [...flyoutLinks, ...data.logout]
  }

  const isImpostor =
    isAuthenticated && currentUser.authorities.includes('ROLE_IMPOSTOR')

  return (
    <>
      {isImpostor && <ImpersonationHeader />}
      <div className='nutrient-overrides app-header'>
        <NavBar
          home='/restaurants'
          isDailySelected={false}
          loginOnClick={() => setShowLoginModal(true)}
          flyoutLinks={flyoutLinks}
          isAuthenticated={isAuthenticated}
          dailyLink={process.env.REACT_APP_ORDER_URL}
        />
        <LoginModal
          show={showLoginModal}
          hideRegister={showLoginModal && !goLocationId}
          onCancel={() => setShowLoginModal(false)}
          onSuccess={() => setShowLoginModal(false)}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  goLocationId: selectGoLocationId(state),
  userProfile: selectUserProfile(state),
  currentUser: selectCurrentUser(state)
})

export default connect(mapStateToProps)(AppHeader)
