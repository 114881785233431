import compact from 'lodash/compact'

export const toDisplayName = user =>
  user.firstName ? formatName(user) : user.emailAddress

export const toDisplayFirstName = user =>
  user.firstName ? user.firstName : user.emailAddress

export const formatName = user =>
  compact([user.firstName, user.lastName]).join(' ')

export const isLoggedIn = user => user && user.userId
