import React from 'react'
import classnames from 'classnames'

import AppHeader from 'components/appHeader/AppHeader'
import { NavFooter } from '@foodsby/nutrient-v3'
import Sticky from 'components/newOrderWorkflow/shared/Sticky'

import {
  CONTACT_URL,
  AUTHENTICATED_CONTACT_URL,
  RESTAURANT_LOGIN,
  TERMS_AND_CONDITIONS_URL,
  REFERRAL_TERMS_AND_CONDITIONS_URL,
  PRIVATE_POLICY_URL,
  AD_PRIVATE_POLICY_URL,
  CAREERS_URL
} from 'routes'

const DfoLayout = ({ currentUser, headerProps, backgroundColor, children }) => {
  const isAuthenticated = currentUser && currentUser.userId

  return (
    <div
      className={classnames('dfo-layout', {
        'dfo-layout-grey': backgroundColor === 'grey'
      })}
    >
      <AppHeader {...headerProps} />
      {/* We need to mount a div that can be used as a portal so that we can update the delivery options outside of the Formik form used for each page */}
      <Sticky>
        <div id='delivery-options-portal' className='sticky' />
      </Sticky>
      {children}
      <div className='nutrient-overrides'>
        <NavFooter
          className='nutrient-overrides'
          links={[
            {
              href: isAuthenticated ? AUTHENTICATED_CONTACT_URL : CONTACT_URL,
              copy: 'Contact'
            },
            {
              href: RESTAURANT_LOGIN,
              copy: 'Resturant Login'
            },
            {
              href: TERMS_AND_CONDITIONS_URL,
              copy: 'Terms of Use'
            },
            {
              href: REFERRAL_TERMS_AND_CONDITIONS_URL,
              copy: 'Referrals'
            },
            { href: PRIVATE_POLICY_URL, copy: 'Privacy Policy' },
            {
              href: AD_PRIVATE_POLICY_URL,
              copy: 'Ad Choices'
            },
            { href: CAREERS_URL, copy: 'Careers' }
          ]}
        />
      </div>
    </div>
  )
}

export default DfoLayout
