import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { withRouter } from 'react-router'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import OrderWorkflowLayout from 'components/newOrderWorkflow/orderWorkflowLayout/OrderWorkflowLayout'
import AbandonOrderPrompt from 'components/newOrderWorkflow/shared/AbandonOrderPrompt'
import DeliveryDetailsMain from './deliveryDetailsMain/DeliveryDetailsMain'
import DeliveryDetailsSidebar from './deliveryDetailsSidebar/DeliveryDetailsSidebar'
import {
  selectNewGroupOrder,
  newGroupOrder,
  selectCurrentUser,
  selectUserProfile
} from 'redux/selectors'
import { setNavAlert } from 'redux/modules/alerts'
import {
  setDeliveryOptionsFormOpen,
  setContactInfo,
  setDropoffInstructions
} from 'redux/modules/newGroupOrder'
import { getStores } from 'redux/modules/store'
import { getUserProfile } from 'redux/modules/user'
import { routeToHomeIfNoData } from 'higherOrderComponents'
import { NEW_ORDER_SUMMARY_PATH } from 'routes'
import { formatName } from 'utils/user'
import { DELIVERY_DETAILS_ORDER_STEP } from 'components/newOrderWorkflow/shared/orderSteps'
import {
  INCOMPLETE_STEP_ALERT,
  buildIncompleteStepAlert
} from 'components/newOrderWorkflow/navAlerts/constants'

const DeliveryDetailsForm = props => {
  useEffect(() => {
    // Workaround to validate form with initial values on mount. Formik has some issues around this:
    // https://github.com/jaredpalmer/formik/issues/1950
    props.validateForm()
  }, [])

  return (
    <Form style={{ height: '100%' }}>
      <OrderWorkflowLayout
        headerProps={{
          stepName: DELIVERY_DETAILS_ORDER_STEP,
          onClickFutureStep: (step, isComplete) => {
            if (!props.isValid) {
              props.setNavAlert({
                name: INCOMPLETE_STEP_ALERT,
                messageOverride: buildIncompleteStepAlert(
                  DELIVERY_DETAILS_ORDER_STEP
                )
              })
            } else if (isComplete) {
              props.history.push(step.path)
            }
          },
          onClickNextStep: step => {
            if (!props.isValid) {
              props.setNavAlert({
                name: INCOMPLETE_STEP_ALERT,
                messageOverride: buildIncompleteStepAlert(
                  DELIVERY_DETAILS_ORDER_STEP
                )
              })
            } else {
              props.submitForm()
            }
          },
          showNextStepLabel: props.isValid
        }}
        mainComponent={<DeliveryDetailsMain {...props} />}
        sidebarComponent={<DeliveryDetailsSidebar {...props} />}
      />
    </Form>
  )
}

let DeliveryDetailsPage = ({
  currentUser,
  userProfile,
  locationId,
  contactName,
  contactPhoneNumber,
  contactPhoneExtension,
  contactEmail,
  suiteNumber,
  dropoffInstructions,
  getStores,
  getUserProfile,
  setDeliveryOptionsFormOpen,
  setDropoffInstructions,
  setContactInfo,
  setNavAlert,
  history
}) => {
  useEffect(() => {
    const fetchStores = async () => await getStores(locationId)
    fetchStores()
  }, [locationId])

  const [loadingUserProfile, setLoadingUserProfile] = useState(true)

  useEffect(() => {
    if (loadingUserProfile) {
      if (currentUser && currentUser.userId) {
        const fetchUserProfile = async () => {
          await getUserProfile(currentUser.userId)
          setLoadingUserProfile(false)
        }
        fetchUserProfile()
      } else {
        setLoadingUserProfile(false)
      }
    }
  }, [currentUser])

  const onSubmit = () => {
    history.push(NEW_ORDER_SUMMARY_PATH)
  }

  const loggedIn = currentUser && currentUser.userId
  const initialContactName = contactName
    ? contactName
    : loggedIn
    ? formatName(currentUser)
    : ''
  const initialContactEmail = contactEmail
    ? contactEmail
    : loggedIn
    ? currentUser.emailAddress
    : ''
  const initialContactPhone = contactPhoneNumber
    ? contactPhoneNumber
    : userProfile
    ? userProfile.phone
    : ''

  return (
    <DfoLayout currentUser={currentUser}>
      {!loadingUserProfile && (
        <Formik
          initialValues={{
            contactName: initialContactName,
            contactPhoneNumber: initialContactPhone,
            contactPhoneExtension: contactPhoneExtension || '',
            contactEmail: initialContactEmail,
            suiteNumber: suiteNumber || '',
            dropoffInstructions: dropoffInstructions || ''
          }}
          validateOnChange
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {props => (
            <DeliveryDetailsForm
              setDeliveryOptionsFormOpen={setDeliveryOptionsFormOpen}
              setNavAlert={setNavAlert}
              setContactInfo={setContactInfo}
              setDropoffInstructions={setDropoffInstructions}
              history={history}
              {...props}
            />
          )}
        </Formik>
      )}

      <AbandonOrderPrompt />
    </DfoLayout>
  )
}

DeliveryDetailsPage = withRouter(DeliveryDetailsPage)

DeliveryDetailsPage = routeToHomeIfNoData(DeliveryDetailsPage)

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  userProfile: selectUserProfile(state),
  newGroupOrder: selectNewGroupOrder(state),
  locationId: newGroupOrder.selectLocationId(state),
  contactName: newGroupOrder.selectContactName(state),
  contactPhoneNumber: newGroupOrder.selectContactPhoneNumber(state),
  contactPhoneExtension: newGroupOrder.selectContactPhoneExtension(state),
  contactEmail: newGroupOrder.selectContactEmail(state),
  suiteNumber: newGroupOrder.selectSuiteNumber(state),
  dropoffInstructions: newGroupOrder.selectDropoffInstructions(state)
})

const mapDispatchToProps = {
  getStores,
  getUserProfile,
  setNavAlert,
  setDeliveryOptionsFormOpen,
  setContactInfo,
  setDropoffInstructions
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryDetailsPage)
