import { createMuiTheme } from '@material-ui/core/styles'
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3d4a6b'
    },
    secondary: {
      main: '#59969b'
    },
    error: {
      main: '#ab2b19'
    },
    warning: {
      main: '#feb700'
    },
    success: {
      main: '#6e8d28'
    },
    background: {
      default: '#fff'
    },
    text: {
      primary: '#30332c'
    }
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    h1: {
      fontWeight: 'bold'
    },
    h3: {
      fontWeight: 'bold'
    },
    h5: {
      fontWeight: 'bold'
    },
    h6: {
      fontWeight: 'bold'
    },
    subtitle1: {
      fontWeight: 'bold'
    }
  },
  spacing: factor => [0, 4, 8, 16, 32, 64][factor],
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 4
      }
    },
    MuiButtonGroup: {
      root: {
        borderRadius: 4
      }
    },
    MuiSwitch: {
      root: {
        height: 43,
        padding: 9
      },
      track: {
        borderRadius: 25
      },
      thumb: {
        height: 24,
        width: 24
      }
    },
    MuiAvatarGroup: {
      root: {
        marginLeft: '4px',
        avatar: {
          marginLeft: '-4px'
        }
      }
    }
  }
})
export default theme
