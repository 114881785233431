import React from 'react'

import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import OrderDeliveryInfo from './orderDeliveryInfo/OrderDeliveryInfo'
import OrderContactInfo from './orderContactInfo/OrderContactInfo'
import PaymentMethod from './paymentMethod/PaymentMethod'
import { NEW_BACKUP_MEAL_PATH, NEW_DELIVERY_DETAILS_PATH } from 'routes'

const CheckoutMain = ({
  contactName,
  contactEmail,
  contactPhoneNumber,
  contactPhoneExtension,
  suiteNumber,
  payments,
  currentStore,
  attendeesCount,
  budgetPerAttendeeInCents,
  dropoff,
  history,
  noBudget,
  ...rest
}) => (
  <div className='checkout-main'>
    <div className='checkout-main__content'>
      <h1 className='dfo-h1'>Ready to place your order?</h1>

      {currentStore && (
        <OrderDeliveryInfo
          store={currentStore}
          dropoff={dropoff}
          attendeesCount={attendeesCount}
          budgetPerAttendeeInCents={budgetPerAttendeeInCents}
          noBudget={noBudget}
          onClickAction={() => history.push(NEW_BACKUP_MEAL_PATH)}
        />
      )}

      <OrderContactInfo
        contactName={contactName}
        contactEmail={contactEmail}
        contactPhoneNumber={contactPhoneNumber}
        contactPhoneExtension={contactPhoneExtension}
        suiteNumber={suiteNumber}
        onClickAction={() => history.push(NEW_DELIVERY_DETAILS_PATH)}
      />

      {payments && <PaymentMethod payments={payments} {...rest} />}

      <DfoSubmitButton label='Place Order' />
    </div>
  </div>
)

export default CheckoutMain
