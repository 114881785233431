import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import isEmpty from 'lodash/isEmpty'
import { Spinner } from '@foodsby/nutrient'

import Menu from 'components/newOrderWorkflow/menu/Menu'
import MenuItemNav from 'components/newOrderWorkflow/menu/menuItemNav/MenuItemNav'
import { getMenuItem } from 'redux/modules/menu'
import { selectMenu, selectMenuItem } from 'redux/selectors'

class MenuMain extends Component {
  handleSelectMenuItem = menuItemId => {
    if (
      !this.props.onSelectMenuItem ||
      (this.props.onSelectMenuItem && this.props.onSelectMenuItem(menuItemId))
    ) {
      this.props.getMenuItem(menuItemId)
    }
  }

  render () {
    const {
      loading,
      menu,
      selectedMenuItem,
      saveMealItem,
      currentStore,
      mealItem,
      unselectMenuItem,
      isBackupMeal,
      menuHeaderComponent,
      attendee,
    } = this.props

    if (loading) {
      return (
        <div className='menu-main menu-main--loading'>
          <Spinner size='lg' />
        </div>
      )
    }

    const menuHeader = menuHeaderComponent({
      menu,
      store: currentStore,
    })

    return (
      <div className='menu-main'>
        <div className='menu-main__top'>
          {menuHeader}

          {!isEmpty(menu) && <MenuItemNav menu={menu} />}

          <Menu
            menu={menu}
            mealItem={mealItem}
            saveMealItem={saveMealItem}
            selectedMenuItem={selectedMenuItem}
            onSelectMenuItem={this.handleSelectMenuItem}
            unselectMenuItem={unselectMenuItem}
            isBackupMeal={isBackupMeal}
            attendee={attendee}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  selectedMenuItem: selectMenuItem(state),
})

const mapDispatchToProps = {
  getMenuItem,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuMain),
)
