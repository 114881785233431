import React from 'react'

import { ReactComponent as CalendarIcon } from 'assets/icon--calendar.svg'
import { ReactComponent as PinIcon } from 'assets/icon--pin.svg'
import { ReactComponent as ProfileIcon } from 'assets/icon--profile.svg'
import { ReactComponent as LockIcon } from 'assets/icon--lock.svg'
import { ReactComponent as CircleCheckIcon } from 'assets/icon--circle-check.svg'
import { ReactComponent as CircleCrossIcon } from 'assets/icon--circle-cross.svg'
import SidebarIconField from 'components/sidebarIconField/SidebarIconField'
import { isPastDropoff, isPastCutoff, isCancelled } from 'utils/order'
import { formatAddress } from 'utils/formatters'
import { formatDateTime, formatDateTimeNoWeekday } from 'utils/datetime'

const CANCELED = 'CANCELED'
const DELIVERED = 'DELIVERED'
const LOCKED = 'LOCKED'
const PENDING = 'PENDING'

const getStatus = groupOrder => {
  if (!groupOrder.status) {
    return null
  }
  if (isCancelled(groupOrder)) {
    return CANCELED
  } else if (isPastDropoff(groupOrder)) {
    return DELIVERED
  } else if (isPastCutoff(groupOrder)) {
    return LOCKED
  } else {
    return PENDING
  }
}

const MeetingDetails = ({
  dropoff,
  attendeesCount,
  location,
  cutoff,
  hideAttendeesCount,
  groupOrder = {},
  headerText = 'Order Details'
}) => {
  const status = getStatus(groupOrder)

  return (
    <div className='meeting-details'>
      <h3>{headerText}</h3>

      <SidebarIconField icon={PinIcon} className='meeting-details__field'>
        <span>{formatAddress(location)}</span>
      </SidebarIconField>

      <SidebarIconField icon={CalendarIcon} className='meeting-details__field'>
        <span>{formatDateTime(dropoff)}</span>
      </SidebarIconField>

      {!hideAttendeesCount && (
        <SidebarIconField icon={ProfileIcon} className='meeting-details__field'>
          <span>{attendeesCount} attendees</span>
        </SidebarIconField>
      )}

      {status === CANCELED && (
        <SidebarIconField icon={CircleCrossIcon} iconClassName='circle-cross'>
          <span>Canceled</span>
        </SidebarIconField>
      )}
      {status === DELIVERED && (
        <SidebarIconField icon={CircleCheckIcon}>
          <span>Delivered</span>
        </SidebarIconField>
      )}
      {status === LOCKED && (
        <SidebarIconField icon={LockIcon} iconClassName='lock'>
          <span>Scheduled: On time</span>
        </SidebarIconField>
      )}
      {cutoff && status === PENDING && (
        <SidebarIconField icon={LockIcon} iconClassName='lock'>
          <span>Order locks on {formatDateTimeNoWeekday(cutoff)}</span>
        </SidebarIconField>
      )}
    </div>
  )
}

export default MeetingDetails
