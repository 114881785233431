import React from 'react'
import Collapsible from 'react-collapsible'

const DfoCollapsible = ({ trigger, initialOpen, className, children }) => (
  <Collapsible
    classParentString='dfo-collapsible'
    openedClassName={className}
    className={className}
    trigger={trigger}
    open={initialOpen}
    overflowWhenOpen='visible'
  >
    {children}
  </Collapsible>
)

export default DfoCollapsible
