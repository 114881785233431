import React from 'react'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'

const MenuItemNav = ({ menu }) => {
  const onClick = (evt, id) => {
    evt.preventDefault()
    scrollIntoView(document.getElementById(id), {
      behavior: 'smooth',
      block: 'start'
    })
  }

  return (
    <nav className='menu-item-nav' role='navigation'>
      <ul className='menu-item-nav__list'>
        {(menu.subMenus || []).map((item, i) => (
          <li key={`navItem-${i}`} className='menu-item-nav__list-item'>
            <a
              href={`#${item.id}`}
              onClick={evt => onClick(evt, item.id)}
              className='menu-item-nav__link'
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default MenuItemNav
