import { useEffect, useRef } from 'react'
import { connect } from 'formik'
import noop from 'lodash/noop'

/*
 * React hooks don't give access to previous props,
 * so we must use a hook to store them for functional components
 */
const usePrevious = value => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

/*
 * Formik has no top-level 'onChange' handler,
 * so we use an effect hook to detect when the values changed
 */
const FormikEffect = ({ onChange = noop, formik }) => {
  const { values } = formik
  const prevValues = usePrevious(values)

  useEffect(() => {
    // Don't run effect on form init
    if (prevValues) {
      onChange({ prevValues, nextValues: values, formik })
    }
  }, [values])

  return null
}

export default connect(FormikEffect)
