import React, { useState } from 'react'
import flatten from 'lodash/flatten'
import { Action, Button } from '@foodsby/nutrient'

import SidebarIconField from 'components/sidebarIconField/SidebarIconField'
import { ReactComponent as PinIcon } from 'assets/icon--pin.svg'
import { ReactComponent as LockIcon } from 'assets/icon--lock.svg'
import { ReactComponent as CalendarIcon } from 'assets/icon--calendar.svg'
import { getMealsToDisplay, getCustomizations } from 'utils/order'
import { formatAddress } from 'utils/formatters'
import { formatDateTime, formatDateTimeNoWeekday } from 'utils/datetime'
import { isEditable } from 'utils/order'

const NextMeeting = ({ meeting, location, editOrder }) => {
  const [showDetails, setShowDetails] = useState(false)

  const mealsToDisplay = getMealsToDisplay(meeting)
  const locked = !isEditable(meeting)
  const hasCustomizations = flatten(mealsToDisplay.map(getCustomizations))

  return (
    <div className='next-meeting'>
      <div className='next-meeting__details'>
        <h2>Your Next Meeting</h2>
        <div>
          <SidebarIconField icon={PinIcon}>
            {formatAddress(location)}
          </SidebarIconField>
          <SidebarIconField icon={CalendarIcon}>
            {formatDateTime(meeting.dropoff)}
          </SidebarIconField>

          <SidebarIconField icon={LockIcon} iconClassName='lock'>
            <span>
              {locked
                ? 'Order Locked'
                : `Order locks on ${formatDateTimeNoWeekday(meeting.cutoff)}`}
            </span>
          </SidebarIconField>
        </div>
      </div>
      <div>
        <h4>Your Meal from {meeting.storeName}</h4>
        <div className='next-meeting__meal'>
          {mealsToDisplay.map(mealItem => {
            return (
              <div key={mealItem.id}>
                {mealItem.name}
                {showDetails &&
                  getCustomizations(mealItem).map((c, idx) => (
                    <div key={idx} className='next-meeting__customization'>
                      {c.name}
                    </div>
                  ))}
                {showDetails && mealItem.specialInstructions && (
                  <div className='next-meeting__customization'>
                    Special Instructions:{' '}
                    <em>{mealItem.specialInstructions}</em>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        {hasCustomizations && (
          <div className='next-meeting__show-details'>
            <Action onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? 'Hide Details' : 'Show Details'}
            </Action>
          </div>
        )}

        {!locked && (
          <div className='next-meeting__edit-meal'>
            <Button className='secondary' onClick={() => editOrder(meeting)}>
              Edit Meal
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default NextMeeting
