import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import FloatingButton from 'components/newOrderWorkflow/floatingButton/FloatingButton'
import SlideupMenu from 'components/newOrderWorkflow/shared/SlideupMenu'

const SidebarDesktop = ({ children }) => (
  <div className='dfo-page-layout__sidebar'>{children}</div>
)

const SidebarMobile = ({ children, show, toggle }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(show)
  }, [show])

  const handleSetOpen = value => {
    setOpen(value)
    if (open) {
      show = false
      toggle && toggle()
    }
  }

  return (
    <>
      <SlideupMenu
        open={open}
        onRequestClose={() => handleSetOpen(false)}
        modalElementClass='dfo-page-layout__sidebar--mobile-drawer'
      >
        {children}
      </SlideupMenu>
      <FloatingButton open={open} onClick={() => handleSetOpen(!open)} />
    </>
  )
}

const Sidebar = ({
  isDesktop,
  inlineMobileSidebar,
  showMobileSidebar,
  toggleMobileSidebar,
  children
}) => {
  if (isDesktop) {
    return <SidebarDesktop>{children}</SidebarDesktop>
  } else if (!isDesktop && inlineMobileSidebar) {
    return <>{children}</>
  } else {
    return (
      <SidebarMobile show={showMobileSidebar} toggle={toggleMobileSidebar}>
        {children}
      </SidebarMobile>
    )
  }
}

const Main = ({ children, noSidebar }) => (
  <div
    className={classnames('dfo-page-layout__main', {
      'dfo-page-layout__main--no-sidebar': noSidebar
    })}
  >
    {children}
  </div>
)

const DfoPageLayout = ({
  headerComponent,
  mainComponent,
  sidebarComponent,
  inlineMobileSidebar = false,
  showMobileSidebar = false,
  toggleMobileSidebar
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 769px)' })
  const noSidebar = !sidebarComponent

  return (
    <div className='dfo-page-layout'>
      {headerComponent}

      <div
        className={classnames('dfo-page-layout__content', {
          'dfo-page-layout__content--inline-mobile-sidebar':
            inlineMobileSidebar && !isDesktop
        })}
      >
        <Main noSidebar={noSidebar}>{mainComponent}</Main>

        {sidebarComponent && (
          <Sidebar
            isDesktop={isDesktop}
            inlineMobileSidebar={inlineMobileSidebar}
            showMobileSidebar={showMobileSidebar}
            toggleMobileSidebar={toggleMobileSidebar}
          >
            {sidebarComponent}
          </Sidebar>
        )}
      </div>
    </div>
  )
}

export default DfoPageLayout
