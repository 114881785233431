import React from 'react'
import { Field, connect as connectFormik } from 'formik'
import classnames from 'classnames'
import get from 'lodash/get'

const DfoFormField = ({
  formik,
  label,
  subtext,
  className,
  containerProps,
  fieldClassName,
  ...rest
}) => {
  const { id, name } = rest
  const error = get(formik.errors, name)
  const touched = get(formik.touched, name)

  return (
    <div
      className={classnames('dfo-form-field', className, {
        'dfo-form-field--error': !!error && !!touched
      })}
      {...containerProps}
    >
      {label && (
        <label className='dfo-form-field__label' htmlFor={id}>
          {label}
        </label>
      )}

      <div className='dfo-form-field__field'>
        <Field className={classnames(fieldClassName)} {...rest} />

        {subtext && <div className='dfo-form-field__subtext'>{subtext}</div>}

        <div
          className={classnames('dfo-form-field__error-message', {
            'dfo-form-field__error-message--visible': !!error && !!touched
          })}
        >
          {!!touched && error}
        </div>
      </div>
    </div>
  )
}

export default connectFormik(DfoFormField)
