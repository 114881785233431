import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import noop from 'lodash/noop'
import classnames from 'classnames'

import { ReactComponent as CheckmarkIcon } from 'assets/dfo/icon--green-check.svg'

const ShareOrderLink = ({
  url,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  setCopied = noop,
  disabled
}) => (
  <div
    className={classnames('share-order-link', {
      'share-order-link--disabled': disabled
    })}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
  >
    <div className='share-order-link__url'>{url}</div>

    <CopyToClipboard text={url} onCopy={() => !disabled && setCopied(true)}>
      <button
        type='button'
        className='share-order-link__copy-button'
        disabled={disabled}
      >
        COPY
      </button>
    </CopyToClipboard>
  </div>
)

const ShareOrderLinkWithCopy = ({ disabled, ...rest }) => {
  const [copied, setCopied] = useState(false)

  return (
    <div
      className={classnames('share-order-link-with-copy', {
        'share-order-link-with-copy--disabled': disabled
      })}
    >
      <ShareOrderLink setCopied={setCopied} disabled={disabled} {...rest} />

      <div
        className={classnames('share-order-link-with-copy__copied', {
          'share-order-link-with-copy__copied--visible': copied
        })}
      >
        <CheckmarkIcon />
        Copied!
      </div>
    </div>
  )
}

export { ShareOrderLink, ShareOrderLinkWithCopy }
