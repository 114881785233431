import React, { useState } from 'react'
import SlideupMenu from 'components/newOrderWorkflow/shared/SlideupMenu'
import { Action } from '@foodsby/nutrient'

import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import { createSupportTicket, createAttendeeSupportTicket } from 'api'

const MobileSupportForm = ({
  isComplete,
  setIsComplete,
  body,
  setBody,
  isSubmitting,
  createTicket,
  showLink
}) => {
  const [open, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
    setIsComplete(false)
    setBody('')
  }

  return (
    <div className='support-form support-form--mobile'>
      {showLink && (
        <Action
          className='support-form__open-action'
          onClick={() => (window.location = 'mailto:support@foodsby.com')}
        >
          Contact Support
        </Action>
      )}

      {!showLink && (
        <>
          <Action
            className='support-form__open-action'
            onClick={() => setOpen(true)}
          >
            Contact Support
          </Action>

          <SlideupMenu
            open={open}
            onRequestClose={close}
            modalElementClass='support-form__mobile-drawer'
          >
            <div className='support-form__slide-indicator' />
            <div className='support-form__mobile-form'>
              <DesktopSupportForm
                body={body}
                setBody={setBody}
                isComplete={isComplete}
                setIsComplete={setIsComplete}
                isSubmitting={isSubmitting}
                createTicket={createTicket}
              />
              {isComplete && (
                <RoundButton className='secondary' onClick={close} label='OK' />
              )}
            </div>
          </SlideupMenu>
        </>
      )}
    </div>
  )
}

const DesktopSupportForm = ({
  isComplete,
  setIsComplete,
  body,
  setBody,
  isSubmitting,
  createTicket,
  showLink
}) => {
  return (
    <div className='support-form'>
      {showLink && (
        <>
          <h3>Need Help?</h3>
          <div>
            Email: <a href='mailto:support@foodsby.com'>support@foodsby.com</a>
          </div>
        </>
      )}

      {!showLink && (
        <>
          {isComplete ? (
            <div className='support-form__confirmation'>
              <h4>Thank you for contacting us</h4>
              <div>
                <p>
                  A dedicated Foodsby Team Member will contact you as soon as
                  possible.
                </p>
              </div>
              <div>
                <Action onClick={() => setIsComplete(false)}>
                  Submit another ticket
                </Action>
              </div>
            </div>
          ) : (
            <>
              <h3>Need Help?</h3>
              <textarea
                rows={4}
                className='support-form__input'
                onChange={e => setBody(e.target.value)}
                value={body}
                placeholder='Have a question or need help with your order? Send us a message.'
              />
              <div className='support-form__actions'>
                <RoundButton
                  className='secondary'
                  disabled={isSubmitting || !body}
                  onClick={createTicket}
                  label='Send'
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

const SupportForm = ({ groupOrder, attendee, showLink, mobile = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [body, setBody] = useState()
  const [isComplete, setIsComplete] = useState(false)

  const createTicket = async () => {
    setIsSubmitting(true)

    try {
      attendee
        ? await createAttendeeSupportTicket(attendee.id, body)
        : await createSupportTicket(groupOrder.id, body)
      setIsComplete(true)
    } finally {
      setIsSubmitting(false)
      setBody('')
    }
  }

  if (mobile) {
    return (
      <MobileSupportForm
        body={body}
        setBody={setBody}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        isSubmitting={isSubmitting}
        createTicket={createTicket}
        showLink={showLink}
      />
    )
  } else {
    return (
      <DesktopSupportForm
        body={body}
        setBody={setBody}
        isComplete={isComplete}
        setIsComplete={setIsComplete}
        isSubmitting={isSubmitting}
        createTicket={createTicket}
        showLink={showLink}
      />
    )
  }
}

export default SupportForm
