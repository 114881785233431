import React from 'react'
import classnames from 'classnames'
import { Spinner } from '@foodsby/nutrient'

import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import { ReactComponent as Pencil } from 'assets/dfo/icon--pencil.svg'
import { ReactComponent as TrashCan } from 'assets/dfo/icon--trash-can.svg'
import { isDefaultMeal, toDisplayMeal, sumMealItems } from 'utils/order'
import { toDisplayName } from 'utils/user'
import { neuterEvent } from 'utils/inputs'

const toAttendeeDisplay = (attendee, index) =>
  attendee.userId ? toDisplayName(attendee) : `Attendee ${index + 1}`

const toDisplay = (defaultMeals, attendeeMeals) =>
  isDefaultMeal(defaultMeals, attendeeMeals)
    ? 'Backup Meal'
    : toDisplayMeal(attendeeMeals)

const AttendeeActions = ({
  index,
  attendee,
  onEditAttendee,
  onRemoveAttendee,
  canRemove,
  disabled
}) => (
  <div className='attendee-actions'>
    <button
      type='button'
      className='attendee-actions__button'
      onClick={neuterEvent(e => onEditAttendee(attendee))}
      disabled={disabled}
    >
      <Pencil alt='Edit Attendee' />
    </button>

    <button
      type='button'
      className={classnames('attendee-actions__button', {
        'attendee-actions__button--hidden': !canRemove
      })}
      onClick={neuterEvent(e => onRemoveAttendee(index))}
      disabled={disabled}
    >
      <TrashCan alt='Remove Attendee' />
    </button>
  </div>
)

const AttendeeCell = ({ className, children }) => (
  <div className={classnames('attendee-cell', className)}>{children}</div>
)

const AttendeeRow = ({
  index,
  defaultMeals,
  attendee,
  editable,
  canRemove,
  recentlyAdded,
  onRemoveAttendee,
  onEditAttendee
}) => {
  const mealPrice =
    (recentlyAdded ? '+' : '') + sumMealItems(attendee.meals).format()

  return (
    <div className='attendee-row'>
      <AttendeeCell className='attendee-cell__name-and-meal'>
        <div className='attendee-cell__name'>
          {toAttendeeDisplay(attendee, index)}
        </div>
        <div className='attendee-cell__meal'>
          {toDisplay(defaultMeals, attendee.meals)}
        </div>
        <div className='attendee-cell__money attendee-cell__money--mobile'>
          <span>{mealPrice}</span>
        </div>
      </AttendeeCell>

      <AttendeeCell className='attendee-cell__money attendee-cell__money--desktop'>
        <span>{mealPrice}</span>
      </AttendeeCell>

      {editable && (
        <AttendeeCell className='attendee-cell__actions'>
          <AttendeeActions
            index={index}
            attendee={attendee}
            onEditAttendee={onEditAttendee}
            onRemoveAttendee={onRemoveAttendee}
            canRemove={canRemove}
          />
        </AttendeeCell>
      )}
    </div>
  )
}

const AddAttendeeRow = ({
  attendeeCount,
  defaultMeals,
  onAddAttendee,
  submitting
}) => {
  const addAttendee = neuterEvent(e => !submitting && onAddAttendee())

  return (
    <div
      className={classnames('attendee-row', 'add-attendee-row', {
        'add-attendee-row--submitting': submitting
      })}
      onClick={addAttendee}
    >
      <AttendeeCell className='attendee-cell__name-and-meal'>
        {!submitting && (
          <DfoAction onClick={addAttendee} disabled={submitting}>
            + Add Attendee to Order
          </DfoAction>
        )}

        {submitting && (
          <div className='attendee-cell__name'>Attendee {attendeeCount}</div>
        )}

        <div className='attendee-cell__meal'>Backup Meal</div>
      </AttendeeCell>

      <AttendeeCell className='attendee-cell__money'>
        <span>+{sumMealItems(defaultMeals).format()}</span>
      </AttendeeCell>

      <AttendeeCell className='attendee-cell__actions'>
        <AttendeeActions index={attendeeCount} canRemove disabled />
      </AttendeeCell>

      {submitting && <Spinner size='sm' />}
    </div>
  )
}

export { AttendeeRow, AddAttendeeRow }
