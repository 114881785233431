import React from 'react'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import reject from 'lodash/reject'
import map from 'lodash/map'

import PersonalizeMenuForm from './PersonalizeMenuForm'
import { normalizeFormValues } from './utils'

const normalizeQuestions = (questions, parents = []) => {
  return questions.reduce((acc, q) => {
    let formId
    if (isEmpty(parents)) {
      formId = `q_${q.id}`
    } else {
      formId = `q_${parents.join('_')}_${q.id}`
    }
    acc = [...acc, { ...q, parents, formId }]
    q.answers.forEach(a => {
      if (a.questions) {
        acc = [...acc, ...normalizeQuestions(a.questions, [...parents, q.id, a.id])]
      }
    })
    return acc
  }, [])
}

const getInitialValues = (mealItem, menuQuestions) => {
  if (!mealItem) {
    return {}
  }
  const values = normalizeMealItems(mealItem.modifiers, menuQuestions)
  return {
    ...values,
    specialInstructions: mealItem.specialInstructions || '',
  }
}

const normalizeMealItems = (mealItemQuestions, menuItemQuestions) => {
  return sortBy(mealItemQuestions, 'depth').reduce((acc, mealQuestion) => {
    const menuQuestion = menuItemQuestions.find(menuQuestion => {
      let parents = [...menuQuestion.parents]
      parents.pop()
      return (
        menuQuestion.id === mealQuestion.questionId &&
        (mealQuestion.depth === 1 || !isEmpty(acc[`q_${parents.join('_')}`]))
      )
    })

    if (!menuQuestion) {
      return acc
    }

    let answerIds = reject(mealQuestion.answers, a => a.length < 1)
    answerIds = map(answerIds, a => a.answerId)

    if (isEmpty(mealQuestion.answers)) {
      return acc
    }
    acc[menuQuestion.formId] = answerIds
    return acc
  }, {})
}

const PersonalizeMenu = ({
  isBackupMeal,
  menuItem,
  mealItem,
  saveMealItem,
  closeModal,
  attendee,
}) => {
  const questions = normalizeQuestions(menuItem.questions)
  const initialValues = getInitialValues(mealItem, questions)
  const isUpdatingMealItem = !isEmpty(initialValues)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        const transformedMenuItem = normalizeFormValues(menuItem, values, questions)
        saveMealItem(transformedMenuItem)
        closeModal()
      }}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {props => (
        <PersonalizeMenuForm
          isBackupMeal={isBackupMeal}
          menuItem={menuItem}
          questions={questions}
          onCancel={closeModal}
          isUpdatingMealItem={isUpdatingMealItem}
          attendee={attendee}
          {...props}
        />
      )}
    </Formik>
  )
}

export default PersonalizeMenu
