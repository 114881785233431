import React from 'react'

import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import { getCustomizations } from 'utils/order'
import { fromCents } from 'utils/money'

const OrderMealItem = ({
  mealItem,
  onEditMealItem,
  onRemoveMealItem,
  showPrice
}) => {
  const customizations = getCustomizations(mealItem)

  return (
    <div className='order-meal-item'>
      <div className='order-meal-item__header'>
        <div className='order-meal-item__name'>
          {mealItem.name}
          {showPrice && onEditMealItem && (
            <DfoAction onClick={onEditMealItem}>Edit</DfoAction>
          )}
        </div>

        {!showPrice && onEditMealItem && (
          <DfoAction onClick={onEditMealItem}>Edit</DfoAction>
        )}

        {showPrice && (
          <div className='order-meal-item__price'>
            {fromCents(mealItem.totalPriceInCents).format()}
          </div>
        )}
      </div>

      <ul className='order-meal-item__customization-list'>
        {customizations.map((c, index) => (
          <li key={index} className='order-meal-item__customization-list-item'>
            {c.name}{' '}
            {c.priceInCents > 0 ? (
              <span className='order-meal-item__customization-list-item-price'>
                +{fromCents(c.priceInCents).format()}
              </span>
            ) : (
              ''
            )}
          </li>
        ))}
      </ul>

      {mealItem.specialInstructions && (
        <div className='order-meal-item__special-instructions'>
          <span className='order-meal-item__special-instructions-label'>
            Special Instructions:
          </span>{' '}
          {mealItem.specialInstructions}
        </div>
      )}

      {onRemoveMealItem && (
        <DfoAction className='destructive' onClick={onRemoveMealItem}>
          Remove
        </DfoAction>
      )}
    </div>
  )
}

export default OrderMealItem
