import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import NavigationPrompt from 'react-router-navigation-prompt'
import trim from 'lodash/trim'
import size from 'lodash/size'
import { Formik, Form } from 'formik'
import { parse } from 'date-fns'

import { ReactComponent as LeftChevronIcon } from 'assets/dfo/icon--chevron-left-navy-blue.svg'
import FormikEffect from 'components/newOrderWorkflow/dfoFormField/FormikEffect'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import DateTimeFormatted from 'components/newOrderWorkflow/shared/dateTimeFormatted/DateTimeFormatted'
import MealBudgetFormatted from 'components/newOrderWorkflow/shared/mealBudgetFormatted/MealBudgetFormatted'
import DeliveryDetailsFormatted from 'components/newOrderWorkflow/shared/deliveryDetailsFormatted/DeliveryDetailsFormatted'
import OrderMealItem from 'components/newOrderWorkflow/shared/orderMealItem/OrderMealItem'
import { AlertSuccessMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import MealBudget from 'components/newOrderWorkflow/mealBudget/MealBudget'
import DeliveryDetails from 'components/newOrderWorkflow/deliveryDetails/DeliveryDetails'
import { ShareOrderLinkWithCopy } from 'components/newOrderWorkflow/shareOrderLink/ShareOrderLink'
import {
  UnsavedDetailsModal,
  ConfirmNavigationModal
} from './unsavedDetailsModal/UnsavedDetailsModal'
import OrderSection from './orderSection/OrderSection'
import AttendeeList from './attendeeList/AttendeeList'
import EditAttendeeModal from './editAttendeeModal/EditAttendeeModal'
import DeleteAttendeeModal from './deleteAttendeeModal/DeleteAttendeeModal'
import ContactSupport from './orderSupportForm/OrderSupportForm'
import { isEditable, shareLinkBaseUrl, getHighestMealCost } from 'utils/order'
import { formatDate, formatTime } from 'utils/datetime'
import { formatAddress } from 'utils/formatters'
import {
  addAttendee,
  removeAttendee,
  setAttendee,
  updateGroupOrder,
  setHasAuthError
} from 'redux/modules/groupOrder'
import {
  isGroupOrderOwner,
  selectGroupOrder,
  selectConfig,
  selectLocation
} from 'redux/selectors'
import {
  GROUP_ORDERS_PATH,
  EDIT_ATTENDEE_MEAL_PATH,
  EDIT_BACKUP_MEAL_PATH,
  ATTENDEE_TOKEN_PATH,
  PAGE_NOT_FOUND,
  ERROR_PATH
} from 'routes'
import { CC_ERROR } from 'errors'

const noPromptRoutes = [PAGE_NOT_FOUND, ERROR_PATH]

class OrderManageMain extends Component {
  state = {
    removeAttendeeId: undefined,
    editAttendeeId: undefined,
    editAttendeeError: undefined,
    addAttendeeSubmitting: false,
    addAttendeeComplete: false,
    deliveryDetailsFormDirty: false,
    mealBudgetFormDirty: false,
    updatingOrder: false,
    potentialAttendee: undefined
  }

  componentWillUnmount () {
    this.props.setHasAuthError(false)
  }

  onAddAttendee = async () => {
    this.setState({ addAttendeeSubmitting: true })
    try {
      await this.updateOrder({ addAmountOfAttendees: 1 })
      this.setState({ addAttendeeComplete: true })
    } finally {
      this.setState({ addAttendeeSubmitting: false })
    }
  }

  onRemoveAttendee = (attendees, removeIndex) =>
    this.setState({ removeAttendeeId: attendees[removeIndex].id })

  removeAttendee = async () => {
    try {
      await this.updateOrder({
        removeAttendeeIds: [this.state.removeAttendeeId]
      })
      this.setState({ addAttendeeComplete: false })
    } finally {
      this.setState({ removeAttendeeId: undefined })
    }
  }

  onSelectAttendee = (attendee, forceProceed) => {
    if (attendee.userId) {
      this.props.history.push(
        formatRoute(EDIT_ATTENDEE_MEAL_PATH, {
          groupOrderId: this.props.groupOrder.id,
          attendeeId: attendee.id
        })
      )
    } else {
      this.selectNewAttendee(attendee, forceProceed)
    }
  }

  selectNewAttendee = (attendee, forceProceed) => {
    if (this.formsAreDirty() && !forceProceed) {
      // Case: Temporarily save the attendee until the user "submits" UnsavedDetailsModal
      this.setState({ potentialAttendee: attendee })
    } else {
      // Case: UnsavedDetailsModal was "submitted" and a new attendee is about to be added
      this.setState({
        editAttendeeId: attendee.id,
        potentialAttendee: undefined,
        deliveryDetailsFormDirty: false,
        mealBudgetFormDirty: false
      })
    }
  }

  onUpdateAttendee = (attendeeId, firstName, lastName, emailAddress) =>
    this.props
      .setAttendee(this.props.groupOrder.id, {
        firstName: trim(firstName),
        lastName: trim(lastName),
        emailAddress: trim(emailAddress)
      })
      .then(attendee =>
        this.props.history.push(
          formatRoute(EDIT_ATTENDEE_MEAL_PATH, {
            groupOrderId: this.props.groupOrder.id,
            attendeeId: attendee.id
          })
        )
      )

  updateOrder = async body => {
    this.setState({ updatingOrder: true })

    if (body.noBudget) {
      body.removeBudget = true
    }

    try {
      this.props.setHasAuthError(false)
      await this.props.updateGroupOrder(this.props.groupOrder.id, body)
    } catch (ex) {
      if (ex.message === CC_ERROR) {
        this.props.setHasAuthError(true)
      } else {
        throw ex
      }
    } finally {
      this.setState({ updatingOrder: false })
    }
  }

  shouldConfirmNavigation = (currentLocation, nextLocation) => {
    const isRoutingInternally =
      nextLocation && !noPromptRoutes.includes(nextLocation.pathname)
    const isRoutingExternally = nextLocation === undefined
    const formsAreDirty = this.formsAreDirty()

    return formsAreDirty && (isRoutingInternally || isRoutingExternally)
  }

  formsAreDirty = () =>
    this.state.deliveryDetailsFormDirty || this.state.mealBudgetFormDirty

  render () {
    const { isOwner, config, groupOrder, orderLocation, history } = this.props

    if (!groupOrder) {
      return null
    }

    const {
      id,
      store,
      cutoff,
      dropoff,
      attendees,
      attendeeUrlToken,
      budgetPerAttendeeInCents,
      defaultMeals,
      contactEmail,
      contactName,
      contactPhoneExt,
      contactPhoneNumber,
      suiteNumber,
      dropoffInstructions
    } = groupOrder

    const readOnly = !isEditable(groupOrder)

    const shareLink = `${shareLinkBaseUrl}${formatRoute(ATTENDEE_TOKEN_PATH, {
      token: attendeeUrlToken
    })}`

    const attendeeCount = size(attendees)

    const hasBudget = !!budgetPerAttendeeInCents

    const highestMealCost = getHighestMealCost(attendees)

    return (
      <div className='order-manage-main'>
        <Link className='dfo-back-link' to={GROUP_ORDERS_PATH}>
          <LeftChevronIcon />
          My Orders
        </Link>

        <h1 className='dfo-h1 order-manage-main__title'>Order #{id}</h1>

        <OrderSection headerProps={{ title: 'Deliver to' }}>
          <div className='order-manage-main__deliver-to'>
            <div className='order-manage-main__deliver-to-location'>
              {formatAddress(orderLocation)}
            </div>
            <DateTimeFormatted
              date={parse(dropoff, "yyyy-MM-dd'T'HH:mm:ss", new Date())}
            />{' '}
            <span className='order-manage-main__deliver-to-attendees'>
              for <b>{attendeeCount}</b> people
            </span>
          </div>
        </OrderSection>

        <OrderSection headerProps={{ title: 'Share' }}>
          <div className='order-manage-main__share'>
            <div className='order-manage-main__share-title'>
              Send this link to your meeting attendees!
            </div>
            <div className='order-manage-main__share-instructions'>
              Attendees must make their meal selections by{' '}
              <b>{formatTime(cutoff)}</b> on <b>{formatDate(cutoff)}</b>,
              otherwise they will receive the backup meal.
            </div>

            <ShareOrderLinkWithCopy url={shareLink} disabled={readOnly} />
          </div>
        </OrderSection>

        <OrderSection
          headerProps={{
            title: 'Attendees',
            subtitle: `(${attendeeCount} people)`
          }}
          collapsible
          initalOpen
        >
          <div className='order-manage-main__attendees'>
            <AttendeeList
              attendees={attendees || []}
              defaultMeals={defaultMeals}
              onSelectAttendee={this.onSelectAttendee}
              onAddAttendee={this.onAddAttendee}
              onRemoveAttendee={removeIndex =>
                this.onRemoveAttendee(attendees, removeIndex)
              }
              min={config.minHeadCount}
              max={store.maxHeadCount + config.maxHeadCountPadding}
              editable={isOwner && !readOnly}
              submitting={this.state.addAttendeeSubmitting}
              complete={this.state.addAttendeeComplete}
            />
          </div>
        </OrderSection>

        <div className='order-section-wrapper'>
          <h1 className='dfo-h1' style={{ fontWeight: 'normal' }}>
            Order Details
          </h1>

          <OrderSection headerProps={{ title: 'Backup Meal' }} collapsible>
            <div className='order-manage-main__backup-meal'>
              <div className='order-manage-main__backup-meal-header'>
                <div className='dfo-title'>{store.name}</div>
                {!readOnly && (
                  <DfoAction
                    onClick={() =>
                      history.push(
                        formatRoute(EDIT_BACKUP_MEAL_PATH, {
                          groupOrderId: groupOrder.id
                        })
                      )
                    }
                  >
                    Edit
                  </DfoAction>
                )}
              </div>

              <div className='order-manage-main__meal-item-list'>
                {defaultMeals.map((mealItem, index) => (
                  <OrderMealItem key={index} mealItem={mealItem} />
                ))}
              </div>
            </div>
          </OrderSection>

          <OrderSection headerProps={{ title: 'Meal Budget' }} collapsible>
            <div className='order-manage-main__meal-budget'>
              {readOnly && (
                <MealBudgetFormatted
                  budgetPerAttendeeInCents={budgetPerAttendeeInCents}
                  noBudget={!hasBudget}
                />
              )}
              {!readOnly && (
                <Formik
                  initialStatus={{ complete: false }}
                  initialValues={{
                    budgetPerAttendeeInCents,
                    noBudget: !hasBudget
                  }}
                  onSubmit={async (values, formikBag) => {
                    formikBag.setSubmitting(true)
                    await this.updateOrder(values)
                    formikBag.resetForm({ values })
                    this.setState({ mealBudgetFormDirty: false })
                    formikBag.setStatus({ complete: true })
                  }}
                  validateOnBlur={false}
                >
                  {({
                    errors,
                    touched,
                    status,
                    isSubmitting,
                    dirty,
                    setFieldValue
                  }) => (
                    <>
                      <FormikEffect
                        onChange={({ prevValues, nextValues, formik }) => {
                          this.setState({ mealBudgetFormDirty: formik.dirty })
                          formik.setStatus({ complete: false })
                        }}
                      />
                      <Form>
                        <MealBudget
                          defaultMeal={defaultMeals}
                          noBudget={!hasBudget}
                          setFieldValue={setFieldValue}
                          highestMealCost={highestMealCost}
                          errors={errors}
                          touched={touched}
                          dirty={dirty}
                          immutableBudget={!hasBudget}
                        />
                        {hasBudget && (
                          <RoundButton
                            type='submit'
                            submitting={isSubmitting}
                            disabled={!dirty}
                          >
                            Update
                          </RoundButton>
                        )}
                        {status.complete && !isSubmitting && (
                          <AlertSuccessMessage>Updated!</AlertSuccessMessage>
                        )}
                      </Form>
                    </>
                  )}
                </Formik>
              )}
            </div>
          </OrderSection>

          <OrderSection headerProps={{ title: 'Delivery Details' }} collapsible>
            <div className='order-manage-main__delivery-details'>
              {readOnly && (
                <DeliveryDetailsFormatted
                  suiteNumber={suiteNumber}
                  contactName={contactName}
                  contactPhoneNumber={contactPhoneNumber}
                  contactPhoneExtension={contactPhoneExt}
                  contactEmail={contactEmail}
                  dropoffInstructions={dropoffInstructions}
                />
              )}
              {!readOnly && (
                <Formik
                  initialStatus={{ complete: false }}
                  initialValues={{
                    contactEmail,
                    contactName,
                    contactPhoneExtensxion: contactPhoneExt || '',
                    contactPhoneNumber,
                    suiteNumber: suiteNumber || '',
                    dropoffInstructions: dropoffInstructions || ''
                  }}
                  onSubmit={async (values, formikBag) => {
                    formikBag.setSubmitting(true)
                    await this.updateOrder(values)
                    formikBag.resetForm({ values })
                    this.setState({ deliveryDetailsFormDirty: false })
                    formikBag.setStatus({ complete: true })
                  }}
                  validateOnBlur={false}
                >
                  {({ status, isSubmitting, dirty }) => (
                    <>
                      <FormikEffect
                        onChange={({ prevValues, nextValues, formik }) => {
                          this.setState({
                            deliveryDetailsFormDirty: formik.dirty
                          })
                          formik.setStatus({ complete: false })
                        }}
                      />
                      <Form>
                        <DeliveryDetails contactPhoneExtensionLabel='Ext.' />
                        <RoundButton
                          type='submit'
                          submitting={isSubmitting}
                          disabled={!dirty}
                        >
                          Update
                        </RoundButton>
                        {status.complete && !isSubmitting && (
                          <AlertSuccessMessage>Updated!</AlertSuccessMessage>
                        )}
                      </Form>
                    </>
                  )}
                </Formik>
              )}
            </div>
          </OrderSection>
        </div>

        <ContactSupport groupOrder={groupOrder} />

        <DeleteAttendeeModal
          showModal={this.state.removeAttendeeId !== undefined}
          onCancel={() => this.setState({ removeAttendeeId: undefined })}
          onSubmit={() =>
            this.removeAttendee(attendees, this.state.removeAttendeeId)
          }
          submitting={this.state.updatingOrder}
        />

        <EditAttendeeModal
          showModal={this.state.editAttendeeId !== undefined}
          attendeeId={this.state.editAttendeeId}
          onCancel={() => this.setState({ editAttendeeId: undefined })}
          onSubmit={this.onUpdateAttendee}
        />

        {this.state.potentialAttendee && (
          <UnsavedDetailsModal
            onCancel={() => this.setState({ potentialAttendee: undefined })}
            onSubmit={() =>
              this.onSelectAttendee(this.state.potentialAttendee, true)
            }
          />
        )}

        <NavigationPrompt when={this.shouldConfirmNavigation}>
          {({ onConfirm, onCancel }) => (
            <ConfirmNavigationModal onCancel={onCancel} onSubmit={onConfirm} />
          )}
        </NavigationPrompt>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  groupOrder: selectGroupOrder(state),
  orderLocation: selectLocation(state),
  isOwner: isGroupOrderOwner(state),
  config: selectConfig(state)
})

const mapDispatchToProps = {
  addAttendee,
  removeAttendee,
  setAttendee,
  updateGroupOrder,
  setHasAuthError
}

OrderManageMain = withRouter(OrderManageMain)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderManageMain)
