import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { formatRoute } from 'react-router-named-routes'
import MediaQuery from 'react-responsive'
import { Button } from '@foodsby/nutrient'

import AttendeeMealStatusSidebar from '../attendeeMealStatusSidebar/AttendeeMealStatusSidebar'
import GroupOrderStoreInfo from 'components/groupOrderStoreInfo/GroupOrderStoreInfo'
import PageFooter from 'components/pageFooter/PageFooter'
import SupportForm from 'components/supportForm/SupportForm'
import { ATTENDEE_TOKEN_MEAL_PATH, INDIVIDUAL_ORDER_URL } from 'routes'
import { formatTime, formatDateWithoutYear } from 'utils/datetime'
import {
  isCancelled,
  isPastCutoff,
  isPastDropoff,
  isEditable
} from 'utils/order'
import { toDisplayFirstName, isLoggedIn } from 'utils/user'

const Content = ({ header, body }) => (
  <div className='attendee-meal-status-main__content'>
    <h1>{header}</h1>
    <div className='attendee-meal-status-main__body'>{body}</div>
  </div>
)

const NoAttendeeSlotsContent = () => (
  <Content
    header='Uh Oh!'
    body={
      <>
        It looks like this order is full.
        <br />
        <br />
        Please reach out to your meeting organizer for help.
      </>
    }
  />
)

const ThankYouContent = ({ cutoff }) => (
  <Content
    header='Thank You!'
    body={
      <>
        Your custom meal has been saved.
        <br />
        <br />
        If you need to make further adjustments, you can always revisit the
        share link. You can customize until {formatTime(cutoff)} on{' '}
        {formatDateWithoutYear(cutoff)}.
      </>
    }
  />
)

const CancelledContent = () => (
  <Content
    header='Oops!'
    body='Your order has been canceled. Please contact your organizer for more details.'
  />
)

const DeliveredContent = ({ dropoff }) => (
  <Content
    header='You already ate that!'
    body={`Order delivered at ${formatTime(dropoff)}.`}
  />
)

const LockedContent = ({ dropoff }) => (
  <Content
    header="Sorry, you're a bit too late!"
    body={
      <>
        The customization window has passed. The backup meal that has been
        selected for you will be delivered {formatDateWithoutYear(dropoff)} at{' '}
        {formatTime(dropoff)}.
      </>
    }
  />
)

const PastOrderContent = () => (
  <Content
    header='Oops!'
    body={
      <>
        The order is locked or in the past.
        <br />
        <br />
        Please reach out to your meeting organizer for help.
      </>
    }
  />
)

class AttendeeMealStatusMain extends Component {
  onBack = () => {
    const { token } = this.props.match.params

    this.props.history.replace(formatRoute(ATTENDEE_TOKEN_MEAL_PATH, { token }))
  }

  render () {
    const { currentUser, currentLocation, groupOrder, attendee } = this.props

    if (!groupOrder) {
      return null
    }

    const { store, cutoff, dropoff } = groupOrder

    let content

    if (isCancelled(groupOrder)) {
      content = <CancelledContent />
    } else if (!attendee) {
      if (isPastCutoff(groupOrder)) {
        content = <PastOrderContent />
      } else {
        content = <NoAttendeeSlotsContent />
      }
    } else if (isPastDropoff(groupOrder)) {
      content = <DeliveredContent dropoff={dropoff} />
    } else if (isPastCutoff(groupOrder)) {
      content = <LockedContent dropoff={dropoff} />
    } else {
      content = <ThankYouContent cutoff={cutoff} />
    }

    const ownerName = toDisplayFirstName(groupOrder.owner)

    return (
      <div className='attendee-meal-status-main'>
        <div className='attendee-meal-status-main__top'>
          <MediaQuery query='(min-width: 769px)'>
            <GroupOrderStoreInfo store={store} includeDetails={false} />
          </MediaQuery>

          {content}

          <MediaQuery query='(max-width: 768px)'>
            <h1>{store.name}</h1>

            <AttendeeMealStatusSidebar
              currentUser={currentUser}
              currentLocation={currentLocation}
              groupOrder={groupOrder}
              attendee={attendee}
              meal={attendee ? attendee.meals : null}
              meetingDetailsHeaderText={`${ownerName}'s Order:`}
            />
          </MediaQuery>
        </div>

        {attendee && isEditable(groupOrder) && (
          <MediaQuery query='(min-width: 769px)'>
            <PageFooter>
              <Button
                className='secondary inverted'
                style={{ margin: 0 }}
                onClick={this.onBack}
              >
                Edit Order
              </Button>
            </PageFooter>
          </MediaQuery>
        )}

        <div className='attendee-meal-status-main__button-group mobile'>
          <Button
            className='primary'
            onClick={() => (window.location = INDIVIDUAL_ORDER_URL)}
          >
            Foodsby Home
          </Button>

          {attendee && isEditable(groupOrder) && (
            <Button className='primary inverted' onClick={this.onBack}>
              Edit Order
            </Button>
          )}
        </div>

        <MediaQuery query='(max-width: 768px)'>
          <SupportForm
            showLink={!isLoggedIn(currentUser) || !attendee}
            groupOrder={groupOrder}
            attendee={attendee}
            mobile
          />
        </MediaQuery>
      </div>
    )
  }
}

export default withRouter(AttendeeMealStatusMain)
