import { geocodeByAddress } from 'react-places-autocomplete'

const CANT_PARSE_ADDRESS = 'There was a problem finding this address.'

export function formatAddress (address) {
  const formattedSearchAddress = {
    street: '',
    city: '',
    state: '',
    zip: ''
  }
  if (address && address.address_components) {
    address.address_components.forEach(addr => {
      addr.types.forEach(type => {
        switch (type) {
          case 'street_number':
            formattedSearchAddress.street = addr.long_name
            break
          case 'route':
            formattedSearchAddress.street += formattedSearchAddress.street
              ? ` ${addr.long_name}`
              : addr.long_name
            break
          case 'sublocality':
          case 'locality':
          case 'neighborhood':
            formattedSearchAddress.city = addr.long_name
            break
          case 'administrative_area_level_1':
            formattedSearchAddress.state = addr.long_name
            break
          case 'postal_code':
            formattedSearchAddress.zip = addr.short_name
            break
          default:
            break
        }
      })
    })
  }
  return formattedSearchAddress
}

export async function convertAddress (address) {
  const results = await geocodeByAddress(address)
  if (results.length) {
    try {
      if (results[0] && results[0].formatted_address) {
        const addressComponents = formatAddress(results[0])

        const finalResult = await geocodeByAddress(results[0].formatted_address)
        let latLng = {
          lat: finalResult[0].geometry.location.lat(),
          lng: finalResult[0].geometry.location.lng()
        }
        if (
          !addressComponents.zip ||
          !addressComponents.street ||
          !addressComponents.city ||
          !addressComponents.state
        ) {
          throw new Error('Bad address entered')
        } else {
          return { address: addressComponents, latLng }
        }
      }
    } catch (ex) {
      throw new Error(CANT_PARSE_ADDRESS)
    }
  } else {
    throw new Error('Address not found. Please enter a valid address.')
  }
}
