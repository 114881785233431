import React from 'react'
import { connect } from 'react-redux'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import OrderOverview, {
  DeliverSection,
  BackupMealSection,
  MealBudgetSection
} from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import { newGroupOrder } from 'redux/selectors'
import { NEW_MEAL_BUDGET_PATH, NEW_BACKUP_MEAL_PATH } from 'routes'

const DeliveryDetailsSidebar = ({
  dropoff,
  attendeesCount,
  budgetPerAttendeeInCents,
  noBudget,
  defaultMeals,
  currentStore,
  isValid,
  submitCount,
  setDeliveryOptionsFormOpen,
  history
}) => {
  if (!(attendeesCount && currentStore && dropoff && defaultMeals)) {
    return null
  }

  return (
    <StickySidebar className='dfo-sidebar delivery-details-sidebar'>
      <OrderOverview>
        <DeliverSection
          dropoff={dropoff}
          attendeesCount={attendeesCount}
          onClickAction={() => setDeliveryOptionsFormOpen(true)}
        />
        <BackupMealSection
          storeName={currentStore.name}
          meals={defaultMeals}
          onClickAction={() => history.push(NEW_BACKUP_MEAL_PATH)}
        />
        <MealBudgetSection
          budgetPerAttendeeInCents={budgetPerAttendeeInCents}
          onClickAction={() => history.push(NEW_MEAL_BUDGET_PATH)}
          noBudget={noBudget}
        />
      </OrderOverview>

      <DfoSubmitButton label='Review Order' />

      {!isValid && submitCount > 0 && (
        <AlertErrorMessage>
          Please complete all required fields before proceeding.
        </AlertErrorMessage>
      )}
    </StickySidebar>
  )
}

const mapStateToProps = state => ({
  currentStore: newGroupOrder.selectStore(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  budgetPerAttendeeInCents: newGroupOrder.selectBudgetPerAttendeeInCents(state),
  noBudget: newGroupOrder.selectNoBudget(state),
  defaultMeals: newGroupOrder.selectDefaultMeal(state),
  dropoff: newGroupOrder.selectDropoff(state)
})

export default connect(mapStateToProps)(DeliveryDetailsSidebar)
