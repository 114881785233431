import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'
import noop from 'lodash/noop'

import { toErrorString } from 'utils/errorUtils'
import { ReactComponent as CheckmarkIcon } from 'assets/dfo/icon--green-check.svg'

const CouponDiscount = ({
  coupon,
  onApply,
  onRemove,
  applying = false,
  onEnter = noop
}) => {
  const code = get(coupon, 'code')
  const errors = get(coupon, 'errors')

  const [currentCode, setCurrentCode] = useState(code)
  const [removing, setRemoving] = useState(false)

  const inputRef = useRef()

  const isApplied = code && !errors && !removing

  let errorMessage

  if (errors) {
    errorMessage =
      errors[0].code === 'PRODUCT'
        ? 'Son of a biscuit! This coupon doesn’t work for Meetings'
        : toErrorString(errors)
  }

  return (
    <div
      className={classnames('coupon-discount', {
        'coupon-discount--error': !!errors && !applying
      })}
    >
      <label className='coupon-discount__label' htmlFor='coupon-discount-input'>
        Coupons
      </label>

      <div className='coupon-discount__input-group'>
        <div className='coupon-discount__input-wrapper'>
          <input
            ref={inputRef}
            id='coupon-discount-input'
            type='text'
            className='coupon-discount__input'
            value={currentCode || ''}
            onChange={({ target: { value } }) => setCurrentCode(value)}
            placeholder='Coupon code'
            onKeyPress={onEnter}
            readOnly={isApplied || applying}
          />

          {isApplied && <CheckmarkIcon />}
        </div>

        <button
          className='coupon-discount__apply-button'
          type='button'
          disabled={applying}
          onClick={e => {
            e.preventDefault()
            if (isApplied) {
              setRemoving(true)
              onRemove(currentCode)
              setCurrentCode(null)
              inputRef.current.focus()
            } else {
              onApply(currentCode)
              setRemoving(false)
            }
          }}
        >
          {isApplied && !applying ? 'Remove' : 'Apply'}
        </button>
      </div>

      <div
        className={classnames('coupon-discount__error-message', {
          'coupon-discount__error-message--visible': !!errors && !applying
        })}
      >
        {errorMessage}
      </div>
    </div>
  )
}

export default CouponDiscount
