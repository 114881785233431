import React, { useEffect } from 'react'
import { Field } from 'formik'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import classnames from 'classnames'

import CreditCardDetail from './creditCardDetail/CreditCardDetail'
import { ReactComponent as CreditCardIcon } from 'assets/dfo/icon--credit-card.svg'

const PaymentMethodOption = ({
  selected,
  onChangePayment,
  value,
  text,
  children
}) => (
  <div
    className={classnames('payment-method-option', {
      'payment-method-option--selected': selected
    })}
    onClick={() => !selected && onChangePayment(value)}
  >
    <div className='payment-method-option__credit-card'>
      <input
        type='radio'
        name='payment-method'
        value={value}
        checked={selected}
        onChange={() => onChangePayment(value)}
      />
      <div
        className={classnames('payment-method-option__label', {
          'payment-method-option__label--new-card': !value
        })}
      >
        <CreditCardIcon />
        {text}
      </div>
    </div>
    {children}
  </div>
)

const PaymentMethod = ({ payments, values, validateForm, sidebar }) => {
  useEffect(() => {
    if (!values.ccProfile.id) {
      // Ensure form is validated if Add New Card form is selected,
      // otherwise formik.isValid will not be correct
      validateForm()
    }
  }, [values.ccProfile.id])

  return (
    <div className='payment-method'>
      {!sidebar && (
        <div className='payment-method__header'>
          <div className='payment-method__title'>Payment Methods</div>
        </div>
      )}

      {!isEmpty(payments) ? (
        <div className='payment-method__credit-cards'>
          <div
            className={classnames('payment-method__cards-on-file', {
              'payment-method__cards-on-file--sidebar': sidebar
            })}
          >
            Credit Cards on File
          </div>

          {map(payments, p => (
            <Field
              key={p.ccProfileId}
              value={p.ccProfileId}
              name='ccProfile.id'
            >
              {({ form, field }) => (
                <PaymentMethodOption
                  value={p.ccProfileId}
                  onChangePayment={() => {
                    if (field.value === undefined) {
                      // reset the form if a valid option is choosen
                      form.resetForm({
                        values: {
                          ccProfile: {
                            id: p.ccProfileId,
                            saveCard: false,
                            number: '',
                            cvv: '',
                            expirationDate: '',
                            zipCode: '',
                            firstName: '',
                            lastName: ''
                          }
                        }
                      })
                    } else {
                      form.setFieldValue(field.name, p.ccProfileId)
                    }
                  }}
                  text={<span>&bull;&bull;&bull;&bull;&nbsp;{p.lastFour}</span>}
                  selected={p.ccProfileId === field.value}
                />
              )}
            </Field>
          ))}

          <Field value={undefined} name='ccProfile.id'>
            {({ form, field }) => {
              const newCardSelected = field.value === undefined
              return (
                <PaymentMethodOption
                  value=''
                  onChangePayment={() =>
                    form.setFieldValue(field.name, undefined)
                  }
                  text={<span>+ Add Form of Payment</span>}
                  selected={newCardSelected}
                >
                  {newCardSelected && <CreditCardDetail sidebar={sidebar} />}
                </PaymentMethodOption>
              )
            }}
          </Field>
        </div>
      ) : (
        <CreditCardDetail sidebar={sidebar} />
      )}
    </div>
  )
}

export default PaymentMethod
