import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import OrderTotal from 'components/newOrderWorkflow/orderTotal/OrderTotal'
import { newGroupOrder } from 'redux/selectors'
import { calculateCartTotals, calculateBackupMealTotal } from 'utils/order'

let OrderSummarySidebar = ({
  budgetPerAttendeeInCents,
  noBudget,
  attendeesCount,
  defaultMeals,
  dropoff,
  currentStore,
  taxes,
  coupon,
  onSubmit
}) => {
  if (
    !(
      attendeesCount &&
      budgetPerAttendeeInCents &&
      currentStore &&
      dropoff &&
      defaultMeals &&
      taxes
    )
  ) {
    return null
  }

  const {
    actualMealTotal,
    actualTaxes,
    actualTotal,
    actualCouponDiscount,
    actualOrderFee,
    budgetedMealTotal,
    budgetedOrderFee,
    budgetedTaxes,
    budgetedTotal,
    budgetedCouponDiscount,
    deliveryFee
  } = calculateCartTotals({
    attendeesCount,
    budgetPerAttendeeInCents,
    coupon,
    currentStore,
    defaultMeals,
    taxes
  })

  const backupMealCost = calculateBackupMealTotal(defaultMeals)

  return (
    <StickySidebar className='dfo-sidebar order-summary-sidebar'>
      <h1 className='dfo-h1'>Is all order information correct?</h1>

      <OrderTotal
        attendeesCount={attendeesCount}
        deliveryFee={deliveryFee}
        coupon={coupon}
        costPerAttendee={
          noBudget && backupMealCost
            ? backupMealCost.value
            : budgetPerAttendeeInCents
        }
        noBudget={noBudget}
        mealTotal={noBudget ? actualMealTotal : budgetedMealTotal}
        couponDiscount={
          noBudget ? actualCouponDiscount : budgetedCouponDiscount
        }
        orderFee={noBudget ? actualOrderFee : budgetedOrderFee}
        taxes={noBudget ? actualTaxes : budgetedTaxes}
        total={noBudget ? actualTotal : budgetedTotal}
        dropoff={dropoff}
      />

      <DfoSubmitButton label='Continue to Checkout' onClick={onSubmit} />
    </StickySidebar>
  )
}

const mapStateToProps = state => ({
  currentStore: newGroupOrder.selectStore(state),
  budgetPerAttendeeInCents: newGroupOrder.selectBudgetPerAttendeeInCents(state),
  noBudget: newGroupOrder.selectNoBudget(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  defaultMeals: newGroupOrder.selectDefaultMeal(state),
  dropoff: newGroupOrder.selectDropoff(state),
  taxes: newGroupOrder.selectTaxes(state),
  coupon: newGroupOrder.selectCoupon(state)
})

OrderSummarySidebar = withRouter(OrderSummarySidebar)

export default connect(
  mapStateToProps,
  null
)(OrderSummarySidebar)
