import React, { useState, useEffect } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import track, { INPUT_CHANGE, SEARCH_LOCATION } from 'services/tracking'

import { ReactComponent as PinIcon } from 'assets/icon--pin.svg'
import { ReactComponent as CloseIcon } from 'assets/icon--close.svg'

export const getHighlightedText = (text, highlight) => {
  const highlightParts = highlight.split(' ')
  const parts = text.split(' ')

  return (
    <span>
      {parts &&
        parts.map((part, i) => (
          <span
            key={i}
            style={
              highlightParts &&
              highlightParts.some(
                highlight =>
                  highlight &&
                  part
                    .replace(/[^a-z0-9]/gi, '')
                    .toLowerCase()
                    .indexOf(
                      highlight.toLowerCase().replace(/[^a-z0-9]/gi, '')
                    ) > -1
              )
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {`${part} `}
          </span>
        ))}
    </span>
  )
}

const LocationSearch = ({
  initialValue,
  onSelect,
  onChange,
  onClearValue,
  value,
  autocompleteRef,
  placeholder = 'Enter a delivery address',
  autoFocus = true
}) => {
  let currentValue, setValue

  if (onChange) {
    setValue = onChange
    currentValue = value
  } else {
    ;[currentValue, setValue] = useState()
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleSelect = async (address, placeId) => {
    setValue(address)

    if (!placeId) {
      return
    }

    if (onSelect) {
      onSelect(address, placeId)
    }

    track({
      category: INPUT_CHANGE,
      action: SEARCH_LOCATION
    })
  }

  return (
    <PlacesAutocomplete
      value={currentValue || ''}
      onChange={address => setValue(address)}
      highlightFirstSuggestion
      onSelect={handleSelect}
      ref={autocompleteRef}
      autocompleteRef={autocompleteRef}
      onError={err => {
        if (err !== 'ZERO_RESULTS') {
          throw err
        }
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        const dropdownClassName = classnames(
          'location-search__autocomplete-container',
          {
            'location-search__autocomplete-container--active': !isEmpty(
              suggestions
            )
          }
        )

        return (
          <div className='location-search'>
            <label htmlFor='location-search'>
              <PinIcon />
              <input
                type='text'
                {...getInputProps({
                  placeholder,
                  autoFocus
                })}
                name='location-search'
                id='location-search'
              />

              {onClearValue && currentValue && currentValue.length > 0 && (
                <div className='location-search__clear-button'>
                  <button
                    id='location-search-clear-button'
                    onClick={onClearValue}
                  />
                  <label htmlFor='location-search-clear-button'>
                    <CloseIcon />
                  </label>
                </div>
              )}

              <div className={dropdownClassName}>
                {suggestions.length > 0 &&
                  suggestions.map((suggestion, i) => {
                    const className = classnames(
                      'location-search__suggestion',
                      {
                        'location-search__suggestion--active': suggestion.active
                      }
                    )
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                        key={i}
                      >
                        <span>
                          {getHighlightedText(
                            suggestion.description,
                            currentValue
                          )}
                        </span>
                      </div>
                    )
                  })}
                <div className='location-search__google-attr'>
                  <img
                    src='https://images.foodsby.com/third-party/powered_by_google_on_white.png'
                    alt='Powered by Google'
                  />
                </div>
              </div>
            </label>
          </div>
        )
      }}
    </PlacesAutocomplete>
  )
}

export default LocationSearch
