import React from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'

import { ReactComponent as AlertErrorIcon } from 'assets/dfo/icon--alert-error.svg'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import WarningModal from 'components/newOrderWorkflow/shared/warningModal/WarningModal'

const AbandonMealPrompt = ({
  show,
  onSaveChanges,
  onDiscardChanges,
  submitting,
  cancelingUnsavedChanges
}) =>
  cancelingUnsavedChanges ? (
    <UnsavedMealModal
      showModal={show}
      onSaveChanges={onSaveChanges}
      onDiscardChanges={onDiscardChanges}
      submitting={submitting}
    />
  ) : (
    <NavigationPrompt when={show}>
      {({ props }) => (
        <UnsavedMealModal
          onSaveChanges={onSaveChanges}
          onDiscardChanges={onDiscardChanges}
          submitting={submitting}
        />
      )}
    </NavigationPrompt>
  )

const UnsavedMealModal = ({
  showModal,
  onSaveChanges,
  onDiscardChanges,
  submitting
}) => {
  const title = "You didn't save your changes"
  const message =
    'Your information will be lost if you leave this page without saving. Do you want to save your changes?'
  const buttons = [
    <RoundButton className='borderless' onClick={onDiscardChanges}>
      Don't Save
    </RoundButton>,
    <RoundButton onClick={onSaveChanges} disabled={submitting}>
      Save Changes
    </RoundButton>
  ]

  return (
    <WarningModal
      showModal={showModal}
      title={title}
      message={message}
      buttons={buttons}
      icon={<AlertErrorIcon />}
      maxWidth='500px'
    />
  )
}

export default AbandonMealPrompt
