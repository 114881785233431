import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import sumBy from 'lodash/sumBy'
import map from 'lodash/map'

import { ReactComponent as LeftChevronIcon } from 'assets/dfo/icon--chevron-left-navy-blue.svg'
import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import DfoPageLayout from 'components/newOrderWorkflow/dfoPageLayout/DfoPageLayout'
import MenuPage from 'components/newOrderWorkflow/menuPage/MenuPage'
import GroupOrderStoreInfo from 'components/groupOrderStoreInfo/GroupOrderStoreInfo'
import AttendeeMealPageHeader from './attendeeMealPageHeader/AttendeeMealPageHeader'
import AttendeeMealSidebar from './attendeeMealSidebar/AttendeeMealSidebar'
import AbandonMealPrompt from './abandonMealPrompt/AbandonMealPrompt'
import { updateAttendeeMeal } from 'api'
import { getLocation } from 'redux/modules/location'
import { getGroupOrder } from 'redux/modules/groupOrder'
import { clearMeal, addMealItem } from 'redux/modules/menu'
import {
  selectCurrentUser,
  selectGroupOrder,
  selectMeal,
  isGroupOrderOwner
} from 'redux/selectors'
import {
  isDefaultMeal,
  isCancelled,
  isPastCutoff,
  isPastDropoff
} from 'utils/order'
import { EDIT_GROUP_ORDER_PATH, ATTENDEE_TOKEN_STATUS_PATH } from 'routes'
import track, { CLICK_EVENT, CLICK_CHOOSE_BACKUP_MEAL } from 'services/tracking'

const AttendeeMealMenuHeader = ({ store, groupOrderId, isOwner }) => (
  <div className='attendee-meal-menu-header'>
    {isOwner && (
      <Link
        className='dfo-back-link'
        to={formatRoute(EDIT_GROUP_ORDER_PATH, {
          groupOrderId: groupOrderId
        })}
      >
        <LeftChevronIcon />
        Order #{groupOrderId}
      </Link>
    )}
    <GroupOrderStoreInfo store={store} includeDetails={false} />
  </div>
)

class AttendeeMealPage extends Component {
  state = {
    cancelingUnsavedChanges: false,
    showMobileSidebar: false,
    submitting: false,
    submitAttempted: false,
    initialMeal: []
  }

  async componentDidMount () {
    const groupOrderId = this.props.match.params.groupOrderId
      ? this.props.match.params.groupOrderId
      : this.props.tokenResponse.id

    const groupOrder = await this.props.getGroupOrder(groupOrderId)

    // If the group order is no longer editable, show the status page instead of the menu
    if (
      !this.props.isOwner &&
      (isCancelled(groupOrder) ||
        isPastDropoff(groupOrder) ||
        isPastCutoff(groupOrder))
    ) {
      this.goToStatusPage(groupOrder)
    } else {
      this.props.getLocation(groupOrder.monolithLocationId)
    }

    const attendee = this.getAttendee(groupOrder)
    // Set the intial meal to saved meal if it's not the backup meal
    if (!isDefaultMeal(groupOrder.defaultMeals, attendee.meals)) {
      this.setState({ initialMeal: attendee.meals })
    }
  }

  goToStatusPage = groupOrder =>
    this.props.history.push(
      formatRoute(ATTENDEE_TOKEN_STATUS_PATH, {
        token: groupOrder.attendeeUrlToken
      })
    )

  saveMeal = async updatedMeal => {
    this.setState({ submitAttempted: true })

    const { budgetPerAttendeeInCents, defaultMeals } = this.props.groupOrder
    const hasBudget = !!budgetPerAttendeeInCents
    const overbudget = this.isOverbudget(updatedMeal, budgetPerAttendeeInCents)
    const attendee = this.getAttendee(this.props.groupOrder)

    if ((!overbudget && hasBudget) || !hasBudget) {
      let body

      if (isEmpty(updatedMeal)) {
        body = defaultMeals // if no meal is selected, update with backup meal
      } else {
        body = updatedMeal.map(mealItem =>
          pick(mealItem, ['menuItemId', 'modifiers', 'specialInstructions'])
        )
      }

      this.setState({ submitting: true })
      await updateAttendeeMeal(attendee.id, body)

      if (this.props.isOwner) {
        this.goBack()
      } else {
        this.goToStatusPage(this.props.groupOrder)
      }
    }
  }

  setBackupMeal = () => {
    this.props.clearMeal()
    map(this.props.groupOrder.defaultMeals, mealItem =>
      this.props.addMealItem(mealItem)
    )
    track({
      category: CLICK_EVENT,
      action: CLICK_CHOOSE_BACKUP_MEAL
    })
  }

  handleCancel = () => {
    const { meal, groupOrder } = this.props
    const overbudget = this.isOverbudget(
      meal,
      groupOrder.budgetPerAttendeeInCents
    )
    const mealUnchanged = isEqual(this.state.initialMeal, meal)

    if (mealUnchanged || overbudget) {
      this.goBack()
    } else {
      this.setState({ cancelingUnsavedChanges: true })
    }
  }

  goBack = () =>
    this.props.history.push(
      formatRoute(EDIT_GROUP_ORDER_PATH, {
        groupOrderId: this.props.groupOrder.id
      })
    )

  isValidMeal = (meal, overbudget) => !(isEmpty(meal) || overbudget)

  calculateRemainingBalance = (meal, budgetPerAttendeeInCents) => {
    const totalPrice = sumBy(meal, 'totalPriceInCents')
    const remainingBalance = budgetPerAttendeeInCents - totalPrice
    const overbudget = remainingBalance < 0
    return {
      remainingBalance,
      overbudget
    }
  }

  isOverbudget = (meal, budgetPerAttendeeInCents) =>
    this.calculateRemainingBalance(meal, budgetPerAttendeeInCents).overbudget

  getAttendee = groupOrder =>
    groupOrder.attendees.find(a => {
      if (this.props.match.params.attendeeId) {
        return a.id === parseInt(this.props.match.params.attendeeId)
      } else {
        return a.userId === this.props.currentUser.userId
      }
    })

  toggleMobileSidebar = () =>
    this.setState({ showMobileSidebar: !this.state.showMobileSidebar })

  render () {
    const { groupOrder, isOwner, meal, currentUser } = this.props
    const {
      submitting,
      submitAttempted,
      cancelingUnsavedChanges,
      showMobileSidebar,
      initialMeal
    } = this.state

    if (
      !groupOrder ||
      (this.props.match.params.groupOrderId &&
        groupOrder.id !== parseInt(this.props.match.params.groupOrderId)) ||
      (this.props.tokenResponse &&
        groupOrder.id !== parseInt(this.props.tokenResponse.id)) ||
      !currentUser.userId
    ) {
      return null
    }

    const attendee = this.getAttendee(groupOrder)

    const { remainingBalance, overbudget } = this.calculateRemainingBalance(
      meal,
      groupOrder.budgetPerAttendeeInCents
    )

    // TODO: Forcing user to choose a meal is disabled for now
    // so isValid is unused right now
    // They are assigned the backup meal if they don't choose a meal
    const isValid = this.isValidMeal(meal, overbudget)
    const isMealChanged = !isEqual(initialMeal, meal)

    return (
      <DfoLayout currentUser={currentUser}>
        <MenuPage
          initialMeal={initialMeal}
          groupOrder={groupOrder}
          currentStore={groupOrder.store}
          saveMeal={this.saveMeal}
          isValid={isValid}
          submitting={submitting}
          submitAttempted={submitAttempted}
          attendee={attendee}
          layoutComponent={props => (
            <DfoPageLayout
              headerComponent={
                <AttendeeMealPageHeader
                  groupOrder={groupOrder}
                  addedMeals={meal}
                  saveMeal={this.saveMeal}
                  toggleMobileSidebar={this.toggleMobileSidebar}
                  submitting={submitting}
                  overbudget={overbudget}
                  budget={groupOrder.budgetPerAttendeeInCents}
                />
              }
              toggleMobileSidebar={this.toggleMobileSidebar}
              showMobileSidebar={showMobileSidebar}
              {...props}
            />
          )}
          menuHeaderComponent={props => (
            <AttendeeMealMenuHeader
              isOwner={isOwner}
              groupOrderId={groupOrder.id}
              {...props}
            />
          )}
          sidebarComponent={props => (
            <AttendeeMealSidebar
              hasBudget={!!groupOrder.budgetPerAttendeeInCents}
              overbudget={overbudget}
              remainingBalance={remainingBalance}
              setBackupMeal={this.setBackupMeal}
              onCancel={isOwner ? this.handleCancel : null}
              {...props}
            />
          )}
        />

        <AbandonMealPrompt
          show={isMealChanged && !overbudget && !submitting}
          cancelingUnsavedChanges={cancelingUnsavedChanges}
          onSaveChanges={() => this.saveMeal(this.props.meal)}
          onDiscardChanges={this.goBack}
          submitting={submitting}
        />
      </DfoLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  groupOrder: selectGroupOrder(state),
  isOwner: isGroupOrderOwner(state),
  meal: selectMeal(state)
})

const mapDispatchToProps = {
  getLocation,
  getGroupOrder,
  clearMeal,
  addMealItem
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendeeMealPage)
