import React from 'react'
import isEmpty from 'lodash/isEmpty'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import OrderOverview from 'components/newOrderWorkflow/orderOverview/OrderOverview'
import OrderMealItem from 'components/newOrderWorkflow/shared/orderMealItem/OrderMealItem'
import MealOrderSummary from './mealOrderSummary/MealOrderSummary'
import { ReactComponent as MealIcon } from 'assets/dfo/icon--backup-meal-grey.svg'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'

const BackupMealSummary = ({ storeName, meals, onSelectBackupMeal }) => (
  <OrderOverview>
    <div className='order-overview-section backup-meal-instructions-section'>
      <div className='backup-meal-instructions-section__header'>
        <MealIcon />
        <span>Backup Meal</span>
      </div>
      <div className='backup-meal-instructions-section__content'>
        This is what you’ll receive if you decide not to choose something else
        off the menu.
      </div>
    </div>

    <div className='order-overview-section backup-meal-section'>
      <div className='dfo-title'>{storeName}</div>
      <div className='backup-meal-section__meal-item-list'>
        {meals.map((mealItem, index) => (
          <OrderMealItem key={index} mealItem={mealItem} />
        ))}
      </div>

      <DfoAction
        className='backup-meal-summary__action'
        onClick={onSelectBackupMeal}
      >
        I want the backup meal
      </DfoAction>
    </div>
  </OrderOverview>
)

const AttendeeMealSidebar = ({
  meal,
  groupOrder,
  isValid,
  submitting,
  submitAttempted,
  onRemove,
  onEdit,
  saveMeal,
  hasBudget,
  overbudget,
  remainingBalance,
  setBackupMeal,
  onCancel
}) => {
  if (!groupOrder) {
    return null
  }

  return (
    <StickySidebar className='dfo-sidebar attendee-meal-sidebar'>
      <MealOrderSummary
        storeName={groupOrder.store.name}
        meals={meal}
        budget={groupOrder.budgetPerAttendeeInCents}
        hasBudget={hasBudget}
        remainingBalance={remainingBalance}
        overbudget={overbudget}
        onEditMealItem={onEdit}
        onRemoveMealItem={onRemove}
      />

      {isEmpty(meal) && (
        <BackupMealSummary
          storeName={groupOrder.store.name}
          meals={groupOrder.defaultMeals}
          onSelectBackupMeal={setBackupMeal}
        />
      )}

      <RoundButton
        label='Save Meal'
        onClick={() => saveMeal(meal)}
        disabled={submitting}
      />

      {onCancel && (
        <DfoAction className='destructive' onClick={onCancel}>
          CANCEL
        </DfoAction>
      )}

      {submitAttempted && !isValid && !overbudget && (
        <AlertErrorMessage>Please choose a meal</AlertErrorMessage>
      )}
    </StickySidebar>
  )
}

export default AttendeeMealSidebar
