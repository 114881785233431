import React, { Component } from 'react'
import classnames from 'classnames'

import Login from './Login'
import Register from './Register'

class LoginToggle extends Component {
  componentDidUpdate (prevProps) {
    if (this.props.loginFocused !== prevProps.loginFocused) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    const { onLogin, onRegister, loginFocused, onToggleForm } = this.props

    const showRegister = onRegister && onToggleForm

    return (
      <div className='login-toggle'>
        <div
          className={classnames('login-toggle__form', {
            'login-toggle__form--visible': loginFocused
          })}
        >
          <Login
            opened={loginFocused}
            onToggleForm={onToggleForm}
            onSubmit={onLogin}
          />
        </div>
        {showRegister && (
          <>
            <div className='login-toggle__form-divider tablet-up' />
            <div
              className={classnames('login-toggle__form', {
                'login-toggle__form--visible': !loginFocused
              })}
            >
              <Register
                opened={!loginFocused}
                onToggleForm={onToggleForm}
                onSubmit={onRegister}
              />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default LoginToggle
