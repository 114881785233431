import React from 'react'
import { Modal } from '@foodsby/nutrient'

import PersonalizeMenu from './PersonalizeMenu'

const MenuItemModal = ({ isBackupMeal, menuItem, mealItem, saveMealItem, onCancel, attendee }) => {
  if (!menuItem) {
    return null
  }

  return (
    <Modal onCancel={onCancel} className='menu-item-modal'>
      <PersonalizeMenu
        isBackupMeal={isBackupMeal}
        menuItem={menuItem}
        mealItem={mealItem}
        saveMealItem={saveMealItem}
        closeModal={onCancel}
        attendee={attendee}
      />
    </Modal>
  )
}

export default MenuItemModal
