import { useEffect } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'

import { selectGoLocationId, selectCurrentUser } from 'redux/selectors'
import { STORES_FOR_LOCATION_PATH } from 'routes'

const StorePage = ({ history, location, currentUser, goLocationId }) => {
  const goToLocation = locationId => {
    const pathPrefix = formatRoute(STORES_FOR_LOCATION_PATH, {
      locationId
    })
    history.replace(`${pathPrefix}${location.search}`)
  }

  useEffect(() => {
    if (goLocationId) {
      goToLocation(goLocationId)
    } else if (currentUser.locationId) {
      goToLocation(currentUser.locationId)
    }
  }, [goLocationId, currentUser.locationId])
  return null
}

const mapStateToProps = state => ({
  goLocationId: selectGoLocationId(state),
  currentUser: selectCurrentUser(state)
})

export default connect(mapStateToProps)(StorePage)
