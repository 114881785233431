import React from 'react'

import MessageBar from 'components/newOrderWorkflow/shared/MessageBar'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'

const PaymentErrorHeader = () => (
  <div className='payment-error-header'>
    <MessageBar center>
      <AlertErrorMessage>
        <b>There's a problem with your payment information.</b>&nbsp; Please
        update below
      </AlertErrorMessage>
    </MessageBar>
  </div>
)

const OrderManageHeader = ({ paymentError }) => {
  if (paymentError) {
    return <PaymentErrorHeader />
  }

  return null
}

export default OrderManageHeader
