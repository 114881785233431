import React from 'react'

import GroupOrderStoreInfo from 'components/groupOrderStoreInfo/GroupOrderStoreInfo'

const MenuHeader = ({ store, includeDetails = true }) => (
  <div className='menu-header'>
    <GroupOrderStoreInfo store={store} includeDetails={includeDetails} />
  </div>
)

export default MenuHeader
