import React, { Component } from 'react'
import classNames from 'classnames'
import { getYear, getMonth } from 'date-fns'
import DayPicker from 'react-day-picker'

const DayPickerCaption = ({ date, localeUtils }) => {
  const months = localeUtils.getMonths()
  return (
    <div className='DayPicker-Caption' role='heading'>
      {months[date.getMonth()]}
    </div>
  )
}

class DatePicker extends Component {
  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    this.props.onDateSelected(day)
  }

  render () {
    const {
      className,
      selectedDate,
      firstValidDateTime,
      disabledDays
    } = this.props

    const startMonth = getMonth(firstValidDateTime)
    const startYear = getYear(firstValidDateTime)
    const fromMonth = new Date(startYear, startMonth)

    return (
      <div className={classNames('date-picker', className)}>
        <DayPicker
          selectedDays={selectedDate}
          disabledDays={disabledDays}
          onDayClick={this.handleDayClick}
          initialMonth={fromMonth}
          month={selectedDate}
          fromMonth={fromMonth}
          weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          captionElement={DayPickerCaption}
          tabIndex={-1}
        />
      </div>
    )
  }
}

export default DatePicker
